import React from 'react'
import { Redirect } from 'react-router'

function Home() {
    return (
        <div>
            <Redirect to="/dashboard"></Redirect>
        </div>
    )
}

export default Home
