import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";
import { 
    ADD_EMPLOYEE, 
    CLEAR_EMPLOYEE, 
    DELETE_EMPLOYEE, 
    EMPLOYEE_ERROR, 
    GET_EMPLOYEES, 
    GET_EMPLOYEE, 
    UPDATE_EMPLOYEE 
} from './types';


export const getEmployees = params => dispatch => {

    dispatch({type: CLEAR_EMPLOYEE});

    return fetch(`${API_URL}/users`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(res => 
        {
            dispatch({
                type: GET_EMPLOYEES,
                payload: res.users
            })
        }
    )
    .catch(err => dispatch({type: EMPLOYEE_ERROR}));

}

export const getEmployee = (id) => dispatch => {

    fetch(`${API_URL}/users/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "ContentType": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_EMPLOYEE,
            payload: res.user
        })
    })
    .catch(err => dispatch({type: EMPLOYEE_ERROR}));

}

export const addEmployee = (formData, user_id, token, history) => dispatch => {

    fetch(`${API_URL}/users/create/${user_id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: formData
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            let employee = res.user;

            dispatch({
                type: ADD_EMPLOYEE,
                payload: employee
            })

            toastr.success(`employee ${employee.firstname} created !`, 'New employee' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/employees');

        }
    })
    .catch(err => dispatch({type: EMPLOYEE_ERROR}));
}

export const removeEmployee = (id, user, token) => dispatch => {

    fetch(`${API_URL}/users/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: DELETE_EMPLOYEE,
                payload: id
            })

            toastr.success(`employee deleted !`, 'employee deleted' ,
            {"positionClass": "toast-bottom-left",})

        }
    })
    .catch(err => dispatch({type: EMPLOYEE_ERROR}));

}

export const updateEmployee = (formData, user_id, token, employee_id, history) => dispatch => {

    fetch(`${API_URL}/users/${employee_id}/${user_id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: formData
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_EMPLOYEE,
                payload: res.user
            })

            toastr.success(`employee ${res.user.firstname} UPDATED !`, 'employee' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/employees');
        }
    })
    .catch(err => dispatch({type: EMPLOYEE_ERROR}));
}