import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getRoles, updateRole, updateRoleModuleAccess } from '../../../actions/roleActions';
import { GET_ROLE } from '../../../actions/types';
import { isAuthenticated } from '../../../auth/helpers';
import { API_URL } from '../../../config';
import LayoutSettings from '../../../core/LayoutSettings'
import Page500 from '../../../core/Page500';
import Spinner from '../../../core/Spinner';

const initialState = {
    hasAccessModuleDoctors: false,
    hasAccessModulePatients: false,
    hasAccessModuleAppointments: false,
    hasAccessModuleDepartments: false,
    hasAccessModuleEmployees: false,
    hasAccessModuleEmployeesSalaries: false,
    hasAccessModuleEvents: false,
    hasAccessModuleLeaves: false,
    hasAccessModuleHolidays: false,
    hasAccessModuleInvoices: false,
    hasAccessModuleTaxs: false,
    hasAccessModuleExpenses: false,
    hasAccessModuleAssets: false,
    hasAccessModuleActivities: false,
    hasAccessModuleChat: false,
    hasAccessModuleCalls: false,
    hasAccessModuleCompanySettings: false,
    hasAccessModuleBasicSettings: false,
    hasAccessModuleThemeSettings: false,
    hasAccessModuleRolesAndPermissions: false,
    hasAccessModuleEmailSettings: false,
    hasAccessModuleInvoiceSettings: false,
    hasAccessModuleSalarySettings: false,
    hasAccessModuleNotificationsSettings: false,
    hasAccessModuleChangePassword: false,
    hasAccessModuleLeaveType: false,
}

const RolesPermissions = ({match}) => {

    const dispatch = useDispatch();
    const { user, token } = isAuthenticated();

    const roles = useSelector(state => state.role.roles);
    const loading = useSelector(state => state.role.loading);
    const error = useSelector(state => state.role.error);
    
    const role_id = match.params.id;
    const [formData, setFormData] = useState(initialState);


    
    useEffect(() => {
        dispatch(getRoles())
        if(role_id) getRole(role_id);

    }, [role_id]);

    const getRole = id => {

        fetch(`${API_URL}/roles/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {

            setFormData(res.role);
            
            dispatch({
                type: GET_ROLE,
                payload: res.role
            })

        })
        .catch(err => console.error(err))
    }

    const handleChange = e => {
        
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setFormData({...formData, [name]: value}); 
    }


    const submit = e => {

        e.preventDefault();
        
        dispatch(updateRoleModuleAccess(formData, user, token, role_id));
    }

    if (error) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading ? (<Spinner />) : (

                <Fragment>
                    <LayoutSettings>
                        <div className="row">
                            <div className="col-sm-8">
                                <h4 className="page-title">Rôles et autorisations</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                <Link to="/settings/roles-permissions/create" className="btn btn-primary btn-block"><i className="fa fa-plus"></i> Ajouter un rôle</Link>
                                <div className="roles-menu">
                                    <ul>
                                        {roles && roles.map((role, index) => (
                                            <li key={index} className={role._id === role_id ? 'active' : ''}> {/* className="active" */}
                                                <Link to={`/settings/roles-permissions/${role._id}`}>{role ? role.name : ''}</Link>
                                                <span className="role-action">
                                                    <Link to={`/settings/roles-permissions/${role._id}/update`}>
                                                        <span className="action-circle large mr-1">
                                                            <i className="material-icons mt-1">edit</i>
                                                        </span>
                                                    </Link>
                                                    <a href="#" data-toggle="modal" data-target="#delete_role">
                                                        <span className="action-circle large delete-btn">
                                                            <i className="material-icons mt-1">delete</i>
                                                        </span>
                                                    </a>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {role_id && (
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                    <form onSubmit={submit}>
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="card-title m-b-20 mt-3">MODULES GENERAUX</h6>
                                            </div>
                                            <div className="col">
                                                <button style={{ float: "right" }} type="submit" className="btn btn-warning"><i className="fa fa-edit"></i> Appliquer les changements</button>
                                            </div>    
                                        </div>
                                        <div className="m-b-30">
                                            <ul className="list-group">
                                                <div className="row">
                                                    <li className="list-group-item col-sm-4">
                                                        Appels
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleCalls" checked={formData.hasAccessModuleCalls}/>
                                                            <label htmlFor="calls_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Chat
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleChat" checked={formData.hasAccessModuleChat}/>
                                                            <label htmlFor="chat_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Changer le mot de passe
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleChangePassword" checked={formData.hasAccessModuleChangePassword}/>
                                                            <label htmlFor="change_password_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Congés
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleLeaves" checked={formData.hasAccessModuleLeaves}/>
                                                            <label htmlFor="leave_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Départements
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleDepartments" checked={formData.hasAccessModuleDepartments}/>
                                                            <label htmlFor="departments_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Dépenses
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleExpenses" checked={formData.hasAccessModuleExpenses}/>
                                                            <label htmlFor="expenses_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Employés
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleEmployees" checked={formData.hasAccessModuleEmployees}/>
                                                            <label htmlFor="employees_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Evénements
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleEvents" checked={formData.hasAccessModuleEvents}/>
                                                            <label htmlFor="events_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Factures
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleInvoices" checked={formData.hasAccessModuleInvoices}/>
                                                            <label htmlFor="invoices_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Médecins 
                                                        <div className="float-right">
                                                            <input onChange={handleChange}  name="hasAccessModuleDoctors" type="checkbox" checked={formData.hasAccessModuleDoctors}/>
                                                            <label className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Patients
                                                        <div className="float-right">
                                                            <input onChange={handleChange} name="hasAccessModulePatients" type="checkbox" checked={formData.hasAccessModulePatients}/>
                                                            <label htmlFor="patients_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4" style={{ pointer: formData && formData.name === 'Administrateur' ? 'none' : '',  opacity: formData && formData.name === 'Administrateur' ? 0.5 : 1 }}>
                                                        Rôles & autorisations
                                                        <div className="float-right" >
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleRolesAndPermissions" checked={formData && formData.name === 'Administrateur' || formData.hasAccessModuleRolesAndPermissions}/>
                                                            <label htmlFor="roles_permissions_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Rendez-vous
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleAppointments" checked={formData.hasAccessModuleAppointments}/>
                                                            <label htmlFor="appointments_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Salaires des employés
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleEmployeesSalaries" checked={formData.hasAccessModuleEmployeesSalaries}/>
                                                            <label htmlFor="employees_salaries_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Taxes
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleTaxs" checked={formData.hasAccessModuleTaxs}/>
                                                            <label htmlFor="activities_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Vacances
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleHolidays" checked={formData.hasAccessModuleHolidays}/>
                                                            <label htmlFor="holidays_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                        <div className="m-b-30">
                                            <ul className="list-group">
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="card-title m-b-20 mt-3">MODULES DE PARAMETRAGE</h6>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres de base
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleBasicSettings" checked={formData.hasAccessModuleBasicSettings}/>
                                                            <label htmlFor="basic_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres de facturation
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleInvoiceSettings" checked={formData.hasAccessModuleInvoiceSettings}/>
                                                            <label htmlFor="invoice_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres de l'entreprise
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleCompanySettings" checked={formData.hasAccessModuleCompanySettings}/>
                                                            <label htmlFor="company_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres de messagerie
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleEmailSettings" checked={formData.hasAccessModuleEmailSettings}/>
                                                            <label htmlFor="email_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres de notifications
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleNotificationsSettings" checked={formData.hasAccessModuleNotificationsSettings}/>
                                                            <label htmlFor="notifications_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres des thèmes
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleThemeSettings" checked={formData.hasAccessModuleThemeSettings}/>
                                                            <label htmlFor="theme_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres des types des congés
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleLeaveType" checked={formData.hasAccessModuleLeaveType}/>
                                                            <label htmlFor="theme_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item col-sm-4">
                                                        Paramètres des salaires
                                                        <div className="float-right">
                                                            <input type="checkbox" onChange={handleChange} name="hasAccessModuleSalarySettings" checked={formData.hasAccessModuleSalarySettings}/>
                                                            <label htmlFor="salaries_settings_module" className="badge-success"></label>
                                                        </div>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </LayoutSettings>
                </Fragment>
        )}
    </Fragment>
    )
}

export default RolesPermissions
