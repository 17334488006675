import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { addTax } from '../../../actions/taxActions'

import { isAuthenticated } from '../../../auth/helpers';


import Layout from '../../../core/Layout'

const AddTax = (props) => {

    const dispatch = useDispatch()

    const [tax, setTax] = useState({
        name: '',
        percentage: 0,
        status: 'Pending'
    })

    const handleChange = e => setTax({...tax, [e.target.name]: e.target.value})
    
    const submit = e => {
        e.preventDefault();

        const { user, token } = isAuthenticated();

        dispatch(addTax(tax, user, token, props.history))
        
    }

    const { name, percentage, status } = tax;
    
    return (
        <div>
            <Layout>
            <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4 className="page-title">Add Tax</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <form onSubmit={submit}>
                            <div className="form-group">
                                <label>Tax Name <span className="text-danger">*</span></label>
                                <input name="name" onChange={handleChange} className="form-control" type="text" value={name} />
                            </div>
                            <div className="form-group">
                                <label>Tax Percentage (%) <span className="text-danger">*</span></label>
                                <input name="percentage" onChange={handleChange} className="form-control" type="number" value={percentage} />
                            </div>
                            <div className="form-group">
                                <label>Status <span className="text-danger">*</span></label>
                                <select name="status" onChange={handleChange} className="select form-control" value={status}>
                                    <option>Pending</option>
                                    <option>Approved</option>
                                </select>
                            </div>
                            <div className="m-t-20 text-center">
                                <Link className="mr-2" to="/appointments">
                                    <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                                </Link>
                                <button className="btn btn-primary submit-btn">Create Tax</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default AddTax
