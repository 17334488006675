import { 
    ADD_EMPLOYEE, 
    CLEAR_EMPLOYEE, 
    DELETE_EMPLOYEE, 
    EMPLOYEE_ERROR, 
    GET_EMPLOYEE, 
    GET_EMPLOYEES, 
    UPDATE_EMPLOYEE
} from "../actions/types";

let myState = {
    employees: [],
    employee: null,
    loading: true,
    error: false
}

const employeeReducer = (state = myState, action) => {
    switch (action.type) {

        case GET_EMPLOYEES:
            return {
                ...state,
                employees: action.payload,
                loading: false,
                error: false
            }
        case GET_EMPLOYEE:
            return {
                ...state,
                employee: action.payload,
                loading: false,
                error: false
            }
        case DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.filter(employee => employee._id !== action.payload),
                loading: false,
                error: false
            }
        case ADD_EMPLOYEE:
            return {
                ...state,
                employees: [...state.employees, action.payload],
                loading: false,
                error: false
            }
        case UPDATE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.map(employee => employee._id === action.payload._id ? (employee = action.payload) : employee),
                loading: false,
                error: false
            }
        case CLEAR_EMPLOYEE:
            return {
                ...state,
                employee: null
            }
        case EMPLOYEE_ERROR:
            return {
                ...state,
                employee: null,
                loading: false,
                error: true
            }
        default:{
            return state
        }
    }
}

export default employeeReducer;
