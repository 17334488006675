import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { getLeaves, removeLeave, updateLeaveStatus } from '../../../actions/leaveActions'
import Layout from '../../../core/Layout'
import moment from 'moment';
import classnames from 'classnames';

import Spinner from '../../../core/Spinner';
import { Modal, Button } from 'react-bootstrap';
import { isAuthenticated } from '../../../auth/helpers'
import Page500 from '../../../core/Page500'

const ListLeaveRequests = () => {

    const { user, token } = isAuthenticated();

    const dispatch = useDispatch();
    const leaves = useSelector(state => state.leave.leaves);
    const loading = useSelector(state => state.leave.loading);
    const error = useSelector(state => state.leave.error);

    const [show, setShow] = useState(false);
    const [deletedLeaveId, setDeletedLeaveId] = useState('') ;
    const [searchData, setSearchData] = useState('');
    let [limit, setLimit] = useState(8);

    useEffect(() => {
        dispatch(getLeaves({limit}))
    }, []);

    const handleStatus = (e,leave) => {
        dispatch(updateLeaveStatus(user, token, leave, e.target.dataset.status))
    }

    // search 
    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value })
    }

    const searchSubmit = (e) => {
        e.preventDefault();
    
        if(searchData && searchData.name != "" && searchData.type != ""  && searchData.status != "" && searchData.fromDate != "" && searchData.toDate != ""){
            
            dispatch(getLeaves({search: searchData}));
        
        }else{
            dispatch(getLeaves({limit:8, skip:0}))
                setLimit(8);
        }    
    }

    // delete
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedLeaveId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {
        dispatch(removeLeave(deletedLeaveId, user, token))
        setShow(false);
    }

    const deleteLeaveModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Leave ?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }

    if (error) {
        return <Page500 />;
    }

    if (!leaves) {
        return <span>Loading...</span>;
    }

    return (
        <Fragment>
            {loading || !leaves ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                    <div className="row">
                            <div className="col-sm-8 col-6">
                                <h4 className="page-title">Leave Request</h4>
                            </div>
                            <div className="col-sm-4 col-6 text-right m-b-30">
                                <Link to="/leaves/create" className="btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Add Leave</Link>
                            </div>
                        </div>
                        <form onSubmit={searchSubmit}>
                            <div className="row filter-row">
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Employee Name</label>
                                        <input name="name" onChange={handleChange} type="text" className="form-control floating"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                    <div className="form-group">
                                        <select style={{ height: 'calc(2.25rem + 14px)' }} name="type" onChange={handleChange} className="form-control">
                                            <option value=""> -- Select Leave Type -- </option>
                                            <option>Casual Leave</option>
                                            <option>Medical Leave</option>
                                            <option>Loss of Pay</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                    <div className="form-group">
                                        <select style={{ height: 'calc(2.25rem + 14px)' }} name="status" onChange={handleChange}  className="form-control">
                                            <option value=""> -- Select Status -- </option>
                                            <option>Pending</option>
                                            <option>Approved</option>
                                            <option>Rejected</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">From</label>
                                        <input className="form-control" name="fromDate" onChange={handleChange} type="date"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">To</label>
                                        <input className="form-control" name="toDate" onChange={handleChange}  type="date"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                    <button type="submit" className="btn btn-success btn-block">Search</button>
                                </div>
                                
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped custom-table m-b-0">
                                        <thead>
                                            <tr>
                                                <th>Employee</th>
                                                <th>Leave Type</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>No of Days</th>
                                                <th>Reason</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { leaves && leaves.map((leave,i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <a href="#!" className="avatar">{leave.user.firstname ? leave.user.firstname[0] : ''}</a>
                                                        <h2><a href="#!">{leave.user ? leave.user.firstname : ''} {leave.user ? leave.user.lastname : ''} <span>{leave.user ? leave.user.role : ''}</span></a></h2>
                                                    </td>
                                                    <td>{leave.type}</td>
                                                    <td>{moment(leave.fromDate).format('DD MMM YYYY') }</td>
                                                    <td>{moment(leave.toDate).format('DD MMM YYYY') }</td>
                                                    <td>{leave.nbOfDays} day(s)</td>
                                                    <td>{leave.reason}</td>
                                                    <td className="text-center">
                                                        <div className="dropdown action-label">
                                                            <a className={classnames('custom-badge  dropdown-toggle',{
                                                                    'status-purple': leave.status == 'Pending',
                                                                    'status-green':  leave.status == 'Approved',
                                                                    'status-red':  leave.status == 'Declined',
                                                                })}
        
                                                            href="#" data-toggle="dropdown" aria-expanded="false">
                                                                {leave.status}
                                                            </a>
                                                            {leave.status !== 'Closed' && (
																<div className="dropdown-menu dropdown-menu-right">
																	{leave.status !== "Approved" && (
																		<a onClick={e => handleStatus(e,leave)} className="dropdown-item" href="#" data-status="Approved">Approved</a>
																	)}
																	{leave.status !== "Pending" && (
																		<a onClick={e => handleStatus(e,leave)} className="dropdown-item" href="#" data-status="Pending">Pending</a>
																	)}
																	{leave.status !== "Declined" && (
																		<a onClick={e => handleStatus(e,leave)} className="dropdown-item" href="#" data-status="Declined">Declined</a>
																	)}
																</div>
															)}
                                                        </div>
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="dropdown dropdown-action">
                                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <li><Link className="dropdown-item" to={`/leaves/${leave._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link></li>
                                                                <li><a style={{cursor: "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,leave._id)}><i className="fa fa-trash-o m-r-5"></i> Delete</a></li>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {deleteLeaveModal()}
                        </div>
                    </Layout>
                </Fragment>)
            }  
        </Fragment>
    )
}

export default ListLeaveRequests
