import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Layout from '../../../core/Layout';
import { getInvoices, removeInvoice } from '../../../actions/invoiceActions';
import Spinner from '../../../core/Spinner';
import Page500 from '../../../core/Page500';
import { isAuthenticated } from '../../../auth/helpers';
import { Modal, Button } from 'react-bootstrap';

const ListInvoices = () => {

    const dispatch = useDispatch();
    const invoices = useSelector(state => state.invoice.invoices);
    const loading = useSelector(state => state.invoice.loading);
    const error = useSelector(state => state.invoice.error);
    const [show, setShow] = useState(false);
	const [deletedInvoiceId, setDeletedInvoiceId] = useState('');

    useEffect(() => {
        dispatch(getInvoices());
    }, []);

    // handle status ------------------------------------
	const handleClass = (status) => {
		switch(status){
			case 'Partially Paid':
				return 'status-orange';
			
			case 'Paid':
				return 'status-green';

			case 'Sent':
				return 'status-blue';

			default:
				return 'status-red'
		}
	}

    // delete -------------------------------------------
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedInvoiceId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {
		const { user, token } = isAuthenticated();
        dispatch(removeInvoice(deletedInvoiceId, user._id, token));
        setShow(false);
    }

    const deleteInvoiceModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Invoice ?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }

	// end delete ------------------------------------------

    if(error) return <Page500/>;
   

    return (
        <Fragment>
            {loading ? (<Spinner />) : (
			
			<Fragment>
                <Layout>
                <div className="row">
                        <div className="col-sm-5 col-4">
                            <h4 className="page-title">Invoices</h4>
                        </div>
                        {/* <div className="col-sm-7 col-8 text-right m-b-30">
                            <Link to="/invoices/create" className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Create New Invoice</Link>
                        </div> */}
                    </div>
                    <div className="row filter-row">
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group form-focus">
                                <label className="focus-label">From</label>
                                <div className="cal-icon">
                                    <input className="form-control floating datetimepicker" type="text"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group form-focus">
                                <label className="focus-label">To</label>
                                <div className="cal-icon">
                                    <input className="form-control floating datetimepicker" type="text"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <select style={{ height: 'calc(2.25rem + 14px)' }} id="type" className="form-control">
                                    <option>Select Status</option>
                                    <option>Pending</option>
                                    <option>Paid</option>
                                    <option>Partially Paid</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <a href="#" className="btn btn-success btn-block"> Search </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table">
                                <table className="table table-striped custom-table m-b-0">
                                    <thead>
                                        <tr>
                                            <th>Invoice Number</th>
                                            <th>Patient</th>
                                            <th>Due Date</th>
                                            <th>Amount</th>
                                            <th>Rest</th>
                                            <th>Method</th>
                                            <th>Status</th>
                                            <th className="text-right">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {invoices && invoices.map((invoice, index) => (
                                            <tr key={index}>
                                                <td><Link to={`/invoices/${invoice._id}/view`}>#INV-{invoice._id}</Link></td>
                                                <td>{invoice.appointment.patient ? `${invoice.appointment.patient.firstname} ${invoice.appointment.patient.lastname}` : ''}</td>
                                                <td>{moment(invoice.dueDate).format('DD MMM YYYY')}</td>
                                                <td>{invoice.grandTotal ? invoice.grandTotal.toFixed(2) : 0} {invoice.currency}</td>
                                                <td>{invoice.rest === 0 ? '-' : invoice.rest}</td>
                                                <td>{invoice.paidBy}</td>
                                                <td>
                                                    <span className={`custom-badge ${handleClass(invoice.status)}`}>{invoice.status}</span>
                                                </td>
                                                <td className="text-right">
                                                    <div className="dropdown dropdown-action">
                                                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            {invoice.status !== 'Paid' && (
                                                                <Link className="dropdown-item" to={`/appointments/${invoice.appointment._id}/invoices/${invoice._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link>
                                                            )}
                                                            <Link className="dropdown-item" to={`/invoices/${invoice._id}/view`}><i className="fa fa-eye m-r-5"></i> View</Link>
                                                            <a className="dropdown-item" href="#"><i className="fa fa-file-pdf-o m-r-5"></i> Download</a>
                                                            {invoice.status !== 'Paid' && (
                                                                <a className="dropdown-item" title="Supprimer"  onClick={handleShow.bind(this,invoice._id)}><i className="fa fa-trash m-r-5"></i> Delete</a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {deleteInvoiceModal()}
                </Layout>
            </Fragment>)
		}  
        </Fragment>
    )
}

export default ListInvoices
