import React, { Children, Fragment } from 'react'
import Header from './Header';
import SidebarSettings from './SidebarSettings';

function LayoutSettings({children}) {
    return (
        <Fragment>
            <Header/>
            <SidebarSettings/>
            <div className="page-wrapper">
                <div className="content">
                    {children}
                </div>  
            </div>
        </Fragment>
    )
}

export default LayoutSettings
