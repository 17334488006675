import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutSettings from '../../core/LayoutSettings';

import { isAuthenticated } from './../../auth/helpers';
import { GET_SETTINGS, SETTINGS_ERROR } from '../../actions/types';
import { API_URL } from '../../config';

import { updateSettings } from '../../actions/settingsActions';
import Spinner from '../../core/Spinner';
import { Link } from 'react-router-dom';

const Localization = (props) => {

    const [formData, setFormData] = useState(new FormData());
    
    let dispatch = useDispatch();
    const settingsDispatch = useSelector(state => state.settings.settings);
    const loading = useSelector(state => state.settings.loading);
    
    const [settings, setSettings] = useState({
        defaultCountry: '',
        dateformat: '',
        timezone: '',
        defaultLanguage: '',
        currencyCode: '',
        currencySymbole: ''
    })

    const getSettings = async () => {

        try {
            const res = await fetch(`${API_URL}/settings`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            });

            const res_1 = await res.json();

            setSettings(res_1.settings[0]);
            formData.set('defaultCountry', res_1.settings[0].defaultCountry || 'Morocco');
            formData.set('dateFormat', res_1.settings[0].dateFormat || 'd M Y');
            formData.set('timezone', res_1.settings[0].timezone || '(UTC +1) Casablanca/Morocco');
            formData.set('defaultLanguage', res_1.settings[0].defaultLanguage || 'English');
            formData.set('currencyCode', res_1.settings[0].currencyCode || 'MAD');
            formData.set('currencySymbole', res_1.settings[0].currencySymbole || 'DH');

            dispatch({
                type: GET_SETTINGS,
                payload: res_1.settings[0]
            });
            
        } catch (err) {
            dispatch({type: SETTINGS_ERROR});
        }
    }

    useEffect(() => {
        getSettings()
    }, [])


    const handleChange = e => {
        formData.set(e.target.name, e.target.value)
        setSettings({...settings, [e.target.name]: e.target.value})
    }

    const submit = e => {
        e.preventDefault()
        
        const { user, token } = isAuthenticated();

        dispatch(updateSettings(formData, user, token, props.history))
    }


    return (
        <Fragment>
            {loading || !settingsDispatch ? (<Spinner />) : (

                <Fragment>
                    <LayoutSettings>
                        <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <form onSubmit={submit}>
                            <h3 className="page-title">Paramètres de base</h3>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Pays par défaut</label>
                                        <select name="defaultCountry" value={settings ? settings.defaultCountry : ''} className="form-control" onChange={handleChange}>
                                            <option value="">Sélectionnez le pays par défaut</option>
                                            <option value="Morocco">Maroc</option>
                                            <option value="USA">Etats-Unis</option>
                                            <option value="United Kingdom">Royaume-Uni</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Format de date</label>
                                        <select name="dateFormat" value={settings ? settings.dateFormat : ''} className="form-control" onChange={handleChange}>
                                            <option value="">Sélectionnez le format de la date</option>
                                            <option value="d/m/Y">15/05/2021</option>
                                            <option value="d.m.Y">15.05.2021</option>
                                            <option value="d-m-Y">15-05-2021</option>
                                            <option value="m/d/Y">05/15/2021</option>
                                            <option value="Y/m/d">2021/05/15</option>
                                            <option value="Y-m-d">2021-05-15</option>
                                            <option value="M d Y">May 15 2021</option>
                                            <option value="d M Y">15 May 2021</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Fuseau horaire</label>
                                        <select name="timezone" value={settings ? settings.timezone : ''} className="form-control" onChange={handleChange}>
                                            <option value="">Sélectionnez le fuseau horaire</option>
                                            <option>(UTC +1) Casablanca/Morocco</option>
                                            <option>(UTC +5:30) Antarctica/Palmer</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Langage par défaut</label>
                                        <select name="defaultLanguage" value={settings ? settings.defaultLanguage : ''} className="form-control" onChange={handleChange}>
                                            <option value="">Sélectionnez la langue par défaut</option>
                                            <option value="Arabic">Arabe</option>
                                            <option value="English">Anglais</option>
                                            <option value="French">Français</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Code de devise</label>
                                        <select name="currencyCode" value={settings ? settings.currencyCode : ''} className="form-control" onChange={handleChange}>
                                            <option value="">Sélectionnez le code de devise</option>
                                            <option>MAD</option>
                                            <option>USD</option>
                                            <option>Pound</option>
                                            <option>EURO</option>
                                            <option>Ringgit</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Symbole de la monnaie</label>
                                        <input  name="currencySymbole" value={settings ? settings.currencySymbole : ''} className="form-control" onChange={handleChange} type="text"/>
                                    </div>
                                </div>
								<div className="col-sm-12 text-center m-t-20">
									<Link to="/dashboard" className="btn btn-secondary submit-btn mr-2">Annuler</Link>
									<button type="submit" className="btn btn-primary submit-btn">Enregistrer</button>
								</div>
                            </div>
                        </form>
                    </div>
                </div>
                    </LayoutSettings>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Localization
