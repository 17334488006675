import React, {Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHoliday, updateHoliday } from '../../../actions/holidayActions';

import Layout from '../../../core/Layout';

import { isAuthenticated } from '../../../auth/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Spinner from '../../../core/Spinner';
import Page500 from '../../../core/Page500';
import { GET_HOLIDAY, HOLIDAY_ERROR } from '../../../actions/types';
import { API_URL } from '../../../config';

const initialState = {
    title: "",
    holidayDate: new Date(),
    nbDays: 0
};

const FormHoliday = ({match, history}) => {
    
    const [formData, setFormData] = useState(initialState);
    const dispatch = useDispatch();
    const holiday_id = match.params.id;
    const loading = useSelector(state => state.holiday.loading);
    const error = useSelector(state => state.holiday.error);

    const loadHoliday = id => {
        return fetch(`${API_URL}/holidays/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {

            dispatch({
                type: GET_HOLIDAY,
                payload: res.holiday
            });

            setFormData(res.holiday);
        })
        .catch(err => dispatch({type: HOLIDAY_ERROR}));
    }

    useEffect(() => {
        if(holiday_id) loadHoliday(holiday_id);
    }, [holiday_id]);

    const { title, holidayDate, nbDays } = formData;

    const handleChange = e => {
        setFormData({...formData, [e.target.name]:e.target.value});
    }

    const submit = e => {
        e.preventDefault();

        const { user, token } = isAuthenticated();

        if(holiday_id){
            dispatch(updateHoliday(formData, user._id, token, holiday_id, history))
        }else{
            dispatch(addHoliday(formData, user._id, token, history));
        }
    }

    if (error) {
        return <Page500 />;
    }


    return (
        <Fragment>
            {loading && holiday_id ? ( <Spinner/>) : (
                <Fragment>
                    <Layout>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h4 className="page-title">{ holiday_id ? "Update" : "Add" } Holiday</h4>
                            </div>
                        </div>
                        <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <form onSubmit={submit}>
                            <div className="row">
                                <div className="col-sm-4 col-xs-12 form-group">
                                    <label>Holiday Title <span className="text-danger">*</span></label>
                                    <input name="title" onChange={handleChange} value={title} className="form-control" type="text"/>
                                </div>
                                <div className="col-sm-4 col-xs-12 form-group">
                                    <label>Holiday Date <span className="text-danger">*</span></label>
                                    <input name="holidayDate" onChange={handleChange} value={moment(holidayDate).format('YYYY-MM-DD') } className="form-control datetimepicker" type="date"/>
                                </div>
                                <div className="col-sm-4 col-xs-12 form-group">
                                    <label>Nombre de jours <span className="text-danger">*</span></label>
                                    <input  name="nbDays" onChange={handleChange} value={nbDays} className="form-control" type="number" min="0"/>
                                </div>
                            </div>
                            <div className="m-t-20 text-center">
                                <Link className="mr-2" to="/holidays">
                                    <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                                </Link>
                                {holiday_id ? (
                                    <button type="submit" className="btn btn-warning submit-btn">Update Holiday</button>
                                ): (
                                    <button type="submit" className="btn btn-primary submit-btn">Create Holiday</button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
                    </Layout>
                </Fragment>
            )}
        </Fragment>
    )
}

export default FormHoliday
