import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { addEmployeeSalary } from '../../actions/employeeSalaryActions';
import { isAuthenticated } from '../../auth/helpers';
import { API_URL } from '../../config';
import Layout from '../../core/Layout'

const AddEmployeeSalary = (props) => {

    const dispatch = useDispatch()

    const [users, setUsers] = useState([]);

    const getUsers = (params) => {

        return fetch(`${API_URL}/users`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params)
        })
        .then(res => res.json())
        .then(res => {
            setUsers(res.users)
        })
        .catch(err => console.error(err))
    }

    let [employeeSalary, setEmployeeSalary] = useState({
        salaryMonth: '',
        user: '',
        netSalary: 0,
        baseSalary: 0,
        CNSS: 0,
        CIMR: 0,
        CNSSAMO: 0,
        assGroup: 0,
        medicalCharges: 0,
        othersDeducations: 0,
        seniorityBonus: 0,
        feastBonusS: 0,
        transporAndLodgementBonus: 0,
        suppSalary125: 0,
        suppSalary150: 0,
        othersEarning: 0
    })

    const [settings, setSettings] = useState({});
        
    const getSettings = () => {

        return fetch(`${API_URL}/settings`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setSettings(res.settings[0])
        })
        .catch(err => console.error(err))
    }

    useEffect(() => {
        getSettings()
        getUsers()
    }, [])

    let CNSSBase = settings.CNSSBase || 0;
    let CNSSRate = settings.CNSSRate || 0;
    let CIMRRate = settings.CIMRRate || 0;
    let CNSSAMORate = settings.CNSSAMORate || 0;
    let assGroupRate = settings.assGroupRate || 0;
    let medicalChargesRate = settings.medicalChargesRate || 0;
    let seniorityBonusBase = settings.seniorityBonusBase || 0;
    let seniorityBonusRate = settings.seniorityBonusRate || 0;
    let suppSalary125Base = settings.suppSalary125Base || 0;
    let suppSalary125Rate = settings.suppSalary125Rate || 0;
    let suppSalary150Base = settings.suppSalary150Base || 0;
    let suppSalary150Rate = settings.suppSalary150Rate || 0;
    let transporAndLodgementBonus = settings.transporAndLodgementBonus || 0;
    let feastBonus = settings.feastBonus || 0;    


    const handleChange = e => {
        let key = ""
        let value = ""
        
        if(e.target.name === 'user' || e.target.name === 'salaryMonth'){
            key = e.target.name
            value = e.target.value  
        }
        else{
            key = e.target.name
            value = Number(e.target.value)
        } 

        setEmployeeSalary({...employeeSalary,
            [key]: value,
           seniorityBonus : Number(seniorityBonusBase)*Number(seniorityBonusRate)/100,
           transporAndLodgementBonus : Number(transporAndLodgementBonus),
           suppSalary125 : Number(suppSalary125Base)*Number(suppSalary125Rate),
           suppSalary150 : Number(suppSalary150Base)*Number(suppSalary150Rate),
           earnings : (Number(seniorityBonusBase)*Number(seniorityBonusRate)/100) + (Number(suppSalary125Base)*Number(suppSalary125Rate)) + (Number(suppSalary150Base)*Number(suppSalary150Rate)) + Number(transporAndLodgementBonus) + Number(feastBonus) + Number(othersEarning),
           grossSalary : Number(baseSalary) + Number(earnings),
           deducations : Number(CNSS) + (Number(grossSalary)*Number(CIMRRate)/100) + (Number(grossSalary)*Number(CNSSAMORate)/100) + (Number(grossSalary)*Number(assGroupRate)/100) + (Number(grossSalary)*Number(medicalChargesRate)/100) + Number(othersDeducations),
           CNSS : Number(CNSSBase)*Number(CNSSRate)/100,
           CIMR : Number(grossSalary)*Number(CIMRRate)/100,
           CNSSAMO : Number(grossSalary)*Number(CNSSAMORate)/100,
           assGroup : Number(grossSalary)*Number(assGroupRate)/100,
           medicalCharges : Number(grossSalary)*Number(medicalChargesRate)/100,
           netSalary : Number(grossSalary) - Number(deducations)
       })
    }

    let {
        salaryMonth,
        netSalary,
        baseSalary,
        CNSS,
        CIMR,
        CNSSAMO,
        assGroup,
        medicalCharges,
        othersDeducations,
        seniorityBonus,
        suppSalary125,
        suppSalary150,
        othersEarning,
        grossSalary, 
        deducations, 
        earnings 
    } = employeeSalary


    seniorityBonus = Number(seniorityBonusBase)*Number(seniorityBonusRate)/100;
    transporAndLodgementBonus = Number(transporAndLodgementBonus);
    suppSalary125 = Number(suppSalary125Base)*Number(suppSalary125Rate);
    suppSalary150 = Number(suppSalary150Base)*Number(suppSalary150Rate);
    
    earnings = (Number(seniorityBonusBase)*Number(seniorityBonusRate)/100) + (Number(suppSalary125Base)*Number(suppSalary125Rate)) + (Number(suppSalary150Base)*Number(suppSalary150Rate)) + Number(transporAndLodgementBonus) + Number(feastBonus) + Number(othersEarning)
    grossSalary = Number(baseSalary) + Number(earnings)
    deducations = Number(CNSS) + (Number(grossSalary)*Number(CIMRRate)/100) + (Number(grossSalary)*Number(CNSSAMORate)/100) + (Number(grossSalary)*Number(assGroupRate)/100) + (Number(grossSalary)*Number(medicalChargesRate)/100) + Number(othersDeducations)
    
    CNSS = Number(CNSSBase)*Number(CNSSRate)/100;
    CIMR = Number(grossSalary)*Number(CIMRRate)/100;
    CNSSAMO = Number(grossSalary)*Number(CNSSAMORate)/100;
    assGroup = Number(grossSalary)*Number(assGroupRate)/100;
    medicalCharges = Number(grossSalary)*Number(medicalChargesRate)/100;
    netSalary = Number(grossSalary) - Number(deducations);

    const submit = e => {
        e.preventDefault();

        setEmployeeSalary({
            ...employeeSalary,
            salaryMonth,
            netSalary,
            baseSalary,
            CNSS,
            CIMR,
            CNSSAMO,
            assGroup,
            medicalCharges,
            othersDeducations,
            seniorityBonus,
            feastBonus,
            transporAndLodgementBonus,
            suppSalary125,
            suppSalary150,
            othersEarning,
            grossSalary, 
            deducations, 
            earnings 
        })

        console.log(employeeSalary)

        const { user, token } = isAuthenticated();

        dispatch(addEmployeeSalary(employeeSalary, user, token, props.history))
    }

    return (
        <div>
            
            <Layout>
            <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4 className="page-title">Add Staff Salary</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <form onSubmit={submit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Select Staff</label>
                                        <select name="user" onChange={handleChange} className="select form-control">
                                            <option value="">Select an employee</option>
                                            {users && users.map((user,i) => (
                                                <option key={i} value={user._id}>{user.firstname} {user.lastname}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
									<div className="form-group">
										<label>Salary Month</label>
										<input className="form-control" type="month" name="salaryMonth" value={salaryMonth} onChange={handleChange}/>
									</div>
                                </div>
                                <div className="col-sm-6">
									<div className="form-group">
										<label>Base Salary</label>
										<input className="form-control" type="text" name="baseSalary" value={baseSalary} onChange={handleChange}/>
									</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4 className="text-primary">Earnings</h4>
                                    <div className="form-group">
                                        <label>Feast Bonus</label>
                                        <input className="form-control" type="text" readOnly name="feastBonus" value={feastBonus} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Seniority Bonus (15%)</label>
                                        <input className="form-control" type="text" readOnly name="seniorityBonus" value={seniorityBonus} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Transport & Lodgement</label>
                                        <input className="form-control" type="text" readOnly name="transporAndLodgementBonus" value={transporAndLodgementBonus} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Supp Salary (125%)</label>
                                        <input className="form-control" type="text" readOnly name="suppSalary125" value={suppSalary125} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Supp Salary (150%)</label>
                                        <input className="form-control" type="text" readOnly name="suppSalary150" value={suppSalary150} />
                                    </div>
                                    <div className="form-group">
                                        <label>Others earnings</label>
                                        <input className="form-control" type="text" name="othersEarning" value={othersEarning} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <h4 className="text-primary">Deductions</h4>
                                    <div className="form-group">
                                        <label>CNSS (4.29%)</label>
                                        <input className="form-control" type="text" readOnly name="CNSS" value={CNSS} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>CIMR (6%)</label>
                                        <input className="form-control" type="text" readOnly name="CIMR" value={CIMR} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>CNSS-AMO (2%)</label>
                                        <input className="form-control" type="text" readOnly name="CNSSAMO" value={CNSSAMO} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Ass.groupe (2%)</label>
                                        <input className="form-control" type="text" readOnly name="assGroup" value={assGroup} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Medical charges (1.02%)</label>
                                        <input className="form-control" type="text" readOnly name="medicalCharges" value={medicalCharges} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Others deducations</label>
                                        <input className="form-control" type="text" name="othersDeducations" value={othersDeducations} onChange={handleChange}/>
                                    </div>
                                </div>
                                

                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <h4 className="text-primary">Calculator</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Earnings</label>
                                        <input className="form-control" type="text" readOnly name="earnings" value={earnings} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Deductions</label>
                                        <input className="form-control" type="text" readOnly name="deducations" value={deducations} onChange={handleChange}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <h4 className="text-primary">Salaries</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Gross Salary (Base salary + Earnings)</label>
                                        <input className="form-control" type="text" readOnly name="grossSalary" value={grossSalary} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Net salary</label>
                                    <input className="form-control" type="text" readOnly name="netSalary" value={netSalary} onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="m-t-20 text-center">
                                <button className="btn btn-primary submit-btn">Create Salary</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default AddEmployeeSalary
