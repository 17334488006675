import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Layout from '../../core/Layout';
import { Link } from 'react-router-dom';
import moment from 'moment';


import { isAuthenticated } from '../../auth/helpers';
import { API_URL } from '../../config';
import Spinner from '../../core/Spinner';

import { addPatient, updatePatient } from '../../actions/patientActions';
import { GET_PATIENT, PATIENT_ERROR } from '../../actions/types';
import Page500 from '../../core/Page500';


const initialState = {
    firstname: '',
    lastname: '',
    birthday: '',
    gender: '',
    cin: '',
    email: '',
    address: '',
    country: '',
    city: '',
    postalcode: '',
    phone: '',
    photo: '',
    status: ''
}

const FormPatient = ({ match, history }) => {

    const patient_id = match.params.id;

    const dispatch = useDispatch();
    const loading = useSelector(state => state.patient.loading);
    const error = useSelector(state => state.patient.error);
    
    const [formData, setFormData] = useState(new FormData());

    const [updPatient, setUpdPatient] = useState(initialState)
    
    const getPatient = async id => {

        try {
            const res = await axios(`${API_URL}/patients/${id}`);
            const patient = res.data.patient;

            if(!loading && patient) {
                const patientData = { ...initialState };
                for(const key in patient){
                    if(key in patientData && key !== 'photo') {
                        patientData[key] = patient[key];
                        formData.set(key, patient[key])
                    }
                }
                setUpdPatient(patientData);
            }
            
            dispatch({
                type: GET_PATIENT,
                payload: res.data.user
            });
            
        }catch (err) {
            dispatch({type: PATIENT_ERROR});
        }
    };

    useEffect(() => {
        const id = match.params.id;
        if (id) getPatient(id);
    }, [match.params.id]); 


    const {
        firstname,
        lastname,
        birthday,
        gender,
        cin,
        email,
        address,
        country,
        city,
        postalcode,
        phone,
        status
    } = updPatient;

    const handleChange = e => {

        let key = e.target.name;
        let value = ""
        
        if(e.target.type === 'file' && e.target.name === 'photo'){
            value = e.target.files[0]  
        }else{
            value = e.target.value
        } 
        
        formData.set(key, value)

        setUpdPatient({...updPatient, [key]: value})

    }



    const submitPatient = e => {

        e.preventDefault();

        const { user, token } = isAuthenticated();

        if(patient_id){
            dispatch(updatePatient(formData, user, token, patient_id, history));       
        }else{
            dispatch(addPatient(formData, user, token, history));       
        }
    }

    if (error == true) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading && patient_id ? ( <Spinner/>) : (
            <Fragment>
                <Layout>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h4 className="page-title">{patient_id ? 'Modifier le patient' : 'Ajouter un patient'}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <form onSubmit={submitPatient}>
                                <div className="row">
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="firstname">Prénom <span className="text-danger">*</span></label>
                                            <input name="firstname" onChange={handleChange} className="form-control" type="text" value={firstname}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="lastname">Nom <span className="text-danger">*</span></label>
                                            <input name="lastname" onChange={handleChange} className="form-control" type="text" value={lastname}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="birthday">Date de naissance <span className="text-danger">*</span></label>
                                            <div>
                                                <input name="birthday" onChange={handleChange} type="date" className="form-control datetimepicker" value={moment(birthday).format('YYYY-MM-DD')} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <div className="form-group gender-select">
                                            <label className="gen-label">Sexe <span className="text-danger">*</span></label>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input onChange={handleChange} type="radio" name="gender" className="form-check-input" value="Male" checked={gender === "Male"} />Masculin
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input onChange={handleChange} type="radio" name="gender" className="form-check-input" value="Female" checked={gender === "Female"} />Féminin
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label htmlFor="cin">CIN <span className="text-danger">*</span></label>
                                            <input name="cin" onChange={handleChange} className="form-control" type="text" value={cin}/>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="phone">Téléphone <span className="text-danger">*</span></label>
                                            <input name="phone" onChange={handleChange} className="form-control" type="text" value={phone}/>
                                        </div>
                                    </div>

                                    <div className="col-sm-5">
                                        <div className="form-group">
                                            <label>Adresse email </label>
                                            <input name="email" onChange={handleChange} className="form-control" type="email" value={email} />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="country">Pays <span className="text-danger">*</span></label>
                                            <select id="country" onChange={handleChange} className="form-control select" value={country}>
                                                <option>Sélectionnez le pays</option>
                                                <option>Maroc</option>
                                                <option>Royaume-Uni</option>
                                                <option>USA</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="city">Ville <span className="text-danger">*</span></label>
                                            <input name="city" onChange={handleChange} type="text" className="form-control" value={city}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="postalcode">Code postal</label>
                                            <input name="postalcode" onChange={handleChange} type="text" className="form-control" value={postalcode} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="address">Adresse</label>
                                            <textarea id="address" onChange={handleChange} type="text" className="form-control" value={address}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className="form-group">
                                            <label>Avatar</label>
                                            <div className="profile-upload">
                                                <div className="upload-img">
                                                    <img alt="" src={patient_id ? `${API_URL}/patients/photo/${patient_id}` : './../../assets/img/user.jpg'}/>
                                                </div>
                                                <div className="upload-input">
                                                    <input name="photo" onChange={handleChange} type="file" className="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="col-lg-6 col-xs-12">
                                        <div className="form-group">
                                            <label className="display-block">Statut <span className="text-danger">*</span></label>
                                            <div className="form-check form-check-inline">
                                                <input onChange={handleChange} className="form-check-input" type="radio" name="status" value="Actif" checked={status === 'Actif'}/>
                                                <label className="form-check-label" htmlFor="doctor_active">
                                                Actif
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input onChange={handleChange} className="form-check-input" type="radio" name="status" value="Inactif" checked={status === 'Inactif'}/>
                                                <label className="form-check-label" htmlFor="doctor_inactive">
                                                Inactif
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-t-20 text-center">
                                <Link className="mr-2" to="/patients">
                                    <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Retourner</button>
                                </Link>
                                {patient_id ? (
                                    <button type="submit" className="btn btn-warning submit-btn">Appliquer les modifications</button>
                                    
                                    ): (
                                    <button type="submit" className="btn btn-primary submit-btn">Ajouter un patient</button>
                                )}
                                </div>
                            </form>
                        </div>
                    </div>
                </Layout>
            </Fragment>
            )}
        </Fragment>
    )
}

export default FormPatient;
