import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Layout from '../../core/Layout';
import Spinner from '../../core/Spinner';

import { getDepartments } from '../../actions/departmentActions';
import { getDoctors } from '../../actions/doctorActions';
import { getPatients } from '../../actions/patientActions';
import { createAppointment, updateAppointment } from '../../actions/appointmentActions';

import { isAuthenticated } from '../../auth/helpers';
import { API_URL } from '../../config';

const initialState = {
    patient: "",
    department: "",
    doctor: "",
    appointmentDate: "",
    fromTime: "",
    toTime: "",
    injury: "",
    status: 'Pending'
}


const FormAppointment = ({ history, match }) => {

    const id = match.params.id;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(initialState);

    //load data
    let departments = useSelector(state => state.department.departments);
    let doctors = useSelector(state => state.doctor.doctors);
    let patients = useSelector(state => state.patient.patients);
    let appointment = useSelector(state => state.appointment.appointment);
    let loading = useSelector(state => state.appointment.loading);

    const getAppointment = async id => {

        try {
            const res = await axios(`${API_URL}/appointments/${id}`);
            const appointment = res.data.appointment;

            if (!loading && appointment) {
                const appointmentData = { ...initialState };
                for (const key in appointment) {
                    if (key in appointmentData && (key === 'department' || key === 'patient' || key === 'doctor')) {
                        appointmentData[key] = appointment[key]._id;
                    } else {
                        appointmentData[key] = appointment[key];
                    }
                }
                setFormData(appointmentData);
            }

            dispatch({
                type: 'GET_APPOINTMENT',
                payload: appointment
            });

        } catch (err) {
            //dispatch({type: DOCTOR_ERROR});
        }
    }

    useEffect(() => {
        dispatch(getDepartments());
        dispatch(getDoctors({search: {role: 'Doctor'}}));
        dispatch(getPatients());

        if (id) getAppointment(id);
    }, [])


    const {
        patient,
        department,
        doctor,
        appointmentDate,
        fromTime,
        toTime,
        injury,
        status
    } = formData;

    const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const submitForm = e => {

        const { user, token } = isAuthenticated();

        e.preventDefault();

        id ? dispatch(updateAppointment(id, formData, user._id, token, history)) : dispatch(createAppointment(formData, user._id, token, history));

    }

    return (
        <Fragment>
            <Layout>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4 className="page-title">{id ? 'Update' : 'Add'} Appointment</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <form onSubmit={submitForm}>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="department">Department <span className="text-danger">*</span></label>
                                        <select name="department" onChange={handleChange} className="form-control select" value={department ? department : ''}>
                                            <option>Select</option>
                                            {departments && departments.map((department, i) => (
                                                <option key={i} value={department._id}>{department.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="patient">Patient Name <span className="text-danger">*</span></label>
                                        <select name="patient" onChange={handleChange} className="form-control select" value={patient ? patient : ''}>
                                            <option>Select</option>
                                            {patients && patients.map((patient, i) => (
                                                <option key={i} value={patient._id}>
                                                    {patient.firstname} {patient.lastname}
                                                </option>
                                            ))}
                                        </select>
                                        <Link className="mb-2" style={{ float: 'right' }} to="/patients/create">Add patient</Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="doctor">Doctor <span className="text-danger">*</span></label>
                                        <select name="doctor" onChange={handleChange} className="form-control select" value={doctor ? doctor : ''}>
                                            <option>Select</option>
                                            {doctors && doctors.map((doctor, i) => (
                                                <option key={i} value={doctor._id}>
                                                    {doctor.firstname} {doctor.lastname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="appointmentDate">Date <span className="text-danger">*</span></label>
                                        <div>
                                            <input name="appointmentDate" onChange={handleChange} type="date" className="form-control"
                                                value={moment(appointmentDate).format('YYYY-MM-DD')} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="fromTime">From Time <span className="text-danger">*</span></label>
                                        <input name="fromTime" onChange={handleChange} type="time" className="form-control"
                                            value={fromTime} />

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="toTime">To Time <span className="text-danger">*</span></label>
                                        <input name="toTime" onChange={handleChange} type="time" className="form-control"
                                            value={toTime} />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="status">Status</label>
                                        <select name="status" onChange={handleChange} className="form-control select" value={status}>
                                            <option value="">Select</option>
                                            <option>Pending</option>
                                            <option>Approved</option>
                                            <option>Canceled</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="injury">Injury</label>
                                        <textarea name="injury" rows="4" onChange={handleChange} className="form-control" placeholder="allergy, depression, fracture, ..." value={injury} />
                                    </div>
                                </div>
                            </div>
                            <div className="m-t-20 text-center">
                                <Link className="mr-2" to="/appointments">
                                    <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                                </Link>
                                {id ? (
                                    <button className="btn btn-warning submit-btn">Update Appointment</button>
                                ) : (
                                    <button className="btn btn-primary submit-btn">Create Appointment</button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </Fragment>
    )
}

export default FormAppointment;
