import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    ACCOUNT_DELETED,
    USER_LOADED_FAILED
  } from '../actions/types';
  
const initialState = {
    token: localStorage.getItem('jwt_info'),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null
};

const authReducer = (state = initialState, action) => {

    const { type, payload } = action
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
                error: false
            }
        case USER_LOADED_FAILED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: null,
                error: true
            }
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false 
            }
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
        case ACCOUNT_DELETED:
            localStorage.removeItem('jwt_info');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false
            }
        default:
            return state;
    }
}

export default authReducer;
