import {API_URL} from './../config';
import toastr from 'toastr';
import "toastr/build/toastr.css";

import { 
    ADD_LEAVE, 
    DELETE_LEAVE, 
    GET_LEAVE, 
    GET_LEAVES, 
    LEAVE_ERROR, 
    UPDATE_LEAVE } 
from './types';


export const getLeaves = (params) => dispatch => {

    fetch(`${API_URL}/leaves`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_LEAVES,
            payload: res.leaves
        })
    })
    .catch(err => {
        console.error(err)
        dispatch({type: LEAVE_ERROR})
    })

}

export const getLeave = id => dispatch => {

    fetch(`${API_URL}/leaves/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_LEAVE,
            payload: res.leave
        })
    })
    .catch(err => console.error(err))

}

export const addLeave = (leave, user_id, token, history) => dispatch => {

    fetch(`${API_URL}/leaves/create/${user_id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(leave)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: ADD_LEAVE,
                payload: res.leave
            })

            dispatch(getLeaves({limit:8}))

            toastr.success(`Leave request created !`, 'New leave' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/leaves');

        }
    })
    .catch(err => console.error(err))
}

export const removeLeave = (id, user, token) => dispatch => {

    fetch(`${API_URL}/leaves/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: DELETE_LEAVE,
                payload: id
            })

            toastr.success(`leave deleted !`, 'leave deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

        }
    })
    .catch(err => console.error(err))

}

export const updateLeave = (leave, user_id, token, history) => dispatch => {

    fetch(`${API_URL}/leaves/${leave._id}/${user_id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(leave)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_LEAVE,
                payload: res.leave
            })

            toastr.success(`Leave updated !`, 'Leave updated successfully',
            {"positionClass": "toast-bottom-left",})

            history.push('/leaves');
        }
    })
    .catch(err => console.error(err))
}

export const updateLeaveStatus = (user, token, leave, status) => dispatch => {

    fetch(`${API_URL}/leaves/${leave._id}/status/${user._id}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({status})
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_LEAVE,
                payload: res.leave
            })

            toastr.success(`Request leave updated !`, 'leave updated' ,
            {"positionClass": "toast-bottom-left",})
        }
    })
    .catch(err => console.error(err))
}


