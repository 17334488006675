let initialState = {
    payments: [],
    payment: {}
}


const paymentReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_PAYMENTS':
            return {
                ...state,
                payments: action.payload
            }
        
        case 'GET_PAYMENT':
            return {
                ...state,
                payment: action.payload
            }
        
        case 'ADD_PAYMENT':
            return {
                ...state,
                payments: [action.payload, ...state.payments]
            }
    
        default:
            return state;
    }
}

export default paymentReducer
