import toastr from 'toastr';
import "toastr/build/toastr.css";
import api from '../utils/api';
import axios from 'axios';
import { API_URL } from '../config';


import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADED_FAILED
} from './types';

// Load User
export const loadUser = (token, history) => async dispatch => {
   try {
    const res = await axios.get(`${API_URL}/profile`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` 
        }
    });

    dispatch({
      type: USER_LOADED,
      payload: res.data.user
    });
    

  } catch (err) {
      dispatch({
        type: USER_LOADED_FAILED
      });  

    toastr.warning(err, 'Server Error', 
    {"positionClass": "toast-bottom-left",})

    history.push('/signin');
  } 
  
};

export const updateProfile = (formData, user, token, profile_id, history) => dispatch => {

  fetch(`${API_URL}/users/${profile_id}/${user._id}`, {
      method: "PUT",
      headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`
      },
      body: formData
  })
  .then(res => res.json())
  .then(res => {
      
      if(res.error){
          toastr.warning(res.error, 'Please check form !', 
          {"positionClass": "toast-bottom-left",})
      }else{
          
          dispatch({
              type: 'UPDATE_EMPLOYEE',
              payload: res.user
          })

          toastr.success(`employee ${res.user.firstname} UPDATED !`, 'employee' ,
          {"positionClass": "toast-bottom-left",})

          history.push('/profile');
      }
  })
  .catch(err => console.error(err))
}


// Login User
export const login = (email, password, history) => async dispatch => {
   const body = JSON.stringify({email, password});

  try {
    const res = await api.post('/login', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    localStorage.setItem('jwt_info', JSON.stringify(res.data))

    history.push('/dashboard');
    //dispatch(loadUser(res.data.token));
  } catch (err) {
    /* const errors = err.response.data.errors; */

    dispatch({
      type: LOGIN_FAIL
    });
    
    toastr.warning(err, '', 
    {"positionClass": "toast-bottom-left",})
  }
};


// Logout
export const logout = () => async dispatch => {

    try {
        await api.get('/signout');
    
        dispatch({ type: LOGOUT });
    
      } catch (err) {
        const errors = err.response.data.errors;
    
        toastr.warning(errors[0].msg, 'Server Error', 
        {"positionClass": "toast-bottom-left",})
      }
    
} 
