export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const USER_LOADED_FAILED = 'USER_LOADED_FAILED';

export const ADD_DOCTOR = 'ADD_DOCTOR';
export const DOCTOR_ERROR = 'DOCTOR_ERROR';
export const GET_DOCTORS = 'GET_DOCTORS';
export const GET_DOCTOR = 'GET_DOCTOR';
export const DELETE_DOCTOR = 'DELETE_DOCTOR';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';
export const CLEAR_DOCTOR = 'CLEAR_DOCTOR';

export const ADD_PATIENT = 'ADD_PATIENT';
export const PATIENT_ERROR = 'PATIENT_ERROR';
export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENT = 'GET_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const CLEAR_PATIENT = 'CLEAR_PATIENT';

export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const APPOINTMENT_ERROR = "APPOINTMENT_ERROR";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const CLEAR_APPOINTMENT = "CLEAR_APPOINTMENT";

export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const DEPARTMENT_ERROR = "DEPARTMENT_ERROR";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const CLEAR_DEPARTMENT = "CLEAR_DEPARTMENT";

export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const EMPLOYEE_ERROR = "EMPLOYEE_ERROR";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";

export const ADD_LEAVE = "ADD_LEAVE";
export const LEAVE_ERROR = "LEAVE_ERROR";
export const GET_LEAVES = "GET_LEAVES";
export const GET_LEAVE = "GET_LEAVE";
export const DELETE_LEAVE = "DELETE_LEAVE";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const CLEAR_LEAVE = "CLEAR_LEAVE";

export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const HOLIDAY_ERROR = "HOLIDAY_ERROR";
export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const GET_HOLIDAY = "GET_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const CLEAR_HOLIDAY = "CLEAR_HOLIDAY";

export const ADD_INVOICE = "ADD_INVOICE";
export const INVOICE_ERROR = "INVOICE_ERROR";
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICE = "GET_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const CLEAR_INVOICE = "CLEAR_INVOICE";

export const ADD_EXPENSE = "ADD_EXPENSE";
export const EXPENSE_ERROR = "EXPENSE_ERROR";
export const GET_EXPENSES = "GET_EXPENSES";
export const GET_EXPENSE = "GET_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const CLEAR_EXPENSE = "CLEAR_EXPENSE";

export const ADD_ROLE = "ADD_ROLE";
export const ROLE_ERROR = "ROLE_ERROR";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_CONNECTED_USER = "GET_ROLE_CONNECTED_USER";
export const DELETE_ROLE = "DELETE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const CLEAR_ROLE = "CLEAR_ROLE";

export const ADD_PRESCRIPTION = "ADD_PRESCRIPTION";
export const PRESCRIPTION_ERROR = "PRESCRIPTION_ERROR";
export const GET_PRESCRIPTIONS = "GET_PRESCRIPTIONS";
export const GET_PRESCRIPTION = "GET_PRESCRIPTION";
export const DELETE_PRESCRIPTION = "DELETE_PRESCRIPTION";
export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const CLEAR_PRESCRIPTION = "CLEAR_PRESCRIPTION";

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";
export const GET_SETTINGS = "GET_SETTINGS";







