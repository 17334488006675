import { 
    ADD_LEAVE, 
    DELETE_LEAVE, 
    GET_LEAVE, 
    GET_LEAVES, 
    LEAVE_ERROR, 
    UPDATE_LEAVE 
} from "../actions/types";


let myState = {
    leaves: [],
    leave: null,
    loading: true,
    error: false
}

const leaveReducer = (state = myState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {

        case GET_LEAVES:
            return {
                ...state,
                leaves: payload,
                loading: false,
                error: false
            }
        case GET_LEAVE:
            return {
                ...state,
                leave: payload,
                loading: false,
                error: false
            }
        case DELETE_LEAVE:
            return {
                ...state,
                leaves: state.leaves.filter(leave => leave._id !== payload),
                loading: false,
                error: false
            }
        case ADD_LEAVE:
            return {
                ...state,
                leaves: [...state.leaves, payload],
                loading: false,
                error: false
            }
        case UPDATE_LEAVE:
            return {
                ...state,
                leaves: state.leaves.map(leave => leave._id === payload._id ? (leave = payload) : leave),
                loading: false,
                error: false
            }
        case LEAVE_ERROR:
            return {
                loading: false,
                error: true
            }
        default:{
            return state
        }
    }
}

export default leaveReducer;
