import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Layout from '../../core/Layout';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

import { getDepartments, removeDepartment } from '../../actions/departmentActions';
import { isAuthenticated } from '../../auth/helpers';

import Spinner from '../../core/Spinner';
import Page500 from '../../core/Page500';

const ListDepartments = () => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [deletedDepartmentId, setDeletedDepartmentId] = useState('');
    const departments = useSelector(state => state.department.departments);
    const loading = useSelector(state => state.department.loading);
    const error = useSelector(state => state.department.error);

    useEffect(() => dispatch(getDepartments()), []);


    // delete  ----------------------------------------------
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedDepartmentId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {

        const { user, token } = isAuthenticated();

        dispatch(removeDepartment(user._id, token, deletedDepartmentId));

        setShow(false);
    }

    const deleteDepartmentModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Department ?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }
    // end delete ----------------------------------------------

    if (error == true) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                        <div className="row">
                            <div className="col-sm-5 col-5">
                                <h4 className="page-title">Departments</h4>
                            </div>
                            <div className="col-sm-7 col-7 text-right m-b-30">
                                <Link to="/departments/create" className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Add Department</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped custom-table m-b-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Department Name</th>
                                                <th>Status</th>
                                                <th>Color</th>
                                                <th>Date de création</th>
                                                <th>Date de mise à jour</th>
                                                <th className="text-right">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {departments && departments.map((department,i) => (

                                                <tr key={department._id}>
                                                    <td>{i+1}</td>
                                                    <td>{department.name}</td>
                                                    <td><span className={department.status == 'Active' ? 'custom-badge status-green' : 'custom-badge status-red'}>{department.status}</span></td>
                                                    <td>{department.color}</td>
                                                    <td>{moment(department.createdAt).format('DD/MM/YYYY H:m')}</td>
                                                    <td>{moment(department.updatedAt).format('DD/MM/YYYY H:m')}</td>
                                                    <td className="text-right">
                                                        <div className="dropdown dropdown-action">
                                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <Link className="dropdown-item" to={`/departments/${department._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link>
                                                                <a style={{"cursor": "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,department._id)}><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Layout>
                    {deleteDepartmentModal()}
                </Fragment>)
                
            }  
        </Fragment>
    )
}

export default ListDepartments
