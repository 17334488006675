import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  getEmployees } from '../../../actions/employeeActions'
import { isAuthenticated } from '../../../auth/helpers'
import Layout from '../../../core/Layout'
import moment from 'moment';

import { addLeave, updateLeave } from '../../../actions/leaveActions'
import { API_URL } from '../../../config'
import { GET_LEAVE } from '../../../actions/types'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const initialState = {
    user: '',
    type: '',
    fromDate: '',
    toDate: '',
    nbOfDays: 0,
    //remainingLeave: 0,
    reason: '',
    status: 'Pending'
};

const FormLeaveRequest = ({history, match}) => {

    const leaveId = match.params.id;
    const [formData, setFormData] = useState(initialState);
    const dispatch = useDispatch();
    const employees = useSelector(state => state.employee.employees);
    
    useEffect(() => {
        if(!leaveId) {
            dispatch(getEmployees())
        }else{
            getLeave(leaveId);
        }

    }, []);

    const getLeave = (id) => {
        fetch(`${API_URL}/leaves/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {

            dispatch({
                type: GET_LEAVE,
                payload: res.leave
            });

            setFormData(res.leave);
        })
        .catch(err => console.error(err))
    }

    let {
        user,
        type,
        fromDate,
        toDate,
        nbOfDays, 
        reason,
        //remainingLeave,
        status 
    } = formData;


    const handleChange = e => {
        nbOfDays = moment(toDate).diff(fromDate, 'days')
        //remainingLeave = user.remainingLeave

        setFormData({
            ...formData, 
            nbOfDays,
            [e.target.name]: e.target.value}
        )
    }

    const submit = e => {
        e.preventDefault();

        const { user, token } = isAuthenticated()
        
        if(leaveId){
            dispatch(updateLeave(formData, user._id, token, history));
        }else{
            dispatch(addLeave(formData, user._id, token, history));
        }
    }

    
    if(toDate && fromDate && toDate != '' && fromDate != ''){
        nbOfDays = moment(toDate).diff(fromDate, 'days')
    
        if(nbOfDays <= 0){
            nbOfDays = 0
        }
    }

    return (
        <Layout>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <h4 className="page-title">{leaveId ? "Update" : "Add"} Leave</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <form onSubmit={submit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Employee<span className="text-danger">*</span></label>
                                    { <select className="form-control select" name="user" onChange={handleChange} readOnly={leaveId}>
                                        {leaveId ? (
                                            <option value={user._id}>{user.firstname} {user.lastname}</option>
                                        ): (
                                            <Fragment>
                                                <option>Select Employee</option>
                                                {employees && employees.map((employee,i) => (
                                                    <option key={i} value={employee._id}>{employee.firstname} {employee.lastname}</option>
                                                ))}
                                            </Fragment>
                                        )}
                                    </select>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Leave Type <span className="text-danger">*</span></label>
                                    <select className="form-control select" name="type" onChange={handleChange} value={type}>
                                        <option value="">Select Leave Type</option>
                                        <option>Casual Leave</option>
                                        <option>Medical Leave</option>
                                        <option>Loss of Pay</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>From <span className="text-danger">*</span></label>
                                    <input className="form-control" type="date" name="fromDate" onChange={handleChange} value={moment(fromDate).format('YYYY-MM-DD')}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>To <span className="text-danger">*</span></label>
                                    <input className="form-control" type="date" name="toDate" onChange={handleChange} value={moment(toDate).format('YYYY-MM-DD')}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Number of days <span className="text-danger">*</span></label>
                                    <input className="form-control" readOnly type="text" name="nbOfDays" onChange={handleChange} value={nbOfDays}/>
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Remaining Leaves <span className="text-danger">*</span></label>
                                    <input className="form-control" readOnly type="text" name="remainingLeave" onChange={handleChange} value={remainingLeave}/>
                                </div>
                            </div> */}
                        </div>
                        <div className="form-group">
                            <label className="display-block">Status</label>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name="status" 
                                    value="Approved" 
                                    checked={status === 'Approved'}
                                    onChange={handleChange}
                                    />
                                <label className="form-check-label" htmlFor="leave_approved">
                                Approved
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name="status" 
                                    value="Pending" 
                                    checked={status === 'Pending'}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="leave_pending">
                                Pending
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name="status" 
                                    value="Declined" 
                                    checked={status === 'Declined'}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="leave_declined">
                                Declined
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Leave Reason <span className="text-danger">*</span></label>
                            <textarea rows="4" cols="5" className="form-control" name="reason" onChange={handleChange} value={reason}></textarea>
                        </div>
                        <div className="m-t-20 text-center">
                            <Link className="mr-2" to="/leaves">
                                <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                            </Link>
                            {leaveId ? (
                                <button type="submit" className="btn btn-warning submit-btn" >Update Leave Request</button>
                            ) : (
                                <button type="submit" className="btn btn-primary submit-btn" >Send Leave Request</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default FormLeaveRequest;
