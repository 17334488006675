import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";

import { 
    ADD_PRESCRIPTION,
    PRESCRIPTION_ERROR,
    GET_PRESCRIPTIONS,
    GET_PRESCRIPTION,
    DELETE_PRESCRIPTION,
    UPDATE_PRESCRIPTION,
    CLEAR_PRESCRIPTION
} from './types';
import { addAppointmentPrescription, updateAppointmentStatus } from './appointmentActions';


export const getPrescriptions = () => dispatch => {

    dispatch({type: CLEAR_PRESCRIPTION});
    
    fetch(`${API_URL}/prescriptions`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_PRESCRIPTIONS,
            payload: res.prescriptions
        })
    })
    .catch(err => dispatch({type: PRESCRIPTION_ERROR}));

}

export const getPrescription = id => dispatch => {

    fetch(`${API_URL}/prescriptions/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_PRESCRIPTION,
            payload: res.prescription
        })
    })
    .catch(err => dispatch({type: PRESCRIPTION_ERROR}));

}

export const addPrescription = (prescription, admin_id, token, history) => dispatch => {

    fetch(`${API_URL}/prescriptions/create/${admin_id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(prescription)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: ADD_PRESCRIPTION,
                payload: res.prescription
            })

            dispatch(addAppointmentPrescription(admin_id, token, prescription.appointment, res.prescription._id));
            
            toastr.success(`prescription created !`, 'New prescription' ,
            {"positionClass": "toast-bottom-left",})


            history.push('/ordonnances');

        }
    })
    .catch(err => dispatch({type: PRESCRIPTION_ERROR}));
}

export const removePrescription = (prescription_id, admin_id, token) => dispatch => {

    fetch(`${API_URL}/prescriptions/${prescription_id}/${admin_id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: DELETE_PRESCRIPTION,
                payload: prescription_id
            })

            toastr.success(`Prescription deleted !`, 'Prescription deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

        }
    })
    .catch(err => dispatch({type: PRESCRIPTION_ERROR}));

}

export const updatePrescription = (prescription, user_id, token, prescription_id, history) => dispatch => {

    fetch(`${API_URL}/prescriptions/${prescription_id}/${user_id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(prescription)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_PRESCRIPTION,
                payload: res.prescription
            })

            toastr.success(`Prescription updated !`, 'Prescription updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/ordonnances');
        }
    })
    .catch(err => dispatch({type: PRESCRIPTION_ERROR}));
}


