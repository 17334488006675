import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Layout from '../../core/Layout'

import { addDoctor, updateDoctor } from '../../actions/doctorActions';
import { getRoleByName } from '../../actions/roleActions';

import { isAuthenticated } from '../../auth/helpers';

import { API_URL } from '../../config'

import moment from 'moment'
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../../core/Spinner';
import { DOCTOR_ERROR, GET_DOCTOR } from '../../actions/types'
import Page500 from '../../core/Page500';


const initialState = {
    firstname: '',
    lastname: '',
    birthday: '',
    gender: '',
    email: '',
    password:'',
    confirmPassword:'',
    address: '',
    country: 'Morocco',
    city: '',
    postalcode: '',
    phone: '',
    photo: '',
    role: '',
    speciality: '',
    status: '',
    joiningDate: '',
    remainingLeave: '',
    cin: ''
}


const FormDoctor = ({ 
    doctor: { doctor, loading, error }, 
    history, 
    match,
    addDoctor,
    updateDoctor
}) => {

    const id = match.params.id;
    const dispatch = useDispatch();

    const role = useSelector(state => state.role.role);
    const [upDoctor, setUpDoctor] = useState(initialState);
    const [formData, setFormData] = useState(new FormData());

    useEffect(() => {
        dispatch(getRoleByName({search: { name: 'Doctor' }}));
        if (id) getDoctor(id);

    }, []); 

    const getDoctor = async id => {

        try {
            const res = await axios(`${API_URL}/users/${id}`);
            const doctor = res.data.user;

            if(!loading && doctor) {
                const doctorData = { ...initialState };
                for(const key in doctor){
                    if(key in doctorData && key !== 'photo') {
                        doctorData[key] = doctor[key];
                        formData.set(key, doctor[key])
                    }
                }
                setUpDoctor(doctorData);
            }
            
            dispatch({
                type: GET_DOCTOR,
                payload: doctor
            });
            
        }catch (err) {
            dispatch({type: DOCTOR_ERROR});
        }
    }
    
    
    const {
        firstname, 
        lastname, 
        birthday, 
        email, 
        gender,
        password,
        confirmPassword,
        address, 
        country, 
        city, 
        postalcode, 
        phone, 
        speciality, 
        joiningDate,
        cin, 
        status,
        remainingLeave
    } = upDoctor;    


    const handleChange = e => {

        let key = e.target.name;
        let value = "";

        if(e.target.type == 'file' && e.target.name == 'photo'){
            value = e.target.files[0]  
        }else{
            value = e.target.value
        } 
        
        formData.set(key, value)

        setUpDoctor({...upDoctor, [key]: value})

    }

    const submitDoctor = e => {

        e.preventDefault();
        
        const { user, token } = isAuthenticated();
        formData.set("role", role._id)

        if(id) {
            updateDoctor(formData, user._id, token, id, history);
        }
        else{
            addDoctor(formData, user, token, history)
        } 
        
    }

    if (error == true) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {!doctor && loading && id ? ( <Spinner/>) : (
                <Fragment>
                    <Layout>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h4 className="page-title">
                                    {id ? 'Update Doctor' : 'Add Doctor'}
                                    </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <form onSubmit={submitDoctor}>
                                    <div className="row">
                                        <div className="col-md-3 col-xs-6">
                                            <div className="form-group">
                                                <label htmlFor="firstname">Prénom <span className="text-danger">*</span></label>
                                                <input name="firstname" onChange={handleChange} className="form-control" type="text" value={firstname}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <div className="form-group">
                                                <label htmlFor="lastname">Nom <span className="text-danger">*</span></label>
                                                <input name="lastname" onChange={handleChange} className="form-control" type="text" value={lastname}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <div className="form-group">
                                                <label htmlFor="birthday">Date de naissance <span className="text-danger">*</span></label>
                                                <input name="birthday" onChange={handleChange} type="date" className="form-control"
                                                        value={moment(birthday).format('YYYY-MM-DD') }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <div className="form-group gender-select">
                                                <label className="gen-label">Sexe <span className="text-danger">*</span></label>
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input onChange={handleChange} type="radio" name="gender" className="form-check-input" value="Male" checked={gender == "Male"} />Male
                                                    </label>
                                                </div>
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input onChange={handleChange} type="radio" name="gender" className="form-check-input" value="Female" checked={gender == "Female"}/>Female
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="cin" title="Numére de carte nationale">CIN <span className="text-danger">*</span></label>
                                                <input name="cin" onChange={handleChange} className="form-control" type="text" value={cin}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="phone">Téléphone <span className="text-danger">*</span></label>
                                                <input name="phone" onChange={handleChange} className="form-control" type="text" value={phone}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>Adresse email <span className="text-danger">*</span></label>
                                                <input name="email" onChange={handleChange} className="form-control" type="email" value={email} readOnly={id ? true : false}/>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Avatar</label>
                                                <div className="profile-upload">
                                                    <div className="upload-img">
                                                        <img alt="" src={id ? `${API_URL}/users/photo/${id}` : './../assets/img/user.jpg'}/>
                                                    </div>
                                                    <div className="upload-input">
                                                        <input name="photo" onChange={handleChange} type="file" className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    {!id ? (
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="password">Mot de passe <span className="text-danger">*</span></label>
                                                    <input name="password" value={password} onChange={handleChange} className="form-control" type="password"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="confirmPassword">Confirmer le mot de passe <span className="text-danger">*</span></label>
                                                    <input name="confirmPassword" value={confirmPassword} onChange={handleChange} className="form-control" type="password"/>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    
        
                                    <hr/>
        
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="role">Rôle <span className="text-danger">*</span></label>
                                                <input name="role" onChange={handleChange} className="form-control" value={role ? role.name : ''} readOnly />
                                            </div>
                                        </div>
        
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="speciality">Spécialité <span className="text-danger">*</span></label>
                                                <select name="speciality" onChange={handleChange} className="form-control select" value={speciality}>
                                                        <option value="">Sélectionnez une spécialité</option>
                                                        <option>Cardiologue</option>
                                                        <option>Dentiste</option>
                                                        <option>Gynécologue</option>
                                                        <option>Ophtalmologiste</option>
                                                        <option>Psychiatre</option>
                                                        <option>Radiologue</option>
                                                        <option>Urologue</option>
                                                        <option>Autre</option>
                                                </select>
                                            </div>
                                        </div>
        
                                        <div className="col-md-3 col-xs-6">
                                            <div className="form-group">
                                                <label htmlFor="joiningDate">Joining Date <span className="text-danger">*</span></label>
                                                <input name="joiningDate" onChange={handleChange} type="date" className="form-control" value={moment(joiningDate).format('YYYY-MM-DD')}/>
                                            </div>
                                        </div>
                                    </div>
        
                                    <hr/>
        
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="address">Address <span className="text-danger">*</span></label>
                                                <textarea name="address" onChange={handleChange} type="text" className="form-control" value={address}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="country">Country <span className="text-danger">*</span></label>
                                                <select name="country" onChange={handleChange}  className="form-control" value={country}>
                                                    <option>Select the country</option>
                                                    <option value="Morocco">Morocco</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="city">City <span className="text-danger">*</span></label>
                                                <select name="city" onChange={handleChange} className="form-control" value={city}>
                                                    <option value="">Select the city</option>
                                                    <option value="Agadir">Agadir</option>
                                                    <option value="Casablanca">Casablanca</option>
                                                    <option value="Marrakech">Marrakech</option>
                                                    <option value="Rabat">Rabat</option>
                                                    <option value="Tanger">Tanger</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="postalcode">Postal Code <span className="text-danger">*</span></label>
                                                <input name="postalcode" onChange={handleChange} type="text" className="form-control" value={postalcode}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="remainingLeave">Remaining Leave  <span className="text-danger">*</span></label>
                                                <input name="remainingLeave" onChange={handleChange} type="text" className="form-control" value={remainingLeave}/>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <hr/>
                                        
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="display-block">Statut <span className="text-danger">*</span></label>
                                                <div className="form-check form-check-inline">
                                                    <input name="doctor_active" onChange={handleChange} className="form-check-input" type="radio" name="status" value="Actif" checked={status=="Actif"}/>
                                                    <label className="form-check-label" htmlFor="doctor_active">
                                                    Actif
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input name="doctor_inactive" onChange={handleChange} className="form-check-input" type="radio" name="status" value="Inactif" checked={status=="Inactif"}/>
                                                    <label className="form-check-label" htmlFor="doctor_inactive">
                                                    Inactif
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="m-t-20 text-center">
                                        <Link className="mr-2" to="/doctors">
                                            <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Retourner</button>
                                        </Link>
                                        {id ? (
                                            <button type="submit" className="btn btn-warning submit-btn">Enregistrer</button>
                                            
                                            ): (
                                            <button type="submit" className="btn btn-primary submit-btn">Ajouter un médecin</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Layout>
                </Fragment>
            )}
        </Fragment>
        
    )
}

FormDoctor.propTypes = {
    addDoctor: PropTypes.func.isRequired,
    updateDoctor: PropTypes.func.isRequired,
    doctor: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    doctor: state.doctor
})

export default connect(mapStateToProps, { addDoctor, updateDoctor })(FormDoctor);
