import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LineItem from './LineItem';
import { Fragment } from 'react';


class LineItems extends Component {
  
  render = () => {

    const {items, addHandler, reorderHandler, ...functions} = this.props

    return (
        <div className="row">
            <div className="col-md-12 col-sm-12">
                <div className="table-responsive">
                    <table className="table table-hover table-white">
                        <thead>
                            <tr>
                                <th style={{ width: "4%"}}>#</th>
                                <th style={{ width: "20%"}}>Item</th>
                                <th style={{ width: "35%"}}>Description</th>
                                <th style={{ width: "5%"}}> </th>
                            </tr>
                        </thead>
                            <tbody>
                            <Fragment>
                                {this.props.items.map((item, i) => (
                                    <LineItem
                                        style={{color: 'red'}}
                                        key={i + item.id} index={i} name={item.name}
                                        description={item.description} quantity={item.quantity} price={item.price}
                                        {...functions}
                                    />
                                ))}
                            </Fragment>
                        </tbody>             
                    </table>
                </div>
                <div>
                    <button style={{ float: 'right' }} type="button" className="btn btn-primary" onClick={addHandler}>
                        <i className="fa fa-plus"/> Add item
                    </button>
                </div>
            </div>                              
        </div>
    )
  }
}

export default LineItems

LineItems.propTypes = {
  items: PropTypes.array.isRequired,
  currencyFormatter: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
}


