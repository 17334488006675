import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isAuthenticated } from '../auth/helpers';
import { getRoleByNameConnectedUser } from '../actions/roleActions';


const isActive = (history, path) => {

    if(history.location.pathname.substring(0, path.length) === path){
      return "active"
        
    }else{
        return ""   
    }

}

const Sidebar = ({history}) => {

    const { user } = isAuthenticated();
    const dispatch = useDispatch();
    const roleConnectedUser = useSelector(state => state.role.roleConnectedUser);

    useEffect(() => dispatch(getRoleByNameConnectedUser({search: { name: user.role }})), []); 

    return (
        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                    
                    <ul>
                        <li className="menu-title">ACCUEIL</li>
                        
                        <li className={isActive(history, '/dashboard')}>
                            <Link to="/dashboard"><i className="fa fa-dashboard"></i> Tableau de bord</Link>
                        </li>
                        {roleConnectedUser && roleConnectedUser.hasAccessModuleDoctors && (
                            <li className={isActive(history, '/doctors', '/doctors/create')}>
                                <Link to="/doctors"><i className="fa fa-user-md"></i> Médecins</Link>
                            </li>
                        )}

                        {roleConnectedUser && roleConnectedUser.hasAccessModulePatients && (
                            <li className={isActive(history, '/patients', '/patients/create')}>
                                <Link to="/patients"><i className="fa fa-wheelchair"></i> Patients</Link>
                            </li>
                        )}
                        {roleConnectedUser && roleConnectedUser.hasAccessModuleAppointments && (
                            <li className={isActive(history, '/appointments')}>
                                <Link to="/appointments"><i className="fa fa-calendar"></i> Rendez-vous</Link>
                            </li>
                        )}
                        {roleConnectedUser && roleConnectedUser.hasAccessModuleDepartments && (
                            <li className={isActive(history, '/departments')}>
                                <Link to="/departments"><i className="fa fa-hospital-o"></i> Départements</Link>
                            </li>
                        )}
                        <li className="submenu">
							<a href="#!"><i className="fa fa-user"></i> <span> Employés </span> <span className="menu-arrow"></span></a>
							<ul /* style={{ display: "none"  }} */>
								{roleConnectedUser && roleConnectedUser.hasAccessModuleEmployees && (
                                    <li className={isActive(history, '/employees')}>
                                        <Link to="/employees">Liste d'employés</Link>
                                    </li>
                                )}
								{roleConnectedUser && roleConnectedUser.hasAccessModuleLeaves && (
                                    <li className={isActive(history, '/leaves')}>
                                        <Link to="/leaves">Congés</Link>
                                    </li>
                                )}
								{roleConnectedUser && roleConnectedUser.hasAccessModuleHolidays && (
                                    <li className={isActive(history, '/holidays')}>
                                        <Link to="/holidays">Vacances</Link>
                                    </li>
                                )}
								{/* <li><a href="attendance.html">Attendance</a></li> */}
							</ul>
						</li>
                        <li className="submenu">
                        <a href="#!"><i className="fa fa-money"></i> <span> Comptes </span> <span className="menu-arrow"></span></a>
							<ul /* style={{ display: "none"  }} */>
								{roleConnectedUser && roleConnectedUser.hasAccessModuleInvoices && (
                                    <li className={isActive(history, '/invoices')}>
                                        <Link to="/invoices">Factures</Link>
                                    </li>
                                )}
                                {/* {roleConnectedUser && roleConnectedUser.hasAccessModuleInvoices && ( */}
                                    <li className={isActive(history, '/ordonnances')}>
                                        <Link to="/ordonnances">Ordonannces</Link>
                                    </li>
                                {/* )} */}
                                {roleConnectedUser && roleConnectedUser.hasAccessModuleTaxs && (
                                    <li className={isActive(history, '/taxs')}>
                                        <Link to="/taxs">Impôts</Link>
                                    </li>
                                )}
                                {roleConnectedUser && roleConnectedUser.hasAccessModuleExpenses && (
                                    <li className={isActive(history, '/expenses')}>
                                        <Link to="/expenses">Dépenses</Link>
                                    </li>
                                )}
                                {/* <li className={isActive(history, '/provident_fund')}>
                                    <Link to="/providentFunds">Provident Fund</Link>
                                </li> */}
							</ul>
						</li>
                       
                            <li className="submenu">
                                <a href="#"><i className="fa fa-book"></i> <span> Paie </span> <span className="menu-arrow"></span></a>
                                <ul /* style={{ display: "none"  }} */>
                                    {roleConnectedUser && roleConnectedUser.hasAccessModuleEmployeesSalaries && (
                                        <li className={isActive(history, '/employees-salaries')}>
                                            <Link to="/employees-salaries">Salaires des employés</Link>
                                        </li>
                                    )}
                                    <li className={isActive(history, '/payslip/example')}>
                                        <Link to="/payslip/example">Fiche de paie</Link>
                                    </li>
                                </ul>
                            </li>
                        
                        <li className="submenu">
                            <Link to="/settings/localization"><i className="fa fa-cog"></i> Réglages</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Sidebar)
