import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrivateRoute from './auth/privateRoute';

import Signin from './user/Signin'
import Dashboard from './core/Dashboard'
import AdminRoute from './auth/adminRoute';

import FormDepartment from './admin/departments/FormDepartment';
import ListDepartments from './admin/departments/ListDepartments';

import ListDoctors from './admin/doctors/ListDoctors';
import AddDoctor from './admin/doctors/FormDoctor';
import UpdateDoctor from './admin/doctors/FormDoctor';
import ProfileDoctor from './admin/doctors/ProfileDoctor';

import ListPatients from './admin/patients/ListPatients';
import FormPatient from './admin/patients/FormPatient';

import ListAppointments from './admin/appointments/ListAppointments'
import FormAppointment from './admin/appointments/FormAppointment';
import CalendarAppointment from './admin/appointments/CalendarAppointment';

import ListEmployees from './admin/employees/employees/ListEmployees';
import FormEmployee from './admin/employees/employees/FormEmployee';

import ListLeaveRequests from './admin/employees/leaves/ListLeaveRequests';
import FormLeaveRequest from './admin/employees/leaves/FormLeaveRequest';

import ListHolidays from './admin/employees/holidays/ListHolidays';
import FormHoliday from './admin/employees/holidays/FormHoliday';

import ListInvoices from './admin/accounts/invoices/ListInvoices';
import FormInvoice from './admin/accounts/invoices/FormInvoice';
import ViewInvoice from './admin/accounts/invoices/ViewInvoice';

import ListTaxs from './admin/accounts/taxs/ListTaxs';
import AddTax from './admin/accounts/taxs/AddTax';
import UpdateTax from './admin/accounts/taxs/UpdateTax';

import ListExpenses from './admin/accounts/expenses/ListExpenses';
import FormExpense from './admin/accounts/expenses/FormExpense';

import ListProvidentFunds from './admin/accounts/providentFunds/ListProvidentFunds';
import AddProvidentFund from './admin/accounts/providentFunds/AddProvidentFund';
import UpdateProvidentFund from './admin/accounts/providentFunds/UpdateProvidentFund';

import CompanySettings from './admin/settings/CompanySettings';
import Localization from './admin/settings/Localization';
import ThemeSettings from './admin/settings/ThemeSettings';
import RolesPermissions from './admin/settings/rolesAndPermissions/RolesPermissions';

import SalarySettings from './admin/settings/SalarySettings';
import EmployeeSalary from './admin/payroll/EmployeeSalary';
import AddEmployeeSalary from './admin/payroll/AddEmployeeSalary';

import Payslip from './admin/payroll/Payslip';
import PayslipExample from './admin/payroll/PayslipExample';

import Page500 from './core/Page500';
import NotFound from './core/NotFound';
import Home from './core/Home';
import Profile from './user/Profile';
import EditProfile from './user/EditProfile';
import FormRole from './admin/settings/rolesAndPermissions/FormRole';

import ListPrescriptions from './admin/prescription/ListPrescriptions';
import ViewPrescription from './admin/prescription/ViewPrescription';
import FormPrescription from './admin/prescription/FormPrescription';


const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>

                <Route path='/signin' exact component={Signin} />
                <Route path='/profile' exact component={Profile} />
                <Route path='/profile/edit' exact component={EditProfile} />

                <PrivateRoute path='/dashboard' exact component={Dashboard} />
                <PrivateRoute path='/' exact component={Home} />
                
                <AdminRoute path='/departments/create' exact component={FormDepartment} />
                <AdminRoute path='/departments/:id/update' exact component={FormDepartment} />
                <AdminRoute path='/departments' exact component={ListDepartments} />

                {/* Doctors */}
                <AdminRoute path='/doctors' exact component={ListDoctors} />
                <AdminRoute path='/doctors/create' exact component={AddDoctor} />
                <AdminRoute path='/doctors/:id/update' exact component={UpdateDoctor} />
                <AdminRoute path='/doctors/:id/profile' exact component={ProfileDoctor} />

                {/* Patients */}
                <AdminRoute path='/patients' exact component={ListPatients} />
                <AdminRoute path='/patients/create' exact component={FormPatient} />
                <AdminRoute path='/patients/:id/update' exact component={FormPatient} />

                {/* Appointments */}
                <Route path='/appointments' exact component={ListAppointments} />
                <Route path='/appointments/calendar' exact component={CalendarAppointment} /> 
                <AdminRoute path='/appointments/create' exact component={FormAppointment} />
                <AdminRoute path='/appointments/:id/update' exact component={FormAppointment} />
                <AdminRoute path='/appointments/:appointmentId/invoices/create' exact component={FormInvoice} />
                <AdminRoute path='/appointments/:appointmentId/prescriptions/create' exact component={FormPrescription} />

                {/* Employees */}
                <AdminRoute path='/employees' exact component={ListEmployees} />
                <AdminRoute path='/employees/create' exact component={FormEmployee} />
                <AdminRoute path='/employees/:id/update' exact component={FormEmployee} />

                {/* Leave Requests */}
                <Route path='/leaves' exact component={ListLeaveRequests} />
                <Route path='/leaves/create' exact component={FormLeaveRequest} />
                <Route path='/leaves/:id/update' exact component={FormLeaveRequest} />
                
                {/* Holidays */}
                <Route path='/holidays' exact component={ListHolidays} />
                <AdminRoute path='/holidays/add' exact component={FormHoliday} />
                <AdminRoute path='/holidays/:id/update' exact component={FormHoliday} />

                {/* Invoices */}
                <AdminRoute path='/invoices' exact component={ListInvoices} />
                <AdminRoute path='/invoices/:id/view' exact component={ViewInvoice} />
                <AdminRoute path='/appointments/:appointmentId/invoices/:id/update' exact component={FormInvoice} />

                {/* Taxes */}
                <AdminRoute path='/taxs' exact component={ListTaxs} />
                <AdminRoute path='/taxs/create' exact component={AddTax} />
                <AdminRoute path='/taxs/:id/update' exact component={UpdateTax} />

                {/* Expenses */}
                <AdminRoute path='/expenses' exact component={ListExpenses} />
                <AdminRoute path='/expenses/create' exact component={FormExpense} />
                <AdminRoute path='/expenses/:id/update' exact component={FormExpense} />

                {/* ProvidentFund */}
                <AdminRoute path='/providentFunds' exact component={ListProvidentFunds} />
                <AdminRoute path='/providentFunds/create' exact component={AddProvidentFund} />
                <AdminRoute path='/providentFunds/:id/update' exact component={UpdateProvidentFund} />

                {/* Settings */}
                <AdminRoute path='/settings/company' exact component={CompanySettings} />
                <AdminRoute path='/settings/localization' exact component={Localization} />
                <AdminRoute path='/settings/theme' exact component={ThemeSettings} />
                <AdminRoute path='/settings/salary' exact component={SalarySettings} />

                {/* Payroll */}
                <AdminRoute path='/employees-salaries' exact component={EmployeeSalary} />
                <AdminRoute path='/employees-salaries/add' exact component={AddEmployeeSalary} />
                <AdminRoute path='/employees-salaries/:id/payslip' exact component={Payslip} />
                <AdminRoute path='/payslip/example' exact component={PayslipExample} />
                
                {/* Prescreption */}
                <AdminRoute path='/ordonnances' exact component={ListPrescriptions} />
                <AdminRoute path='/ordonnances/:id/view' exact component={ViewPrescription} />
                <AdminRoute path='/appointments/:appointmentId/ordonnance/:id/update' exact component={FormPrescription} />

                <AdminRoute path='/settings/roles-permissions/create' exact component={FormRole} />
                <AdminRoute path='/settings/roles-permissions/:id/update' exact component={FormRole} />
                <AdminRoute path='/settings/roles-permissions' exact component={RolesPermissions} />
                <AdminRoute path='/settings/roles-permissions/:id' exact component={RolesPermissions} />

                
                <Route path="/500" exact component={Page500}/>
                <Route component={NotFound} />

                

            </Switch>

        </BrowserRouter>
    )
}

export default Routes
