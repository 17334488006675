import {
    ADD_EXPENSE,
    EXPENSE_ERROR,
    GET_EXPENSES,
    GET_EXPENSE,
    DELETE_EXPENSE,
    UPDATE_EXPENSE,
    CLEAR_EXPENSE
} from './../actions/types';

let myState = {
    expenses: [],
    expense: null,
    loading: true,
    error: false
}

const expenseReducer = (state = myState, action) => {
    switch (action.type) {

        case GET_EXPENSES:
            return {
                ...state,
                expenses: action.payload,
                loading: false,
                error: false
            }
        case GET_EXPENSE:
            return {
                ...state,
                expense: action.payload,
                loading: false,
                error: false
            }
        case DELETE_EXPENSE:
            return {
                ...state,
                expenses: state.expenses.filter(expense => expense._id !== action.payload),
                loading: false,
                error: false
            }
        case ADD_EXPENSE:
            return {
                ...state,
                expenses: [...state.expenses, action.payload],
                loading: false,
                error: false
            }
        case UPDATE_EXPENSE:
            return {
                ...state,
                expenses: state.expenses.map(expense => expense._id === action.payload._id ? (expense = action.payload) : expense),
                loading: false,
                error: false
            }
        case CLEAR_EXPENSE:
            return {
                ...state,
                expense: null
            }
        case EXPENSE_ERROR:
            return {
                ...state,
                expense: null,
                loading: false,
                error: true
            }
        default:{
            return state
        }
    }
}

export default expenseReducer;
