import moment from 'moment';
import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";
import { APPOINTMENT_ERROR, CLEAR_APPOINTMENT, GET_APPOINTMENTS } from './types';


export const getAppointments = (params) => dispatch => {
    
    dispatch({type: CLEAR_APPOINTMENT})

    fetch(`${API_URL}/appointments`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_APPOINTMENTS,
            payload: res.appointments
        })
    })
    .catch(err =>  dispatch({type: APPOINTMENT_ERROR}))

}

export const getAppointment = id => dispatch => {

    fetch(`${API_URL}/appointments/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_APPOINTMENT',
            payload: res.appointment
        })
    })
    .catch(err =>  dispatch({type: APPOINTMENT_ERROR}))

}

export const createAppointment = (appointment, user_id, token, history) => dispatch => {

    fetch(`${API_URL}/appointments/create/${user_id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(appointment)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'ADD_APPOINTMENT',
                payload: res.appointment
            })

            toastr.success(`Appointment created !`, 'New Appointment' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/appointments');

        }
    })
    .catch(err =>  dispatch({type: APPOINTMENT_ERROR}))
}

export const updateAppointment = (id, formData, user_id, token, history) => dispatch => {
    

        fetch(`${API_URL}/appointments/${id}/${user_id}`, {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(formData)
        })
        .then(res => res.json())
        .then(res => {
            if(res.error){
                toastr.warning(res.error, 'Please check form !', 
                {"positionClass": "toast-bottom-left",})
            }else{
                toastr.success(`Appointment updated !`, 'appointment updated' ,
                {"positionClass": "toast-bottom-left",})

                history.push('/appointments');
            }

            
            
        })
        .catch(err => toastr.error(err, 'Server error !', 
        {"positionClass": "toast-bottom-left",}))
}

export const updateAppointmentStatus = (userid, token, appointmentId, status) => async dispatch => {

    try {
        const res = await fetch(`${API_URL}/appointments/status/${appointmentId}/${userid}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ status })
        });
        const res_1 = await res.json();
        if (res_1.error) {
            toastr.warning(res_1.error, 'Please check form !',
                { "positionClass": "toast-bottom-left", });
        } else {

            dispatch({
                type: 'UPDATE_APPOINTMENT',
                payload: res_1.appointment
            });

            if (status !== "Closed") {
                toastr.success(`Appointment status updated !`, `Appointment ${status}`,
                    { "positionClass": "toast-bottom-left", });
            }

        }
    } catch (err) {
        return dispatch({ type: APPOINTMENT_ERROR });
    }

}

export const addAppointmentInvoice = (userid, token, appointmentId, invoice) => dispatch => {

    return fetch(`${API_URL}/appointments/invoice/${appointmentId}/${userid}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({invoice})
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
        
            toastr.warning(res.error, 'Error while adding invoice!', 
            {"positionClass": "toast-bottom-left",})
        
        }else{

            dispatch({
                type: 'UPDATE_APPOINTMENT',
                payload: res.appointment
            })
        
        }

    })
    .catch(err =>  dispatch({type: APPOINTMENT_ERROR}))

}

export const addAppointmentPrescription = (userid, token, appointmentId, prescription) => dispatch => {

    return fetch(`${API_URL}/appointments/prescription/${appointmentId}/${userid}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({prescription})
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
        
            toastr.warning(res.error, 'Error while adding prescription!', 
            {"positionClass": "toast-bottom-left",})
        
        }else{

            dispatch({
                type: 'UPDATE_APPOINTMENT',
                payload: res.appointment
            })
        
        }

    })
    .catch(err =>  dispatch({type: APPOINTMENT_ERROR}))

}

export const removeAppointment = (id, user, token) => dispatch => {

    fetch(`${API_URL}/appointments/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'DELETE_APPOINTMENT',
                payload: id
            })

            toastr.success(`appointment deleted !`, 'Appointment deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

        }
    })
    .catch(err =>  dispatch({type: APPOINTMENT_ERROR}))

}