import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Layout from './Layout';
import { getDoctors } from '../actions/doctorActions';
import { getPatients } from '../actions/patientActions';
import {  updateAppointmentStatus } from '../actions/appointmentActions';
import { API_URL } from '../config';
import { APPOINTMENT_ERROR, GET_APPOINTMENTS, GET_INVOICES, INVOICE_ERROR } from '../actions/types';
import Spinner from './Spinner';
import SpinnerXS from './SpinnerXS';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../auth/helpers';
import { getInvoices } from '../actions/invoiceActions';
import { getDepartments } from '../actions/departmentActions';


const Dashboard = () => {

	const dispatch = useDispatch();
	const doctors = useSelector(state => state.doctor.doctors);
	const patients = useSelector(state => state.patient.patients);
	const departments = useSelector(state => state.department.departments);
	const invoices = useSelector(state => state.invoice.invoices);
	const [upcomingAppointments, setUpcomingAppointments] = useState([]);
	const [pendingAppointments, setPendingAppointments] = useState([]);
	const [payedInvoices, setPayedInvoices] = useState([]);
	
	const loadingDoctors = useSelector(state => state.doctor.loading);
	const loadingAppointments = useSelector(state => state.appointment.loading);
	const loadingDepartments = useSelector(state => state.department.loading);
    const loadingInvoices = useSelector(state => state.invoice.loading);

	const handleClass = (status) => {
		switch(status){
			case 'Partially Paid':
				return 'status-orange';
			
			case 'Paid':
				return 'status-green';

			case 'Sent':
				return 'status-blue';

			default:
				return 'status-red'
		}
	}


	const pendingAppoints = (params) => {
	
		fetch(`${API_URL}/appointments`, {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(res => {
			setPendingAppointments(res.appointments)
			setUpcomingAppointments(res.appointments)
			dispatch({
				type: GET_APPOINTMENTS,
				payload: res.appointments
			})
		})
		.catch(err =>  dispatch({type: APPOINTMENT_ERROR}))
	
	}

	const payedInvois = (params) => {
	
		fetch(`${API_URL}/invoices`, {
			method: "GET",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(res => {
			setPayedInvoices(res.invoices)
			dispatch({
				type: GET_INVOICES,
				payload: res.invoices
			})
		})
		.catch(err =>  dispatch({type: INVOICE_ERROR}))
	
	}

	useEffect(() => {
		
		dispatch(getDoctors({limit:6, search: { role: 'Doctor' }}));
		dispatch(getPatients());
		dispatch(getInvoices({limit:6}));
		dispatch(getDepartments());
		pendingAppoints({search: { status: 'Pending' }});
		payedInvois({search: { status: 'Paid' }})

	}, [])



	const handleStatus = (e,appointment) => {
		const { user, token } = isAuthenticated();
        dispatch(updateAppointmentStatus(user._id, token, appointment._id, 'Approved'));
    }

    
    return (
        <div>
            <Layout>
                <>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="dash-widget">
								<span className="dash-widget-bg1"><i className="fa fa-stethoscope" aria-hidden="true"></i></span>
								<div className="dash-widget-info text-right">
									<h3>{ doctors && doctors.length ? doctors.length : 0 }</h3>
									<span className="widget-title1">Médecins <i className="fa fa-check" aria-hidden="true"></i></span>
								</div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="dash-widget">
                            <span className="dash-widget-bg2"><i className="fa fa-user-o"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3>{ patients && patients.length > 0 ? patients.length : 0 }</h3>
                                <span className="widget-title2">Patients <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="dash-widget">
                            <span className="dash-widget-bg3"><i className="fa fa-user-md" aria-hidden="true"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3>{ pendingAppointments && pendingAppointments.length > 0 ? pendingAppointments.length : 0 }</h3>
                                <span className="widget-title3">RDV En attente <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div className="dash-widget">
                            <span className="dash-widget-bg4"><i className="fa fa-file" aria-hidden="true"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3>{ payedInvoices && payedInvoices.length > 0 ? payedInvoices.length : 0 }/{invoices ? invoices.length : 0}</h3>
                                <span className="widget-title4">Factures Impayées </span>
                            </div>
                        </div>
                    </div>
                </div>
			
				<div className="row">
					<div className="col-12 col-md-8 col-lg-8 col-xl-8">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title d-inline-block">Prochains rendez-vous</h4> <Link to="/appointments" className="btn btn-primary float-right">Voir tout</Link>
							</div>
							<div className="card-body p-0">
								<div className="table-responsive">
									<table className="table mb-0">
										<thead className="d-none">
											<tr>
												<th>Patient Name</th>
												<th>Doctor Name</th>
												<th>Timing</th>
												<th className="text-right">Status</th>
											</tr>
										</thead>
										<tbody>
											{!loadingAppointments && upcomingAppointments ? upcomingAppointments.map( appointment => (

												<tr>
													<td>
														<a className="avatar" href="profile.html">{appointment ? appointment.patient.firstname[0] : ''} </a>
														<h2><a href="profile.html">{appointment ? appointment.patient.firstname+' '+appointment.patient.lastname : ''} <span>{appointment ? appointment.patient.city: ''} , {appointment ? appointment.patient.country: ''}</span></a></h2>
													</td>                 
													<td>
														<h5 className="time-title p-0">Rendez-vous avec</h5>
														<p>Dr. {appointment ? appointment.doctor.firstname+' '+appointment.doctor.lastname : ''} </p>
													</td>
													<td>
														<h5 className="time-title p-0">Département</h5>
														<p>{appointment && appointment.department ? appointment.department.name : ''} </p>
													</td>
													<td>
														<h5 className="time-title p-0">Horaire</h5>
														<p>{appointment ? appointment.fromTime : ''} </p>
													</td>
													<td className="text-right">
														<span onClick={e => handleStatus(e,appointment)} className="dropdown-item" href="#" className="btn btn-outline-primary">Prendre</span>
													</td>
												</tr>

											)): (
												<SpinnerXS />
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="card member-panel">
							<div className="card-header bg-white">
								<h4 className="card-title m-b-0">Médecins</h4>
							</div>
                            <div className="card-body">
                                <ul className="contact-list">
								{!loadingDoctors && doctors ? doctors.map( doctor => (

									<li>
										<div className="contact-cont">
											<div className="float-left user-img m-r-10">
												<Link to={`/doctors/${doctor._id}/profile`}title="John Doe"><img src={`${API_URL}/users/photo/${doctor._id}`} alt="" className="w-40 rounded-circle"/><span className={`status ${doctor.status && doctor.status === 'Active' ? 'online' : 'offline'}`}></span></Link>
											</div>
											<div className="contact-info">
												<span className="contact-name text-ellipsis">{doctor ? doctor.firstname+' '+doctor.lastname : ''}</span>
												<span className="contact-date">{doctor ? doctor.speciality: ''}</span>
											</div>
										</div>
									</li>

									)): (
										<SpinnerXS />
									)}
                                    
                                </ul>
                            </div>
                            <div className="card-footer text-center bg-white">
                                <Link to="/doctors" className="text-muted">Voir tous les médecins</Link>
                            </div>
                        </div>
                    </div>
				</div>
				<div className="row">
					<div className="col-12 col-md-8 col-lg-8 col-xl-8">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title d-inline-block">Nouvelles factures </h4> <Link to="/appointments" className="btn btn-primary float-right">Voir tout</Link>
							</div>
							<div className="card-block">
								<div className="table-responsive">
									<table className="table m-b-0 new-patient-table">
										<tbody>
											{!loadingInvoices && invoices ? invoices.map((invoice, index) => (

												<tr>
													<td><Link to={`/invoices/${invoice._id}/view`}>#INV-0{index+1}</Link></td>
													<td>
														<img width="28" height="28" className="rounded-circle" src="assets/img/user.jpg"/> 
														<h2>{invoice.appointment.patient ? `${invoice.appointment.patient.firstname[0]}.${invoice.appointment.patient.lastname}` : ''}</h2>
													</td>
													<td>{moment(invoice.dueDate).format('DD MMM YYYY')}</td>
													<td>{invoice.grandTotal ? invoice.grandTotal.toFixed(2) : 0} {invoice.currency}</td>
													<td>{invoice.paidBy}</td>
													<td>
														<span className={`custom-badge ${handleClass(invoice.status)}`}>{invoice.status}</span>
													</td>
												</tr>

											)): (
												<SpinnerXS />
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						
					</div>
					<div className="col-12 col-md-4 col-lg-4 col-xl-4">
									<div className="hospital-barchart">
										<h4 className="card-title d-inline-block">Hospital Management</h4>
									</div>
									<div className="bar-chart">
										<div className="legend">
											<div className="item">
												<h4>Level1</h4>
											</div>
											
											<div className="item">
												<h4>Level2</h4>
											</div>
											
											<div className="item text-right">
												<h4>Level3</h4>
											</div>
									
											<div className="item text-right">
												<h4>Level4</h4>
											</div>
										</div>
                
										<div className="chart clearfix">
											{!loadingDepartments && departments ? departments.map((department, index) => (
												<div key={index} className="item">
													<div className="bar">
														<span className="percent">12%</span>
										
														<div className="item-progress" data-percent="12">
															<span className="title">{department.name}</span>
														</div>
													</div>
												</div>
											)): (
												<SpinnerXS />
											)}
										</div>
									</div>
                             </div>
                </div>
                </>
            </Layout>
        </div>
    )
}

export default Dashboard
