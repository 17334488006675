import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../core/Layout';

import { isAuthenticated } from '../../auth/helpers';

import { addDepartment, updateDepartment } from '../../actions/departmentActions';
import { API_URL } from '../../config';

import Spinner from '../../core/Spinner';
import { Link } from 'react-router-dom';
import { GET_DEPARTMENT } from '../../actions/types';
import Page500 from '../../core/Page500';

const initialState = {
    name: '',
    description: '',
    status: '',
    color: ''
}

const FormDepartment = ({history, match}) => {

    const dispatch = useDispatch();
    const department_id = match.params.id;
    const [formData, setFormData] = useState(initialState);
    const loading = useSelector(state => state.department.loading);
    const error = useSelector(state => state.department.error);

    const loadDepartment = (id) => {
        return fetch(`${API_URL}/departments/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {

            dispatch({
                type: GET_DEPARTMENT,
                payload: res.department
            });

            setFormData(res.department);
        })
        .catch(err => console.error(err))
    }

    useEffect(() => {
        if(department_id) loadDepartment(department_id);

    }, [department_id])

    const { name, description, status, color } = formData;

    const handleChange = e => setFormData({...formData, [e.target.name]: e.target.value });

    const submitDepartment = e => {

        e.preventDefault();

        const { user, token } = isAuthenticated();
        
        if(department_id){
            dispatch(updateDepartment(formData, user._id, token, department_id, history))
        }else{
            dispatch(addDepartment(formData, user._id, token, history))
        }

    }

    if (error == true) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading && department_id ? ( <Spinner/>) : (
                <Fragment>
                    <Layout>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h4 className="page-title">{department_id ? "Update" : "Add"} Department</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <form onSubmit={submitDepartment}>
                                    <div className="form-group">
                                        <label htmlFor="name">Department Name</label>
                                        <input name="name" required autoFocus value={name} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="description">Description</label>
                                        <textarea value={description} onChange={handleChange} name="description" cols="30" rows="4" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="color">Color</label>
                                        <select value={color} onChange={handleChange} name="color" className="form-control">
                                            <option value=''>Select a color</option>
                                            <option>green</option>
                                            <option>blue</option>
                                            <option>orange</option>
                                            <option>purple</option>
                                            <option>black</option>
                                            <option>red</option>
                                            <option>brown</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="display-block">Status</label>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="status" 
                                                value="Active" 
                                                checked={status === 'Active'}
                                                onChange={handleChange}
                                                />
                                            <label className="form-check-label" htmlFor="department_active">
                                            Active
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="status" 
                                                value="Inactive" 
                                                checked={status === 'Inactive'}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="department_inactive">
                                            Inactive
                                            </label>
                                        </div>
                                    </div>
                                    <div className="m-t-20 text-center">
                                        <Link className="mr-2" to="/departments">
                                            <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                                        </Link>
                                        {department_id ? (
                                            <button type="submit" className="btn btn-warning submit-btn">Update Department</button>
                                            
                                            ): (
                                            <button type="submit" className="btn btn-primary submit-btn">Create Department</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Layout>
            </Fragment>
            )}
        </Fragment>
    )
}

export default FormDepartment
