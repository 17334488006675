import {
    ADD_PATIENT,
    PATIENT_ERROR,
    GET_PATIENTS,
    GET_PATIENT,
    DELETE_PATIENT,
    UPDATE_PATIENT,
    CLEAR_PATIENT
} from './../actions/types';

let initialState = {
    patients: [],
    patient: {},
    loading: true,
    error: false
}

const patientReducer = (state = initialState, action) => {

    const { type, payload } = action;
    
    switch (type) {

        case GET_PATIENTS:
            return {
                ...state,
                patients: payload,
                loading: false,
                error: false
            }
        case GET_PATIENT:
            return {
                ...state,
                patient: payload,
                loading: false,
                error: false
            }
        case DELETE_PATIENT:
            return {
                ...state,
                patients: state.patients.filter(patient => patient._id !== payload),
                loading: false,
                error: false
            }
        case ADD_PATIENT:
            return {
                ...state,
                patients: [payload, ...state.patients],
                loading: false,
                error: false
            }
        case PATIENT_ERROR:
            return {
                ...state,
                patients: payload,
                loading: false,
                error: true
            }
        case UPDATE_PATIENT:
            return {
                ...state,
                patients: state.patients.map(patient => patient._id === payload._id ? (patient = payload) : patient),
                loading: false,
                error: false
            }
        case CLEAR_PATIENT:
            return {
                ...state,
                patient: null
            }
        default:{
            return state
        }
    }

}

export default patientReducer
