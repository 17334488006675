import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctor } from '../../actions/doctorActions';
import moment from 'moment';

import Layout from '../../core/Layout';
import Page500 from '../../core/Page500';
import Spinner from '../../core/Spinner';
import { API_URL } from '../../config';
import { Link } from 'react-router-dom';

const ProfileDoctor = ({match}) => {

    const doctor_id = match.params.id;
    const dispatch = useDispatch();
    const doctor = useSelector(state => state.doctor.doctor);
    const loading = useSelector(state => state.doctor.loading);
    const error = useSelector(state => state.doctor.error);

    useEffect(() => {
        if(doctor_id) dispatch(getDoctor(doctor_id));
    }, [doctor_id]);

    if (error == true) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading || !doctor ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                    <div className="row">
                        <div className="col-sm-7 col-4">
                            <h4 className="page-title">Profil</h4>
                        </div>

                        <div className="col-sm-5 col-8 text-right m-b-30">
                            <Link to={`/doctors/${doctor_id}/update`}className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Modifier le profil</Link>
                        </div>
                    </div>
                    <div className="card-box profile-header">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="profile-view">
                                    <div className="profile-img-wrap">
                                        <div className="profile-img">
                                            <a href="#"><img className="avatar" src={doctor_id ? `${API_URL}/users/photo/${doctor_id}` : ''} alt=""/></a>
                                        </div>
                                    </div>
                                    <div className="profile-basic">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="profile-info-left">
                                                    <h3 className="user-name m-t-0 m-b-0">{doctor ? doctor.firstname : ''} {doctor ? doctor.lastname : ''}</h3>
                                                    <small className="text-muted">{doctor ? doctor.speciality : ''}</small>
                                                    <div className="staff-id">Employé(e) ID : MD-{doctor ? doctor._id : ''}</div>
                                                    <div className="staff-msg"><a href="chat.html" className="btn btn-primary">Envoyer un message</a></div>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <ul className="personal-info">
                                                    <li>
                                                        <span className="title">Téléphone:</span>
                                                        <span className="text"><a href="">{doctor ? doctor.phone: ''}</a></span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Adresse email:</span>
                                                        <span className="text"><a href="">{doctor ? doctor.email: ''}</a></span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Date de naissance:</span>
                                                        <span className="text">{moment(doctor ? doctor.birthday: '').format('D MMM YYYY')}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Adresse:</span>
                                                        <span className="text">{doctor ? doctor.address: ''}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Sexe:</span>
                                                        <span className="text">{doctor ? doctor.gender: ''}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>
                    <div className="profile-tabs">
                        <ul className="nav nav-tabs nav-tabs-bottom">
                            <li className="nav-item"><a className="nav-link active" href="#about-cont" data-toggle="tab">À propos</a></li>
                            <li className="nav-item"><a className="nav-link" href="#bottom-tab2" data-toggle="tab">Profil</a></li>
                            <li className="nav-item"><a className="nav-link" href="#bottom-tab3" data-toggle="tab">Messages</a></li>
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane show active" id="about-cont">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-box">
                                <h3 className="card-title">Informations sur l'éducation</h3>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">International College of Medical Science (UG)</a>
                                                    <div>MBBS</div>
                                                    <span className="time">2001 - 2003</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">International College of Medical Science (PG)</a>
                                                    <div>MD - Obstetrics & Gynaecology</div>
                                                    <span className="time">1997 - 2001</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-box m-b-0">
                                <h3 className="card-title">Experience</h3>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">Consultant Gynecologist</a>
                                                    <span className="time">Jan 2014 - Present (4 years 8 months)</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">Consultant Gynecologist</a>
                                                    <span className="time">Jan 2009 - Present (6 years 1 month)</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">Consultant Gynecologist</a>
                                                    <span className="time">Jan 2004 - Present (5 years 2 months)</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                            </div>
                            <div className="tab-pane" id="bottom-tab2">
                                Tab content 2
                            </div>
                            <div className="tab-pane" id="bottom-tab3">
                                Tab content 3
                            </div>
                        </div>
                    </div>

                    </Layout>
                </Fragment>)
            }  
        </Fragment>
    )
}

export default ProfileDoctor;
