let myState = {
    employeesSalaries: [],
    employeeSalary: {}
}

const employeeSalaryReducer = (state = myState, action) => {
    switch (action.type) {

        case 'GET_EMPLOYEES_SALARIES':
            return {
                ...state,
                employeesSalaries: action.payload
            }
        case 'GET_EMPLOYEE_SALARY':
            return {
                ...state,
                employeeSalary: action.payload
            }
        case 'DELETE_EMPLOYEE_SALARY':
            return {
                ...state,
                employeesSalaries: state.employeesSalaries.filter(employeeSalary => employeeSalary._id !== action.payload)
            }
        case 'ADD_EMPLOYEE_SALARY':
            return {
                ...state,
                employeesSalaries: [...state.employeesSalaries, action.payload]
            }
        case 'UPDATE_EMPLOYEE_SALARY':
            return {
                ...state,
                employeesSalaries: state.employeeSalary.map(employeeSalary => employeeSalary._id === action.payload._id ? (employeeSalary = action.payload) : employeeSalary)
            }
        default:{
            return state
        }
    }
}

export default employeeSalaryReducer;
