import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { addExpense, updateExpense } from '../../../actions/expenseActions';
import { EXPENSE_ERROR, GET_EXPENSE } from '../../../actions/types';

import { isAuthenticated } from '../../../auth/helpers';
import { API_URL } from '../../../config';
import Layout from '../../../core/Layout';
import { Fragment } from 'react';
import Spinner from '../../../core/Spinner';

const initialState = {
    itemName: '',
    purchaseFrom: '',
    purchaseDate: '',
    purchasedBy: '',
    amount: 0,
    paidBy: '',
    status: '',
    attachment: ''
};

const FormExpense = ({match, history}) => {

    const dispatch = useDispatch();
    const expense_id = match.params.id;

    const loading = useSelector(state => state.expense.loading);
    const error = useSelector(state => state.expense.error);

    const [upExpense, setUpExpense] = useState(initialState);

    const [formData, setFormData] = useState(new FormData());

    useEffect(() => {
        if(expense_id) getExpense(expense_id);
    }, [expense_id])

    /* const getExpense = id => {

        fetch(`${API_URL}/expenses/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setExpense(res.expense);
            formData.set('itemName',res.expense && res.expense.itemName ? res.expense.itemName : '');
            formData.set('purchaseFrom',res.expense && res.expense.purchaseFrom ? res.expense.purchaseFrom : '');
            formData.set('purchaseDate',res.expense && res.expense.purchaseDate ? res.expense.purchaseDate : '');
            formData.set('purchasedBy',res.expense && res.expense.purchasedBy ? res.expense.purchasedBy : '');
            formData.set('amount',res.expense && res.expense.amount ? res.expense.amount : '');
            formData.set('paidBy',res.expense && res.expense.paidBy ? res.expense.paidBy : '');
            formData.set('status',res.expense && res.expense.status ? res.expense.status : '');
        })
        .catch(err => console.error(err))
    
    } */

    const getExpense = async id => {

        try {
            const res = await axios(`${API_URL}/expenses/${id}`);
            const expense = res.data.expense;

            if(!loading && expense) {
                const expenseData = { ...initialState };
                for(const key in expense){
                    if(key in expenseData && key !== 'photo') {
                        expenseData[key] = expense[key];
                        formData.set(key, expense[key])
                    }
                }
                setUpExpense(expenseData);
            }
            
            dispatch({
                type: GET_EXPENSE,
                payload: expense
            });
            
        }catch (err) {
            dispatch({type: EXPENSE_ERROR});
        }
    }

    const handleChange = e => {
        let key = e.target.name
        let value = ""
        
        if(e.target.type == 'file' && e.target.name == 'attachement'){
            value = e.target.files[0]  
        }
        else{
            value = e.target.value
        } 
        
        formData.set(key, value)

        setUpExpense({...upExpense, [key]: value})
    }

    const submit = e => {
        e.preventDefault();
        
        const { user, token } = isAuthenticated();

        if(expense_id){
            dispatch(updateExpense(formData, user, token, expense_id, history));
        }else{
            dispatch(addExpense(formData, user, token, history));
        }
    }

    const {itemName, purchaseFrom, purchaseDate, purchasedBy, amount, paidBy, status, attachment } = upExpense    
    
    return (
        <Fragment>
            {loading ? (<Spinner />) : (
			
			<Fragment>
                <Layout>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h4 className="page-title">New Expense</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <form onSubmit={submit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Item Name</label>
                                            <input name="itemName" onChange={handleChange} value={itemName} className="form-control" type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Purchase From</label>
                                            <input name="purchaseFrom" onChange={handleChange} value={purchaseFrom} className="form-control" type="text"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Purchase Date</label>
                                            <input name="purchaseDate" onChange={handleChange} value={purchaseDate} className="form-control datetimepicker" type="date"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Purchased By </label>
                                            <select name="purchasedBy" onChange={handleChange} value={purchasedBy} className="select form-control">
                                                <option value="">Select an user</option>
                                                <option>Daniel Porter</option>
                                                <option>Roger Dixon</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Amount</label>
                                            <input name="amount" onChange={handleChange} value={amount} placeholder="$50" className="form-control" type="number"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Paid By</label>
                                            <select name="paidBy" onChange={handleChange} value={paidBy} className="select form-control">
                                                <option>Select payment method</option>
                                                <option>Cash</option>
                                                <option>Cheque</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select name="status" onChange={handleChange} value={status} className="select form-control">
                                                <option>Select status</option>
                                                <option>Pending</option>
                                                <option>Approved</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Attachments</label>
                                            <input name="attachment" onChange={handleChange} className="form-control" type="file"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-t-20 text-center">
                                    <Link className="mr-2" to="/expenses">
                                        <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                                    </Link>
                                    {expense_id ? (
                                        <button type="submit" className="btn btn-warning submit-btn">Update Expense</button>
                                    ): (
                                        <button type="submit" className="btn btn-primary submit-btn">Create Expense</button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </Layout>
            </Fragment>)
		}  
        </Fragment>
    )
}

export default FormExpense;
