import React, {Fragment, useState, useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import axios from 'axios';

import { addEmployee, updateEmployee } from '../../../actions/employeeActions';
import { getRoles } from '../../../actions/roleActions';

import { isAuthenticated } from '../../../auth/helpers';
import { API_URL } from '../../../config';
import Layout from '../../../core/Layout';
import { Link } from 'react-router-dom';
import { EMPLOYEE_ERROR, GET_EMPLOYEE } from '../../../actions/types';
import { Spinner } from 'react-bootstrap';


const initialState = {
    firstname: '',
    lastname: '',
    birthday: '',
    gender: '',
    email: '',
    password:'',
    confirmPassword:'',
    address: '',
    country: '',
    city: '',
    postalcode: '',
    phone: '',
    photo: '',
    role: '',
    speciality: null,
    status: '',
    joiningDate: '',
    remainingLeave: '',
    cin: ''
}

const FormEmployee = ({match, history}) => {

    const employee_id = match.params.id;

    const dispatch = useDispatch();
    const loading = useSelector(state => state.role.loading);
    const error = useSelector(state => state.role.error);
    
    const roles = useSelector(state => state.role.roles);

    const [employee, setEmployee] = useState(initialState);
    const [formData, setFormData] = useState(new FormData());
    let [isDoctor, setIsDoctor] = useState(false);

    useEffect(() => {
        if(employee_id) getEmployee(employee_id);

        dispatch(getRoles());
    }, [employee_id])

    const getEmployee = async id => {

        try {
            const res = await axios(`${API_URL}/users/${id}`);
            const employee = res.data.user;

            if(!loading && employee) {
                const employeeData = { ...initialState };
                for(const key in employee){
                    if(key in employeeData && key !== 'photo' && key !== 'role') {
                        employeeData[key] = employee[key];
                        formData.set(key, employee[key])
                    }
                    
                    employeeData['role'] = employee['role']._id;
                    formData.set('role', employee['role']._id);
                }

                setEmployee(employeeData);
            }
            
            dispatch({
                type: GET_EMPLOYEE,
                payload: employee
            });
            
        }catch (err) {
            dispatch({type: EMPLOYEE_ERROR});
        }
    }

    const handleChange = e => {

        let key = e.target.name;
        let value = "";
        
        if(e.target.type == 'file' && e.target.name == 'photo'){
            value = e.target.files[0]  
        }
        else{
            value = e.target.value
        } 

        
        formData.set(key, value)
        
        setEmployee({...employee, [key]: value})
        
        if(e.target.options && e.target.name == 'role'){
            
            if(e.target.options[e.target.selectedIndex].dataset.rolename === 'Doctor'){
                setIsDoctor(true);
            }else{
                setIsDoctor(false);
                formData.set("speciality", null)
                setEmployee({...employee, "speciality": null})
            }
        }
    }

    const submitEmployee = e => {

        e.preventDefault();

        const { user, token } = isAuthenticated();

        if(employee_id){
            dispatch(updateEmployee(formData, user._id, token, employee_id, history))        
        }else{
            dispatch(addEmployee(formData, user._id, token, history))  
        }
    }

    const {
        firstname,
        lastname,
        birthday,
        gender,
        email,
        address,
        country,
        city,
        postalcode,
        cin,
        phone,
        password,
        confirmPassword,
        role,
        speciality,
        status,
        joiningDate,
        remainingLeave
    } = employee


    return (
        <Fragment>
            {loading ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                    <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h4 className="page-title">{employee_id ? 'Modifier' : 'Ajouter'} un employé</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <form onSubmit={submitEmployee}>
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="firstname">Prénom<span className="text-danger">*</span></label>
                                                <input name="firstname" value={firstname} onChange={handleChange} className="form-control" type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="lastname">Nom <span className="text-danger">*</span></label>
                                                <input name="lastname" value={lastname} onChange={handleChange} className="form-control" type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="birthday">Date de naissance <span className="text-danger">*</span></label>
                                                <input name="birthday" value={moment(birthday).format('YYYY-MM-DD')} onChange={handleChange} type="date" className="form-control datetimepicker"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="form-group gender-select">
                                                <label className="gen-label">Sexe <span className="text-danger">*</span></label>
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input onChange={handleChange} type="radio" name="gender" checked={gender == "Male"} className="form-check-input" value="Male"/>Male
                                                    </label>
                                                </div>
                                                <div className="form-check-inline">
                                                    <label className="form-check-label">
                                                        <input onChange={handleChange} type="radio" name="gender" checked={gender == "Female"} className="form-check-input" value="Female"/>Female
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="cin" title="Numéro de la crte nationale">CIN <span className="text-danger">*</span></label>
                                                <input name="cin" value={cin} onChange={handleChange} className="form-control" type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="phone">Téléphone <span className="text-danger">*</span></label>
                                                <input name="phone" value={phone} onChange={handleChange} className="form-control" type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Adresse email <span className="text-danger">*</span></label>
                                                <input name="email" value={email} onChange={handleChange} className="form-control" type="email"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="form-group">
                                                <label>Photo de profil</label>
                                                <div className="profile-upload">
                                                    <div className="upload-img">
                                                        <img alt="" src="./../../assets/img/user.jpg"/>
                                                    </div>
                                                    <div className="upload-input">
                                                        <input name="photo" onChange={handleChange} type="file" className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="joiningDate">Data d'intégration <span className="text-danger">*</span></label>
                                                <input name="joiningDate" value={moment(joiningDate).format('YYYY-MM-DD')} onChange={handleChange} type="date" className="form-control datetimepicker"/>
                                            </div>
                                        </div>
                                    </div>  

                                    {!employee_id ? (
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="password">Mot de passe <span className="text-danger">*</span></label>
                                                    <input name="password" value={password} onChange={handleChange} className="form-control" type="password"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="confirmPassword">Confirer le mot de passe <span className="text-danger">*</span></label>
                                                    <input name="confirmPassword" value={confirmPassword} onChange={handleChange} className="form-control" type="password"/>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="row">
                                        
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="role">Rôle <span className="text-danger">*</span></label>
                                                <select name="role" value={role._id} onChange={handleChange} type="text" className="form-control select">
                                                    <option value="">Select the role</option>
                                                    {roles && roles.map((role, index) => (
                                                        <option key={index} value={role._id} data-rolename={role.name}>{role.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {}
                                        {isDoctor ? (
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label htmlFor="speciality">Spécialité <span className="text-danger">*</span></label>
                                                    <select name="speciality" onChange={handleChange} className="form-control select" value={speciality}>
                                                        <option>Sélectionnez la spécialité</option>
                                                        <option>Cardiologue</option>
                                                        <option>Dentiste</option>
                                                        <option>Gynécologue</option>
                                                        <option>Ophtalmologiste</option>
                                                        <option>Psychiatre</option>
                                                        <option>Radiologue</option>
                                                        <option>Urologue</option>
                                                        <option>Autre</option>
                                                    </select>
                                                </div>
                                            </div>
                                        ): false}
                                    </div>
                                    
                                    <hr/>
                                    
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="address">Adresse</label>
                                                <textarea name="address" value={address} onChange={handleChange} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="country">Pays <span className="text-danger">*</span></label>
                                                <select name="country" value={country} onChange={handleChange}  className="form-control select">
                                                    <option>Sélectionnez le pays</option>
                                                    <option value="Morocco">Morocco</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="city">Ville <span className="text-danger">*</span></label>
                                                <select name="city" value={city} onChange={handleChange} type="text" className="form-control select">
                                                    <option>Sélectionnez la ville</option>
                                                    <option value="Agadir">Agadir</option>
                                                    <option value="Casablanca">Casablanca</option>
                                                    <option value="Marrakech">Marrakech</option>
                                                    <option value="Rabat">Rabat</option>
                                                    <option value="Tanger">Tanger</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="postalcode">Code postal</label>
                                                <input name="postalcode" value={postalcode} onChange={handleChange} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="remainingLeave">Congé restant (jour)<span className="text-danger">*</span></label>
                                                <input name="remainingLeave" value={remainingLeave} onChange={handleChange} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <hr/>
                                        
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="display-block">Statut <span className="text-danger">*</span></label>
                                                <div className="form-check form-check-inline">
                                                    <input onChange={handleChange} className="form-check-input" type="radio" name="status" checked={status == "Actif"} value="Actif"/>
                                                    <label className="form-check-label" htmlFor="employee_active">
                                                    Actif
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input onChange={handleChange} className="form-check-input" type="radio" name="status" checked={status == "Inactif"} value="Inactif"/>
                                                    <label className="form-check-label" htmlFor="employee_inactive">
                                                    Inactif
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="m-t-20 text-center">
                                        <Link className="mr-2" to="/employees">
                                            <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Retourner</button>
                                        </Link>
                                        {employee_id ? (
                                            <button type="submit" className="btn btn-warning submit-btn">Mettre à jour l'employé</button>
                                        ) : (
                                            <button type="submit" className="btn btn-primary submit-btn">Créer un employé</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Layout>
                </Fragment>)
            }  
        </Fragment>
    )
}

export default FormEmployee;
