import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../core/Layout';
import { Modal, Button } from 'react-bootstrap';
import { API_URL } from '../../config';

import moment from 'moment';

import { getAppointments, updateAppointmentStatus, removeAppointment, getAppointment } from '../../actions/appointmentActions';
import { getDepartments } from '../../actions/departmentActions';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helpers';

import Spinner from '../../core/Spinner';
import Page500 from '../../core/Page500';

const ListAppointments = () => {

    let appointments = useSelector(state => state.appointment.appointments);
    let departments = useSelector(state => state.department.departments);
    let loading = useSelector(state => state.appointment.loading);
    let error = useSelector(state => state.appointment.error);
    let dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [deletedAppointmentId, setDeletedAppointmentId] = useState('');
	const [searchData, setSearchData] = useState('');
	let [limit, setLimit] = useState(8);

    useEffect(() => {
        dispatch(getAppointments({limit}))
        dispatch(getDepartments())
    }, [])

	
	// handle status ------------------------------------
	const handleClass = (status) => {
		switch(status){
			case 'Pending':
				return 'status-orange';
			
			case 'Approved':
				return 'status-green';

			case 'Canceled':
				return 'status-red';
			
			case 'Closed':
				return 'status-grey';

			default:
				return 'status-red'
		}
	}

	const handleStatus = (e,appointment) => {
		const { user, token } = isAuthenticated();
        dispatch(updateAppointmentStatus(user._id, token, appointment._id, e.target.dataset.status))
    }
	// end handle status --------------------------------

	// search ------------------------------------------
    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.id]: e.target.value })
    }

	const searchSubmit = (e) => {
        e.preventDefault();
    
        if(searchData && searchData.patient != "" && searchData.doctor != "" && searchData.department != "" && searchData.status != ""){
            
            dispatch(getAppointments({search: searchData}));
        
        }else{
            dispatch(getAppointments({limit:10}))
                setLimit(10);
        }    
    }
	// end search ------------------------------------------


	// delete -------------------------------------------
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedAppointmentId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {
		const { user, token } = isAuthenticated();
        dispatch(removeAppointment(deletedAppointmentId, user, token))
        setShow(false);
    }

    const deleteAppointmentModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Appointment?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }
	// end delete ------------------------------------------


	// load button -----------------------------------------------
    const loadMore = () => {

        limit = limit + 5

        dispatch(getAppointments({limit, search: searchData}))
        setLimit(limit)
        
    }


    const buttonToLoadMore = () => {
        return (
            appointments.length > 0 && appointments.length >= limit && (
                <div className="text-center mt-2">
                    <button onClick={loadMore} className="btn btn-outline-success">Load More</button>
                </div>
            )   
        )
    } 

	// end load button ------------------------------------------

	if(error) return <Page500/>;
	
    return (
		<Fragment>
            {loading ? (<Spinner />) : (
			
			<Fragment>
				<Layout>
						<div className="row">
							<div className="col-sm-4 col-3">
								<h4 className="page-title">Appointments 
								
									<Link to="/appointments/calendar">
										<button className="btn btn-primary ml-1">
											<i className="fa fa-calendar"></i>
										</button>
									</Link>
								</h4>
							</div>
							<div className="col-sm-8 col-9 text-right m-b-20">
								<Link to="/appointments/create" className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Add Appointment</Link>
							</div>
						</div>
						<form onSubmit={searchSubmit}>
                            <div className="row filter-row">
                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Patient Name</label>
                                        <input id="patient" onChange={handleChange} type="text" className="form-control floating"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-2">
                                    <div className="form-group">
                                        <select style={{ height: 'calc(2.25rem + 14px)' }} id="department" onChange={handleChange} className="form-control">
                                            <option value="">Select Department</option>
											{departments && departments.map((department, index) => (
												<option key={index} value={department._id} >{department.name}</option>
											))}
                                        </select>
                                    </div>
                                </div>
								<div className="col-sm-6 col-md-3">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Doctor Name</label>
                                        <input id="doctor" onChange={handleChange} type="text" className="form-control floating"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-2">
                                    <div className="form-group">
                                        <select style={{ height: 'calc(2.25rem + 14px)' }} id="status" onChange={handleChange} className="form-control">
                                            <option value="">Select Status</option>
                                            <option>Approved</option>
                                            <option>Canceled</option>
                                            <option>Closed</option>
                                            <option>Pending</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-2">
                                    <button type="submit" className="btn btn-success btn-block"> Search </button>
                                </div>
                            </div>
                        </form>
						<div className="row">
							<div className="col-md-12">
								<div className="table-responsive">
									<table id="example" className="table table-striped dt-responsive custom-table nowrap" cellSpacing="0" width="100%">
										<thead>
											<tr>
												<th>#</th>
												<th>Patient Name</th>
												<th>Department</th>
												<th>Date</th>
												<th>Visit Time</th>
												<th>Doctor Name</th>
												<th>Injury/Condition</th>
												<th>Status</th>
												<th className="text-right">Action</th>
											</tr>
										</thead>
										<tbody>
											{ appointments && appointments.map((appointment,i) => (
												<tr key={i}>
													<td>{i+1}</td>
													<td>
														<img width="28" height="28" src={`${API_URL}/patients/photo/${appointment.patient._id}`} className="rounded-circle m-r-5"/> 
														{appointment.patient.firstname} {appointment.patient.lastname}
													</td>
													<td>{appointment.department.name}</td>
													<td>{moment(appointment.appointmentDate).format('D MMM YYYY')}</td>
													<td>{appointment.fromTime} - {appointment.toTime}</td>
													<td>
													<img width="28" height="28" src={`${API_URL}/users/photo/${appointment.doctor._id}`} className="rounded-circle m-r-5"/> 
														Dr. {appointment.doctor.lastname}
													</td>
													<td>{appointment.injury}</td>

													<td>
														<div className="dropdown action-label">
															<a className={`custom-badge ${handleClass(appointment.status)} ${appointment.status !== 'Closed' ? 'dropdown-toggle' : ''}`} href="#" data-toggle="dropdown" aria-expanded="false">
																{appointment.status}
															</a>

															{appointment.status !== 'Closed' && (
																<div className="dropdown-menu">
																	{appointment.status !== "Approved" && (
																		<a onClick={e => handleStatus(e,appointment)} className="dropdown-item" href="#" data-status="Approved">Approved</a>
																	)}
																	{appointment.status !== "Pending" && (
																		<a onClick={e => handleStatus(e,appointment)} className="dropdown-item" href="#" data-status="Pending">Pending</a>
																	)}
																	{appointment.status !== "Canceled" && (
																		<a onClick={e => handleStatus(e,appointment)} className="dropdown-item" href="#" data-status="Canceled">Canceled</a>
																	)}
																</div>
															)}
														</div>
													</td>

												
													<td className="text-right">
														
														{appointment.status == "Closed" && (
															<Link title="Invoice" className="dropdown-item" to={`/invoices/${appointment.invoice}/view`}><i className="fa fa-file m-r-5"></i></Link>
														)}

														{appointment.status === "Approved" && (
															<>
																<Link to={`/appointments/${appointment._id}/prescriptions/create`}>
																	<i title="Add prescription" className="fa fa-plus mx-1"></i>
																</Link>
																<Link to={`/appointments/${appointment._id}/invoices/create`}>
																	<button title="Create invoice" className="btn btn-sm btn-secondary"><i className="fa fa-shopping-cart"></i></button>
																</Link>
															</>
														)}
														{appointment.status !== 'Closed' && (
															<Fragment>
																<Link to={`/appointments/${appointment._id}/update`}>
																	<button title="Modifier" className="btn btn-sm btn-warning mx-1"><i className="fa fa-edit"></i></button>
																</Link>
																<button title="Supprimer" className="btn btn-sm btn-danger" onClick={handleShow.bind(this,appointment._id)}><i className="fa fa-trash"></i></button>
															</Fragment>
														)}
													</td>
												</tr>
											)) }
										</tbody>
									</table>
								</div>
								{buttonToLoadMore()}
							</div>
						</div>
						{deleteAppointmentModal()}
					</Layout>
			</Fragment>)
		}  
        </Fragment>
    )
};

export default ListAppointments
