import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { loadUser } from '../actions/authActions';
import Page500 from '../core/Page500';
import Spinner from '../core/Spinner';
import { API_URL } from '../config';
import Layout from '../core/Layout';
import { isAuthenticated } from '../auth/helpers';
import { Link } from 'react-router-dom';

const Profile = ({history}) => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const loading = useSelector(state => state.auth.loading);
    const error = useSelector(state => state.auth.error);

    useEffect(() => {
        const { token } = isAuthenticated();
        if(token) dispatch(loadUser(token, history));
    }, []);

    if (error) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                    <div className="row">
                        <div className="col-sm-7 col-4">
                            <h4 className="page-title">My Profile</h4>
                        </div>

                        <div className="col-sm-5 col-8 text-right m-b-30">
                            <Link to="/profile/edit" className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Edit Profile</Link>
                        </div>
                    </div>
                    <div className="card-box profile-header">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="profile-view">
                                    <div className="profile-img-wrap">
                                        <div className="profile-img">
                                            <a href="#"><img className="avatar" src={user ? `${API_URL}/users/photo/${user._id}` : ''} alt=""/></a>
                                        </div>
                                    </div>
                                    <div className="profile-basic">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="profile-info-left">
                                                    <h3 className="user-name m-t-0 m-b-0">{user ? user.firstname : ''} {user ? user.lastname : ''}</h3>
                                                    <small className="text-muted">{user ? user.speciality : ''}</small>
                                                    <div className="staff-id">ID : {user ? user._id : ''}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <ul className="personal-info">
                                                    <li>
                                                        <span className="title">Phone:</span>
                                                        <span className="text"><a href="">{user ? user.phone: ''}</a></span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Email:</span>
                                                        <span className="text"><a href="">{user ? user.email: ''}</a></span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Birthday:</span>
                                                        <span className="text">{moment(user ? user.birthday: '').format('d MMM')}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Address:</span>
                                                        <span className="text">{user ? user.address: ''}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Gender:</span>
                                                        <span className="text">{user ? user.gender: ''}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>
                    {/* <div className="profile-tabs">
                        <ul className="nav nav-tabs nav-tabs-bottom">
                            <li className="nav-item"><a className="nav-link active" href="#about-cont" data-toggle="tab">About</a></li>
                            <li className="nav-item"><a className="nav-link" href="#bottom-tab2" data-toggle="tab">Profile</a></li>
                            <li className="nav-item"><a className="nav-link" href="#bottom-tab3" data-toggle="tab">Messages</a></li>
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane show active" id="about-cont">
                            <div className="row">
                        <div className="col-md-12">
                            <div className="card-box">
                                <h3 className="card-title">Education Informations</h3>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">International College of Medical Science (UG)</a>
                                                    <div>MBBS</div>
                                                    <span className="time">2001 - 2003</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">International College of Medical Science (PG)</a>
                                                    <div>MD - Obstetrics & Gynaecology</div>
                                                    <span className="time">1997 - 2001</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-box m-b-0">
                                <h3 className="card-title">Experience</h3>
                                <div className="experience-box">
                                    <ul className="experience-list">
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">Consultant Gynecologist</a>
                                                    <span className="time">Jan 2014 - Present (4 years 8 months)</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">Consultant Gynecologist</a>
                                                    <span className="time">Jan 2009 - Present (6 years 1 month)</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="experience-user">
                                                <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                                <div className="timeline-content">
                                                    <a href="#/" className="name">Consultant Gynecologist</a>
                                                    <span className="time">Jan 2004 - Present (5 years 2 months)</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                            </div>
                            <div className="tab-pane" id="bottom-tab2">
                                Tab content 2
                            </div>
                            <div className="tab-pane" id="bottom-tab3">
                                Tab content 3
                            </div>
                        </div>
                    </div> */}

                    </Layout>
                </Fragment>)
            }  
        </Fragment>
    )
}

export default Profile;
