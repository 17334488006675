import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTaxs, updateTaxStatus, removeTax } from '../../../actions/taxActions';
import Layout from '../../../core/Layout';

import { Modal, Button } from 'react-bootstrap'
import { isAuthenticated } from '../../../auth/helpers';

const ListTaxes = () => {

    const { user, token } = isAuthenticated();
    
    const taxs = useSelector(state => state.tax.taxs);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [deletedTaxId, setDeletedTaxId] = useState('');


    useEffect(() => {
        dispatch(getTaxs())
    }, [])

    const handleStatus = (e,tax) => {
        dispatch(updateTaxStatus(user, token, tax, e.target.dataset.status))
    }

    // delete
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedTaxId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {


        dispatch(removeTax(deletedTaxId, user, token))
        setShow(false);
    }

    const deleteTaxModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Tax?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }


    return (
        <div>
            <Layout>
                <div className="row">
                    <div className="col-sm-8 col-6">
                        <h4 className="page-title">Taxes</h4>
                    </div>
                    <div className="col-sm-4 col-6 text-right m-b-30">
                        <Link to="/taxs/create" className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Add Tax</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped custom-table m-b-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tax Name </th>
                                        <th>Tax Percentage (%) </th>
                                        <th>Status</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { taxs && taxs.map((tax,i) => (
                                        <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>{tax.name}</td>
                                            <td>{tax.percentage}%</td>
                                            <td>
                                            {tax.status == "Approved" ? (
                                                <div className="dropdown action-label">
                                                    <a className="custom-badge status-green dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                        Approved
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <a onClick={e => handleStatus(e,tax)} className="dropdown-item" href="#" data-status="Approved">Approved</a>
                                                        <a onClick={e => handleStatus(e,tax)} className="dropdown-item" href="#" data-status="Pending">Pending</a>
                                                         
                                                        
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="dropdown action-label">
                                                    <a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                        Pending
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <a onClick={e => handleStatus(e,tax)} className="dropdown-item" href="#" data-status="Approved">Approved</a>
                                                        <a onClick={e => handleStatus(e,tax)} className="dropdown-item" href="#" data-status="Pending">Pending</a>
                                                    </div>
                                                </div>
                                            )}
                                            </td>
                                            <td className="text-right">
                                                <div className="dropdown dropdown-action">
                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to={`/taxs/${tax._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link>
                                                        <a style={{cursor: "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,tax._id)}><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {deleteTaxModal()}
            </Layout>
        </div>
    )
}

export default ListTaxes
