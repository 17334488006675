import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Pdf from "react-to-pdf";

import Layout from '../../core/Layout';
import Page500 from '../../core/Page500';

import Spinner from '../../core/Spinner';
import { API_URL } from '../../config';
import { GET_PRESCRIPTION, PRESCRIPTION_ERROR } from '../../actions/types';


const ref = React.createRef();

const ViewPrescription = ({match}) => {

    const dispatch = useDispatch();
    const [prescription, setPrescription] = useState({});
    const [settings, setSettings] = useState({});
    const loading = useSelector(state => state.prescription.loading);
    const error = useSelector(state => state.prescription.error);
    const prescriptionId = match.params.id;

    useEffect(() => {
        dispatch(getSettings());
        if(prescriptionId) dispatch(getPrescription(prescriptionId));
    }, [prescriptionId]);


    const getSettings = () => dispatch => {

        fetch(`${API_URL}/settings`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {

            setSettings(res.settings[0]);
            
            dispatch({
                type: 'GET_SETTINGS',
                payload: res.settings[0]
            })
        })
        .catch(err => console.error(err))
    }

    const getPrescription = id => dispatch => {

        fetch(`${API_URL}/prescriptions/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setPrescription(res.prescription);
            dispatch({
                type: GET_PRESCRIPTION,
                payload: res.prescription
            })
        })
        .catch(err => dispatch({type: PRESCRIPTION_ERROR}));
    
    }


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const ref = React.createRef();

    if(error) return <Page500/>;

    return (
        <Fragment>
            {!prescription || loading ? (<Spinner />) : (
			
			<Fragment>
                <Layout>
                        <div className="row">
                            <div className="col-sm-5 col-4">
                                <h4 className="page-title">Prescription</h4>
                            </div>
                            <div className="col-sm-7 col-8 text-right m-b-30">
                                <div className="btn-group btn-group-sm">
                                    <Pdf targetRef={ref} filename="prescription.pdf" >
                                        {({ toPdf }) => <button className="btn btn-white" onClick={toPdf}>PDF</button>}
                                    </Pdf>
                                    
                                    <button onClick={handlePrint} className="btn btn-white"><i className="fa fa-print fa-lg"></i> Print</button>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div ref={componentRef} className="card">
                                    <div className="card-body" ref={ref} >
                                        <div className="row custom-prescription">
                                            <div className="col-6 col-sm-6 m-b-20">
                                                <img src="assets/img/logo-dark.png" className="inv-logo" alt=""/>
                                                <ul className="list-unstyled">
                                                    <li>{settings ? settings.companyName : ""}</li>
                                                    <li>{settings ? settings.address: ""},</li>
                                                    <li>{settings ? settings.state: ""}, {settings ? settings.city: ""}, {settings ? settings.postalCode: ""}</li>
                                                    <li>GST No:</li>
                                                </ul>
                                            </div>
                                            <div className="col-6 col-sm-6 m-b-20">
                                                <div className="prescription-details">
                                                    <h3 className="text-uppercase">Prescription #{prescription ? prescription._id : ""}</h3>
                                                    <ul className="list-unstyled">
                                                        <li>Date: <span>{prescription ? moment(prescription.prescriptionDate).format('MMMM DD, YYYY') : ""}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-6 m-b-20">
                                                <h5>Prescription to:</h5>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h5><strong>{prescription && prescription.appointment ? prescription.appointment.patient.firstname+' '+prescription.appointment.patient.lastname : ""}</strong></h5>
                                                    </li>
                                                    <li><span>{prescription ? prescription.patientAddress : ""}</span></li>
                                                    <li>5754 Airport Rd</li>
                                                    <li>{prescription && prescription.patient? prescription.patient.phone : ""}</li>
                                                    <li><a href="#">{prescription ? prescription.email : ""}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>ITEM</th>
                                                        <th>DESCRIPTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {prescription && prescription.lineItems && prescription.lineItems.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{index+1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.description ? item.description : "-"}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div>
                                            <div className="prescription-info">
                                                <h5>Other information</h5>
                                                <p className="text-muted">{prescription ? prescription.otherInformation : "-"}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                </Layout>
            </Fragment>)
		    }  
        </Fragment>
    )
}

export default ViewPrescription;
