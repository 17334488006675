import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import { Link } from 'react-router-dom'
import Layout from '../../core/Layout'

import moment from 'moment';

import { Modal, Button } from 'react-bootstrap'

import { isAuthenticated } from './../../auth/helpers';
import { getEmployeesSalaries, removeEmployeeSalary } from '../../actions/employeeSalaryActions';
import { API_URL } from '../../config';

const EmployeeSalary = (props) => {

    const [currencySymbole, setCurrencySymbole] = useState('');

    const getSettings = () => {

        return fetch(`${API_URL}/settings`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setCurrencySymbole(res.settings[0].currencySymbole)
        })
        .catch(err => console.error(err))
    }

    const employeesSalaries = useSelector(state => state.employeeSalary.employeesSalaries)
    let dispatch = useDispatch()
    

    const [show, setShow] = useState(false);
    const [deletedEmployeeSalaryId, setDeletedEmployeeSalaryId] = useState('') 
    const [searchData, setSearchData] = useState('')
    let [limit, setLimit] = useState(4);

    useEffect(() => {
        dispatch(getEmployeesSalaries({limit}))
        getSettings()
    }, [])

    // search panel
    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value })
    }

    const searchSubmit = (e) => {
        e.preventDefault();
        
        if(searchData && searchData.name != "" && searchData.role != "" && searchData.fromDate != "" && searchData.toDate != ""){
            
            dispatch(getEmployeesSalaries({search: searchData}))
        
        }else{
            dispatch(getEmployeesSalaries({limit:3}))
                setLimit(3)
        }
        
    }
    // end search panel

    // delete modal
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedEmployeeSalaryId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {

        const { user, token } = isAuthenticated();

        dispatch(removeEmployeeSalary(deletedEmployeeSalaryId, user, token, props.history))
        setShow(false);
        
    }

    const deleteEmployeeSalaryModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this employee's salary?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }
    // end delete modal

    const classNameRole = (role) => {
        
        switch (role) {
            
            case 'Admin':
                return 'status-red'
                break;
            
            case 'Doctor':
                return 'status-blue'
                break;
            
            case 'Nurse':
                return 'status-green'
                break;
            
            default:
                return 'status-grey'
                break;
        }
      }

      return (
          <div>
            <Layout>
                <div className="row">
                    <div className="col-sm-4 col-5">
                        <h4 className="page-title">Employee Salary</h4>
                    </div>
                    <div className="col-sm-8 col-7 text-right m-b-30">
                        <Link to="/employees-salaries/add" className="btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Add Salary</Link>
                    </div>
                </div>
                
                <form onSubmit={searchSubmit}>
                    <div className="row filter-row">
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <input name="name" onChange={handleChange} type="text" placeholder="Employee Name" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <select name="role" onChange={handleChange} className="select">
                                    <option> -- Select Role -- </option>
                                    <option>Employee</option>
                                    <option>Manager</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group">
                                <label className="focus-label">From</label>
                                <input className="form-control floating" name="fromDate" onChange={handleChange} type="month"/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group">
                                <label className="focus-label">To</label>
                                <input className="form-control floating" name="toDate" onChange={handleChange} type="month"/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 offset-xl-2 offset-md-3 offset-sm-6">
                            <button type="submit" className="btn btn-success btn-block"> Search </button>
                        </div>
                    </div>
                </form>

                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped custom-table">
                                <thead>
                                    <tr>
                                        <th style={{width:'25%'}}>Employee</th>
                                        <th>Email</th>
                                        <th>Joining Date</th>
                                        <th>Role</th>
                                        <th>Salary Month</th>
                                        <th>Net Salary</th>
                                        <th>Payslip</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeesSalaries && employeesSalaries.map((employeeSalary,i) => (
                                        <tr>
                                            {employeeSalary.user && (
                                                <>
                                                    <td>
                                                        <img className="rounded-circle" src="assets/img/user.jpg" height="28" width="28"/> {employeeSalary.user.firstname} {employeeSalary.user.lastname}
                                                    </td>
                                                    <td>{employeeSalary.user.email}</td>
                                                    <td>{moment(employeeSalary.user.joiningDate).format('DD/MM/YYYY') }</td>
                                                    <td>
                                                        <span className={`custom-badge ${classNameRole(employeeSalary.user.role)}`}>{employeeSalary.user.role}</span>
                                                    </td>
                                                    <td>{moment(employeeSalary.salaryMonth).format('MMMM Y') }</td>
                                                    <td>{employeeSalary.netSalary.toFixed(2)} {currencySymbole}</td>
                                                    <td><Link className="btn btn-sm btn-primary" to={`/employees-salaries/${employeeSalary._id}/payslip`}>Generate Slip</Link></td>
                                                    <td className="text-right">
                                                        <div className="dropdown dropdown-action">
                                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a className="dropdown-item" href="edit-salary.html"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                                                <a style={{"cursor": "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,employeeSalary._id)}><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {deleteEmployeeSalaryModal()}
            </Layout>
        </div>
    )
}

export default EmployeeSalary
