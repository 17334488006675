let myState = {
    providentFunds: [],
    providentFund: {}
}

const providentFundReducer = (state = myState, action) => {
    switch (action.type) {

        case 'GET_PROVIDENT_FUNDS':
            return {
                ...state,
                providentFunds: action.payload
            }
        case 'GET_PROVIDENT_FUND':
            return {
                ...state,
                providentFund: action.payload
            }
        case 'DELETE_PROVIDENT_FUND':
            return {
                ...state,
                providentFunds: state.providentFunds.filter(providentFund => providentFund._id !== action.payload)
            }
        case 'ADD_PROVIDENT_FUND':
            return {
                ...state,
                providentFunds: [...state.providentFunds, action.payload]
            }
        case 'UPDATE_PROVIDENT_FUND':
            return {
                ...state,
                providentFunds: state.providentFunds.map(providentFund => providentFund._id === action.payload._id ? (providentFund = action.payload) : providentFund)
            }
        default:{
            return state
        }
    }
}

export default providentFundReducer;
