import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'
import { getExpenses, removeExpense, updateExpenseStatus } from '../../../actions/expenseActions';
import Layout from '../../../core/Layout'

import { Modal, Button } from 'react-bootstrap'

import { isAuthenticated } from '../../../auth/helpers';

import moment from 'moment';

const ListExpenses = () => {

    const expenses = useSelector(state => state.expense.expenses);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [deletedExpenseId, setDeletedExpenseId] = useState('') 

    const [searchData, setSearchData] = useState('')
    let [limit, setLimit] = useState(3);

    useEffect(() => {
        dispatch(getExpenses({limit}))
    }, [])

     // search panel
    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.id]: e.target.value })
    }

    const searchSubmit = (e) => {
        e.preventDefault();
        
        if(searchData && searchData.itemName != "" && searchData.purchasedBy != "" && searchData.paidBy != "" && searchData.fromDate != "" && searchData.toDate != ""){
            
            dispatch(getExpenses({search: searchData}))
        
        }else{
            dispatch(getExpenses({limit:3, skip:0, size:3}))
                setLimit(3)
        }   
    }

    const handleStatus = (e,expense) => {
        const { user, token } = isAuthenticated();
        dispatch(updateExpenseStatus(user, token, expense, e.target.dataset.status))
    }


    // delete
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedExpenseId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {

        const { user, token } = isAuthenticated();

        dispatch(removeExpense(deletedExpenseId, user, token))
        setShow(false);
    }

    const deleteExpenseModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Tax?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }

    // load patients
    const loadMore = () => {
        limit = limit + 2

        dispatch(getExpenses({limit, search: searchData}))
        setLimit(limit)   
    }

    const buttonToLoadMore = () => {
        return (
            expenses.length > 0 && expenses.length >= limit && (
                <div className="text-center mt-2">
                    <button onClick={loadMore} className="btn btn-outline-success">Load More</button>
                </div>
            )   
        )
    } 

    return (
        <div>
            <Layout>
                <div className="row">
                    <div className="col-sm-8 col-5">
                        <h4 className="page-title">Expenses</h4>
                    </div>
                    <div className="col-sm-4 col-7 text-right m-b-30">
                        <Link to="/expenses/create" className="btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Add Expense</Link>
                    </div>
                </div>
                
                <form onSubmit={searchSubmit}>
                    <div className="row filter-row">   
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group form-focus">
                                <label className="focus-label">Item Name</label>
                                <input id="itemName" onChange={handleChange} type="text" className="form-control floating"/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group">
                                <select id="purchasedBy" style={{ height: 'calc(2.25rem + 14px)' }} onChange={handleChange} className="form-control">
                                    <option value="">Purchased By</option>
                                    <option>Loren Gatlin</option>
                                    <option>Tarah Shropshire</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group">
                                <select id="paidBy" style={{ height: 'calc(2.25rem + 14px)' }} onChange={handleChange} className="form-control">
                                    <option value="">Paid By</option>
                                    <option> Cash </option>
                                    <option> Cheque </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group form-focus">
                                <label className="focus-label">From</label>
                                <input id="fromDate" onChange={handleChange} className="form-control" type="date"/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <div className="form-group form-focus">
                                <label className="focus-label">To</label>
                                <input id="toDate" onChange={handleChange} className="form-control" type="date"/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                            <button type="submit" className="btn btn-success btn-block"> Search </button>
                        </div>
                    </div>
                </form>

                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped custom-table m-b-0">
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Purchase From</th>
                                        <th>Purchase Date</th>
                                        <th>Purchased By</th>
                                        <th>Amount</th>
                                        <th>Paid By</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { expenses && expenses.map((expense, i) => (
                                        <tr key={i}>
                                            <td>
                                                <strong>{expense.itemName}</strong>
                                            </td>
                                            <td>{expense.purchaseFrom}</td>
                                            <td>{moment(expense.purchaseDate).format('DD MMM YYYY') }</td>
                                            <td>{expense.purchasedBy}</td>
                                            <td>${expense.amount}</td>
                                            <td>{expense.paidBy}</td>
                                            {expense.status == "Approved" ? (
                                                <td className="text-center">
                                                    <div className="dropdown action-label">
                                                        <a className="custom-badge status-green dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                            Approved
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a onClick={e => handleStatus(e,expense)} data-status="Approved" className="dropdown-item" href="#">Approved</a>
                                                            <a onClick={e => handleStatus(e,expense)} data-status="Pending" className="dropdown-item" href="#">Pending</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            ) 
                                            : (
                                                <td className="text-center">
                                                    <div className="dropdown action-label">
                                                        <a className="custom-badge status-red dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">
                                                            Pending
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a onClick={e => handleStatus(e,expense)} data-status="Pending" className="dropdown-item" href="#">Pending</a>
                                                            <a onClick={e => handleStatus(e,expense)} data-status="Approved" className="dropdown-item" href="#">Approved</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                            
                                            <td className="text-right">
                                                <div className="dropdown dropdown-action">
                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to={`/expenses/${expense._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link>
                                                        <a style={{cursor: "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,expense._id)} ><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                   
                                </tbody>
                            </table>
                        </div>
                        {buttonToLoadMore()}
                    </div>
                </div>
                {deleteExpenseModal()}
            </Layout>
        </div>
    )
}

export default ListExpenses
