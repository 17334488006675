
import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";


export const getExpenses = (params) => dispatch => {

    fetch(`${API_URL}/expenses`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_EXPENSES',
            payload: res.expenses
        })
    })
    .catch(err => console.error(err))

}

export const getExpense = id => dispatch => {

    fetch(`${API_URL}/expenses/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_EXPENSE',
            payload: res.expense
        })
    })
    .catch(err => console.error(err))
}

export const addExpense = (expense, user, token, history) => dispatch => {

    fetch(`${API_URL}/expenses/create/${user._id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: expense
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'ADD_EXPENSE',
                payload: res.expense
            })

            toastr.success(`expense ${res.expense.itemName} created !`, 'New expense' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/expenses');

        }
    })
    .catch(err => console.error(err))
}

export const removeExpense = (id, user, token, history) => dispatch => {

    fetch(`${API_URL}/expenses/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'DELETE_EXPENSE',
                payload: id
            })

            toastr.success(`expense deleted !`, 'expense deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/expenses');

        }
    })
    .catch(err => console.error(err))
}

export const updateExpense = (expense, user, token, expense_id, history) => dispatch => {

    fetch(`${API_URL}/expenses/${expense_id}/${user._id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: expense
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_EXPENSE',
                payload: res.expense
            })

            toastr.success(`expense ${res.expense.itemName} updated !`, 'expense updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/expenses');
        }
    })
    .catch(err => console.error(err))
}


export const updateExpenseStatus = (user, token, expense, status) => dispatch => {

    fetch(`${API_URL}/expenses/${expense._id}/status/${user._id}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({status})
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_EXPENSE',
                payload: res.expense
            })

            toastr.success(`expense ${res.expense.itemName} updated !`, 'expense updated' ,
            {"positionClass": "toast-bottom-left",})
        }
    })
    .catch(err => console.error(err))
}

