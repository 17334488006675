import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addProvidentFund } from '../../../actions/providentFundActions';

import Layout from '../../../core/Layout'
import providentFundReducer from '../../../reducers/providentFundReducer';

import { isAuthenticated } from '../../../auth/helpers';

const AddProvidentFund = (props) => {
    
    const dispatch = useDispatch();

    const [providentFund, setProvidentFund] = useState({
        employee: '',
        type:'Fixed Amount',
        employeeShareAmount:'0',
        organizationShareAmount: '0',
        employeeSharePercentage:'',
        organizationSharePercentage: '',
        description: ''
    })

    const handleChange = e => {
        setProvidentFund({...providentFund, [e.target.name]: e.target.value})
    }


    const submit = e => {
        e.preventDefault();

        const { user, token } = isAuthenticated();
        dispatch(addProvidentFund(providentFundReducer, user, token, props.history))
    }
    
    return (
        <div>
            <Layout>
            <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <h4 class="page-title">Add Provident Fund</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <form onSubmit={submit}>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee Name</label>
                                        <select name="employee" onChange={handleChange} class="form-control select">
                                            <option value="3">John Doe (FT-0001)</option>
                                            <option value="23">Richard Miles (FT-0002)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Provident Fund Type</label>
                                        <select name="type" onChange={handleChange} class="form-control select">
                                            <option value="Fixed Amount" selected="">Fixed Amount</option>
                                            <option value="Percentage of Basic Salary">Percentage of Basic Salary</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="show-fixed-amount">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Employee Share (Amount)</label>
                                                    <input name="employeeShareAmount" onChange={handleChange} class="form-control" type="text"/>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Organization Share (Amount)</label>
                                                    <input name="organizationShareAmount" onChange={handleChange} class="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="show-basic-salary">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Employee Share (%)</label>
                                                    <input name="employeeSharePercentage" onChange={handleChange} class="form-control" type="text"/>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Organization Share (%)</label>
                                                    <input name="organizationSharePercentage" onChange={handleChange} class="form-control" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <textarea name="description" onChange={handleChange} class="form-control" rows="4" cols="50"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="m-t-20 text-center">
                                <button class="btn btn-primary submit-btn">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default AddProvidentFund
