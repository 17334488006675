import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom'
import { getHolidays, removeHoliday } from '../../../actions/holidayActions';
import Layout from '../../../core/Layout'
import { Modal, Button } from 'react-bootstrap'

import { isAuthenticated } from '../../../auth/helpers';

import moment from 'moment';

import Spinner from '../../../core/Spinner';
import Page500 from '../../../core/Page500';

const ListHolidays = () => {

    const dispatch = useDispatch();
    const holidays = useSelector(state => state.holiday.holidays);
    const loading = useSelector(state => state.holiday.loading);
    const error = useSelector(state => state.holiday.error);

    const [show, setShow] = useState(false);
    const [deletedHolidayId, setDeletedHolidayId] = useState('') 

    useEffect(() => {
        dispatch(getHolidays())
    }, [])

    // delete
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedHolidayId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {

        const { user, token } = isAuthenticated();

        dispatch(removeHoliday(deletedHolidayId, user, token))
        setShow(false);
    }

    const deleteHolidayModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Holiday?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }

    if (error) {
        return <Page500 />;
    }


    return (
        <Fragment>
            {loading ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                        <div className="row">
                            <div className="col-sm-5 col-5">
                                <h4 className="page-title">Holidays {new Date().getFullYear()}</h4>
                            </div>
                            <div className="col-sm-7 col-7 text-right m-b-30">
                                <Link to="/holidays/add" className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Add Holiday</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {holidays && holidays.length == 0 && ( <div>No data available in holidays table</div> )}

                                {holidays && holidays.length > 0 && (
                                    <div className="table-responsive">
                                        <table className="table table-striped custom-table m-b-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title </th>
                                                    <th>Holiday Date</th>
                                                    <th>Day</th>
                                                    <th>Number of days</th>
                                                    <th className="text-right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { holidays && holidays.map((holiday,key) => (
                                                    <tr key={key} className={moment().diff(holiday.holidayDate, 'days') < 0 ? "holiday-upcoming" : "holiday-completed"}>
                                                        <td>{key+1}</td>
                                                        <td>{holiday.title}</td>
                                                        <td>{moment(holiday.holidayDate).format('DD MMM YYYY') }</td>
                                                        <td>{moment(holiday.holidayDate).format('dddd') }</td>
                                                        <td>{holiday.nbDays}</td>
                                                        <td className="text-right">
                                                            {moment().diff(holiday.holidayDate, 'days') < 0 && (
                                                                <div className="dropdown dropdown-action">
                                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <li><Link className="dropdown-item" to={`/holidays/${holiday._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link></li>
                                                                        <li><a style={{cursor: "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,holiday._id)}><i className="fa fa-trash-o m-r-5"></i> Delete</a></li>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )) }

                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        {deleteHolidayModal()}
                    </Layout>
                </Fragment>)
                
            }  
        </Fragment>
    )
}

export default ListHolidays
