let myState = {
    taxs: [],
    tax: {}
}

const taxReducer = (state = myState, action) => {
    switch (action.type) {

        case 'GET_TAXS':
            return {
                ...state,
                taxs: action.payload
            }
        case 'GET_TAX':
            return {
                ...state,
                tax: action.payload
            }
        case 'DELETE_TAX':
            return {
                ...state,
                taxs: state.taxs.filter(tax => tax._id !== action.payload)
            }
        case 'ADD_TAX':
            return {
                ...state,
                taxs: [...state.taxs, action.payload]
            }
        case 'UPDATE_TAX':
            return {
                ...state,
                taxs: state.taxs.map(tax => tax._id === action.payload._id ? (tax = action.payload) : tax)
            }
        default:{
            return state
        }
    }
}

export default taxReducer;
