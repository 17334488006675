import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import Page500 from '../core/Page500';
import Spinner from '../core/Spinner';
import { API_URL } from '../config';
import Layout from '../core/Layout';
import { isAuthenticated } from '../auth/helpers';
import { USER_LOADED, USER_LOADED_FAILED } from '../actions/types';
import { updateProfile } from '../actions/authActions';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

const initialState = {
    firstname: '',
    lastname: '',
    birthday: '',
    gender: '',
    email: '',
    password:'',
    confirmPassword:'',
    address: '',
    country: 'Morocco',
    city: '',
    postalcode: '',
    phone: '',
    photo: '',
    role: '',
    speciality: '',
    status: '',
    joiningDate: '',
    remainingLeave: 1.5,
    cin: ''
}
const EditProfile = ({history}) => {

    const { user } = isAuthenticated();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const error = useSelector(state => state.auth.error);

    const [upProfile, setUpProfile] = useState(initialState);
    const [formData, setFormData] = useState(new FormData());

    useEffect(() => {
        if(user) getProfile(user._id);
    }, []);

    const getProfile = async id => {

        try {
            const res = await axios(`${API_URL}/users/${id}`);
            const user = res.data.user;
            
            if(!loading && user) {
                const userData = { ...initialState };
                for(const key in user){
                    if(key in userData && key !== 'photo') {
                        userData[key] = user[key];
                        formData.set(key, user[key])
                    }
                }
                setUpProfile(userData);
            }

            
            dispatch({
                type: USER_LOADED,
                payload: user
            });
            
        } catch (err) {
            dispatch({type: USER_LOADED_FAILED});
        }
    }

    const handleChange = e => {

        formData.set(e.target.name, e.target.value);
        
        setUpProfile({...upProfile, [e.target.name]: e.target.value});
        
    }

    const submit = e => {

        e.preventDefault();

        const { user, token } = isAuthenticated();

        dispatch(updateProfile(formData, user, token, user._id, history))  
    }

    if (error) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {user.isEmpty || loading ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="page-title">Edit Profile</h4>
                        </div>
                    </div>
                    <form onSubmit={submit}>
                        <div className="card-box">
                            <h3 className="card-title">Basic Informations</h3>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-img-wrap">
                                        <img className="inline-block" src={upProfile ? `${API_URL}/users/photo/${user._id}` : ''} alt="user"/>
                                        <div className="fileupload btn">
                                            <span className="btn-text">edit</span>
                                            <input className="upload" type="file"/>
                                        </div>
                                    </div>
                                    <div className="profile-basic">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input onChange={handleChange} type="text" className="form-control" name="firstname" placeholder="First Name" value={upProfile ? upProfile.firstname : ''}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input onChange={handleChange} type="text" className="form-control" name="lastname"placeholder="Last Name"  value={upProfile ? upProfile.lastname : ''}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input onChange={handleChange} className="form-control" name="birthday" type="date" value={moment(upProfile.birthday).format('YYYY-MM-DD')}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group select-focus">
                                                    <select name="gender" className="select form-control">
                                                        <option value={`male ${upProfile && upProfile.gender == 'Male' ? 'selected' : ''}`}>Male</option>
                                                        <option value={`female ${upProfile && upProfile.gender == 'Female' ? 'selected' : ''}`}>Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-box">
                            <h3 className="card-title">Contact Informations</h3>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input onChange={handleChange} type="text" className="form-control" placeholder="Address" name="address" value={upProfile ? upProfile.address : ''}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input onChange={handleChange} type="text" className="form-control" placeholder="State" name="state" value={upProfile ? upProfile.state : ''}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input onChange={handleChange} type="text" className="form-control" placeholder="Country" name="country" value={upProfile ? upProfile.country : ''}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input onChange={handleChange} type="text" className="form-control" placeholder="PostalCode" name="postalCode" value={upProfile ? upProfile.postalcode : ''}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input onChange={handleChange} type="text" className="form-control" placeholder="Phone" name="phone" value={upProfile ? upProfile.phone : ''}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className="card-box">
                            <h3 className="card-title">Education Informations</h3>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Institution</label>
                                        <input type="text" className="form-control floating" value="Oxford University"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Subject</label>
                                        <input type="text" className="form-control floating" value="Computer Science"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Starting Date</label>
                                        <div className="cal-icon">
                                            <input type="text" className="form-control floating datetimepicker" value="01/06/2002"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Complete Date</label>
                                        <div className="cal-icon">
                                            <input type="text" className="form-control floating datetimepicker" value="31/05/2006"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Degree</label>
                                        <input type="text" className="form-control floating" value="BE Computer Science"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Grade</label>
                                        <input type="text" className="form-control floating" value="Grade A"/>
                                    </div>
                                </div>
                            </div>
                            <div className="add-more">
                                <a href="#" className="btn btn-primary"><i className="fa fa-plus"></i> Add More Institute</a>
                            </div>
                        </div>
                        <div className="card-box">
                            <h3 className="card-title">Experience Informations</h3>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Company Name</label>
                                        <input type="text" className="form-control floating" value="Digital Devlopment Inc"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Location</label>
                                        <input type="text" className="form-control floating" value="United States"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Job Position</label>
                                        <input type="text" className="form-control floating" value="Web Developer"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Period From</label>
                                        <div className="cal-icon">
                                            <input type="text" className="form-control floating datetimepicker" value="01/07/2007"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Period To</label>
                                        <div className="cal-icon">
                                            <input type="text" className="form-control floating datetimepicker" value="08/06/2018"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-more">
                                <a href="#" className="btn btn-primary"><i className="fa fa-plus"></i> Add More Experience</a>
                            </div>
                        </div>
                         */}
                        <div className="text-center m-t-20">
                            <Link className="mr-2" to="/profile">
                                <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                            </Link>
                            <button className="btn btn-primary submit-btn" type="submit">Save</button>
                        </div>
                    </form>
                    </Layout>
                </Fragment>)
            }  
        </Fragment>
    )
}

export default EditProfile;
