import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { removePatient, getPatients } from '../../actions/patientActions';

import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { isAuthenticated } from './../../auth/helpers';

import Layout from '../../core/Layout';
import Spinner from '../../core/Spinner';
import Page500 from '../../core/Page500';


const ListPatients = () => {

    const patients = useSelector(state => state.patient.patients);
    const loading = useSelector(state => state.patient.loading);
    const error = useSelector(state => state.patient.error);
    let dispatch = useDispatch();
    

    const [show, setShow] = useState(false);
    const [deletedPatientId, setDeletedPatientId] = useState('') ;
    const [searchData, setSearchData] = useState('');
    let [limit, setLimit] = useState(10);

    
    useEffect(() => {
        dispatch(getPatients({limit}))
    }, [dispatch, limit]);

    
    // search panel
    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.id]: e.target.value });
    }
    
    const searchSubmit = (e) => {
        e.preventDefault();
        
        if(searchData && searchData.name !== "" && searchData.cin !== ""){
           
            dispatch(getPatients({search: searchData}));
        
        }else{
            dispatch(getPatients({limit:10}));
                setLimit(10);
        }
    }

    
    // delete modal
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedPatientId(id);
        setShow(true);;
    }
    
    const submitDeleteModal = () => {

        const { user, token } = isAuthenticated();

        dispatch(removePatient(deletedPatientId, user, token));
        setShow(false);
    }

    const deletePatientModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Voulez-vous vraiment supprimer ce patient ?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Supprimer
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }


     // load patients
    const loadMore = () => {

        limit= limit+6;

        dispatch(getPatients({limit, search: searchData}));
        setLimit(limit);
    }


    const buttonToLoadMore = () => {
        return (
            patients.length > 0 && patients.length >= limit && (
                <div className="text-center mt-2">
                    <button onClick={loadMore} className="btn btn-outline-success">Charger plus</button>
                </div>
            )   
        )
    } 
 
    if (error) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading ? (<Spinner />) : (

                <Fragment>
                    <Layout>
                        <div className="row">
                            <div className="col-sm-4 col-3">
                                <h4 className="page-title">Patients</h4>
                            </div>
                            <div className="col-sm-8 col-9 text-right m-b-20">
                                <Link to="/patients/create" className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Ajouter un patient</Link>
                            </div>
                        </div>
                        
                        <form onSubmit={searchSubmit}>
                            <div className="row filter-row">
                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group form-focus">
                                        <label htmlFor="name" className="focus-label">Nom du patient</label>
                                        <input id="name" onChange={handleChange} type="text" className="form-control floating"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group form-focus">
                                        <label htmlFor="cin" className="focus-label">CIN du patient</label>
                                        <input id="cin" onChange={handleChange} type="text" className="form-control floating"/>
                                    </div>
                                </div>
                                
                                <div className="col-sm-6 col-md-3 offset-3">
                                    <button type="submit" className="btn btn-success btn-block"> Rechercher </button>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-border table-striped custom-table m-b-0">
                                        <thead>
                                            <tr>
                                                <th>Nom complet</th>
                                                <th>CIN</th>
                                                <th>Age</th>
                                                <th>Géo</th>
                                                <th>Téléphone</th>
                                                <th>Statut</th>
                                                <th className="text-right">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { patients && patients.map((patient, i) => (
                
                                                <tr key={i}>
                                                    <td>
                                                        <img width="28" height="28" src="./../assets/img/user.jpg" alt="avatar" className="rounded-circle m-r-5"/>{patient.firstname} {patient.lastname} 
                                                    </td>
                                                    <td>{patient.cin}</td>
                                                    <td>{moment().diff(patient.birthday, 'years')} ans</td>
                                                    <td>{patient.city} - {patient.country}</td>
                                                    <td>{patient.phone}</td>
                                                    <td><span className={patient.status && patient.status === 'Active' ? 'custom-badge status-green' : 'custom-badge status-red'}>{patient.status}</span></td>
                                                    <td className="text-right">
                                                        <div className="dropdown dropdown-action">
                                                            <a href="#!" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <Link className="dropdown-item" to={`/patients/${patient._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link>
                                                                <a href="#!" style={{"cursor": "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,patient._id)}><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )) }
                                        
                                        </tbody>
                                    </table>
                                </div>
                                {buttonToLoadMore()}
                            </div>
                        </div>
                        {deletePatientModal()}
                    </Layout>
                </Fragment>
            )}
        </Fragment>
    )
}



export default ListPatients;
