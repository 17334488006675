import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import toastr from 'toastr';
import "toastr/build/toastr.css";
import { isAuthenticated } from '../auth/helpers';

import { API_URL } from './../config'

//import { login } from '../actions/authActions';

const root = document.getElementById("root");
root.classList.add("account-wrapper");

const Signin = ({history}) => {

    //const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    //const dispatch = useDispatch();

    const [user, setUser] = useState({
        email:'',
        password:''
    })

    const { email, password } = user;

    const handleChange = e => {
        setUser({...user, [e.target.id]: e.target.value})
    }

    const submitSignin = e => {

        e.preventDefault();

        //dispatch(login(email, password, history));

        fetch(`${API_URL}/signin`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
        })
        .then(res => res.json())
        .then(res => {
            if(res.error){
                toastr.warning(res.error, 'Please check form !', 
                {"positionClass": "toast-bottom-left",})
            }else{
                toastr.info('User is authenticated successfully !', 'Welcome' ,
                {"positionClass": "toast-bottom-left",})

                localStorage.setItem('jwt_info', JSON.stringify(res))

                history.push('/dashboard');
            }

        })
        .catch(err => toastr.error(err, 'Server error !', 
        {"positionClass": "toast-bottom-left",}))

    }

    const form = () => (

        <form onSubmit={submitSignin} className="form-signin">
            <div className="account-logo">
                <img src="assets/img/logo-dark.png" alt="Preadmin"/>
            </div>
            <div className="form-group">
                <label className="text-muted" htmlFor="email">Email</label>
                <input id="email" onChange={handleChange} type="email" autoFocus className="form-control" required/>
            </div>
            <div className="form-group">
                <label className="text-muted" htmlFor="password">Password</label>
                <input id="password" onChange={handleChange}  type="password" className="form-control" required/>
            </div>
            <div className="form-group text-center">
                <button type="submit" className="btn btn-primary account-btn">Login</button>
            </div>
        </form>

    )

   if (isAuthenticated()) {
        return <Redirect to="/dashboard" />;
   }
    
    return (
            <div className="account-page">
                <div className="account-center">
                    <div className="account-box">
                        {form()}
                    </div>
                </div>
            </div>
    )
}

export default Signin
