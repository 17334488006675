import {
    ADD_DOCTOR,
    DOCTOR_ERROR,
    GET_DOCTORS,
    GET_DOCTOR,
    DELETE_DOCTOR,
    UPDATE_DOCTOR,
    CLEAR_DOCTOR
} from './../actions/types';

let myState = {
    doctors: [],
    doctor: null,
    loading: true,
    error: {}
};

const doctorReducer = (state = myState, action) => {

    const { type, payload } = action;

    switch (type) {

        case GET_DOCTORS:
            return {
                ...state,
                doctors: payload,
                loading: false,
                error: false
            }
        case GET_DOCTOR:
            return {
                ...state,
                doctor: payload,
                loading: false,
                error: false
            }
        case DELETE_DOCTOR:
            return {
                ...state,
                doctors: state.doctors.filter(doctor => doctor._id !== payload),
                loading: false,
                error: false
            }
        case ADD_DOCTOR:
            return {
                ...state,
                doctors: [payload, ...state.doctors],
                loading: false,
                error: false
            }
        case DOCTOR_ERROR:
            return {
                ...state,
                doctors: payload,
                loading: false,
                error: true
            }
        case UPDATE_DOCTOR:
            return {
                ...state,
                doctors: state.doctors.map(doctor => doctor._id === payload._id ? (doctor = payload) : doctor),
                loading: false,
                error: false
            }
        case CLEAR_DOCTOR:
            return {
                ...state,
                doctor: null
            }
        default:{
            return state
        }
    }
}

export default doctorReducer;
