import {
    ADD_ROLE,
    ROLE_ERROR,
    GET_ROLES,
    GET_ROLE,
    DELETE_ROLE,
    UPDATE_ROLE,
    CLEAR_ROLE,
    GET_ROLE_CONNECTED_USER
} from './../actions/types';

let myState = {
    roles: [],
    role: null,
    roleConnectedUser: null,
    loading: true,
    error: false
}

const roleReducer = (state = myState, action) => {
    switch (action.type) {

        case GET_ROLES:
            return {
                ...state,
                roles: action.payload,
                loading: false,
                error: false
            }
        case GET_ROLE:
            return {
                ...state,
                role: action.payload,
                loading: false,
                error: false
            }
        case GET_ROLE_CONNECTED_USER:
            return {
                ...state,
                roleConnectedUser: action.payload,
                loading: false,
                error: false
            }
        case DELETE_ROLE:
            return {
                ...state,
                roles: state.roles.filter(role => role._id !== action.payload),
                loading: false,
                error: false
            }
        case ADD_ROLE:
            return {
                ...state,
                roles: [...state.roles, action.payload],
                loading: false,
                error: false
            }
        case UPDATE_ROLE:
            return {
                ...state,
                roles: state.roles.map(role => role._id === action.payload._id ? (role = action.payload) : role),
                loading: false,
                error: false
            }
        case CLEAR_ROLE:
            return {
                ...state,
                role: null
            }
        case ROLE_ERROR:
            return {
                ...state,
                role: null,
                loading: false,
                error: true
            }
        default:{
            return state
        }
    }
}

export default roleReducer;
