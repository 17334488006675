import {
    ADD_HOLIDAY,
    HOLIDAY_ERROR,
    GET_HOLIDAYS,
    GET_HOLIDAY,
    DELETE_HOLIDAY,
    UPDATE_HOLIDAY,
    CLEAR_HOLIDAY
} from './../actions/types';

let myState = {
    holidays: [],
    holiday: null,
    loading: true,
    error: false
}

const holidayReducer = (state = myState, action) => {

    const { type, payload } = action;
    
    switch (type) {
        case GET_HOLIDAYS:
            return {
                ...state,
                holidays: payload,
                loading: false,
                error: false
            }
        case GET_HOLIDAY:
            return {
                ...state,
                holiday: payload,
                loading: false,
                error: false
            }
        case DELETE_HOLIDAY:
            return {
                ...state,
                holidays: state.holidays.filter(holiday => holiday._id !== payload),
                loading: false,
                error: false
            }
        case ADD_HOLIDAY:
            return {
                ...state,
                holidays: [...state.holidays, payload],
                loading: false,
                error: false
            }
        case UPDATE_HOLIDAY:
            return {
                ...state,
                holidays: state.holidays.map(holiday => holiday._id === payload._id ? (holiday = payload) : holiday),
                loading: false,
                error: false
            }
        case HOLIDAY_ERROR: 
            return {
                ...state,
                loading: false,
                error: true
            }
        case CLEAR_HOLIDAY:
            return {
                ...state,
                holiday: null
            }
        default:{
            return state
        }
    }
}

export default holidayReducer;
