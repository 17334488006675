import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { API_URL } from './../config'
import toastr from 'toastr';
import "toastr/build/toastr.css";
import { isAuthenticated } from '../auth/helpers';

const Header = ({history}) => {

    const signout = () => {

        fetch(`${API_URL}/signout`)
            .then(() => {
                toastr.info('User signout !', 'Next Time' ,
                {"positionClass": "toast-bottom-left",})
            })
            .catch(err => toastr.error(err, 'Server error !', 
                {"positionClass": "toast-bottom-left",}))
    
            localStorage.removeItem('jwt_info')
    
            history.push('/signin')
    }

    const { user } = isAuthenticated();

    return (
        
        <div className="header">
            <div className="header-left">
                <Link to="/dashboard" className="logo">
                    <img src="./../assets/img/Logo_Hospital.png" width="35" height="35" alt=""/>
                </Link>
            </div>
            <a id="mobile_btn" className="mobile_btn float-left" href="#sidebar">
                <i className="fa fa-bars"></i>
            </a>
            <ul className="nav user-menu float-right">
                <li className="nav-item dropdown has-arrow">
                    <a href="#" className="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                        <span className="user-img">
                            <img className="rounded-circle" src="./../assets/img/user.jpg" width="24" alt="Admin"/>
                            <span className="status online"></span>
                        </span>
                        <span> {user.role}</span>
                    </a>
                    <div className="dropdown-menu">
                        <Link to="/profile" className="dropdown-item" >My Profile</Link>
                        <Link to="/settings/company" className="dropdown-item"> Settings</Link>
                        <span style={{ cursor: "pointer" }} onClick={signout} className="dropdown-item" href="#!">Logout</span>
                    </div>
                </li>
            </ul>
            <div className="dropdown mobile-user-menu float-right">
                <a href="#!" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link to="/profile" className="dropdown-item" >My Profile</Link>
                    <Link to="/settings/company" className="dropdown-item"> Settings</Link>
                    <a style={{ cursor: "pointer" }} onClick={signout} className="dropdown-item" href="#!">Logout</a>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Header)
