import {API_URL} from './../config';
import axios from 'axios';

import toastr from 'toastr';
import "toastr/build/toastr.css";

import { push } from 'react-router-redux';

import { 
    ADD_PATIENT, 
    PATIENT_ERROR, 
    GET_PATIENT, 
    GET_PATIENTS,
    CLEAR_PATIENT,
    DELETE_PATIENT,
    UPDATE_PATIENT
} from './types';

export const getPatients = params => async dispatch => {

    /* const { search } = params;

    if(!search){
        dispatch({ type: CLEAR_PATIENT });
    } */

    //dispatch({ type: CLEAR_PATIENT });
    
    try {
        const config = {
            "Accept": "application/json",
            "Content-Type": "applicat ion/json",
        }
        
        const res = await axios.post(`${API_URL}/patients`, params, config);
        
        dispatch({
           type: GET_PATIENTS,
           payload: res.data.patients
        });
    }catch (err) {
        dispatch({type: PATIENT_ERROR});
    }

}

export const getPatient = (id) => dispatch => {

    fetch(`${API_URL}/patients/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "ContentType": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_PATIENT',
            payload: res.patient
        })
    })
    .catch(err => console.error(err))

}

export const addPatient = (patient, user, token, history) => dispatch => {

    fetch(`${API_URL}/patients/create/${user._id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: patient
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'ADD_PATIENT',
                payload: res.patient
            })

            toastr.success(`Patient ${res.patient.firstname} created !`, 'New Patient' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/patients');

        }
    })
    .catch(err => console.error(err))
}

export const removePatient = (id, user, token) => dispatch => {

    fetch(`${API_URL}/patients/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'DELETE_PATIENT',
                payload: id
            })

            toastr.success(`Patient deleted !`, 'Patient deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

        }
    })
    .catch(err => console.error(err))

}

export const updatePatient = (formData, user, token, patient_id, history) => dispatch => {

    fetch(`${API_URL}/patients/${patient_id}/${user._id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: formData
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_PATIENT',
                payload: res.patient
            })

            toastr.success(`patient ${res.patient.firstname} updated !`, 'Patient updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/patients');
        }
    })
    .catch(err => console.error(err))
}


