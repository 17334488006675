import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutSettings from '../../core/LayoutSettings';

import { isAuthenticated } from './../../auth/helpers';
import { GET_SETTINGS, SETTINGS_ERROR } from '../../actions/types';

import { API_URL } from '../../config';
import { updateSettings } from '../../actions/settingsActions';
import Spinner from '../../core/Spinner';
import { Link } from 'react-router-dom';

const SalarySettings = (props) => {

    const [formData, setFormData] = useState(new FormData());

    let dispatch = useDispatch();
    const loading = useSelector(state => state.settings.loading);

    const [settings, setSettings] = useState({
        CNSSBase: 0,
        CNSSRate: 0,
        CIMRRate: 0,
        CNSSAMORate: 0,
        assGroupRate: 0,
        medicalChargesRate: 0,
        seniorityBonusBase: 0,
        seniorityBonusRate: 0,
        suppSalary125Base: 0,
        suppSalary125Rate: 0,
        suppSalary150Base: 0,
        suppSalary150Rate: 0,
        transporAndLodgementBonus: 0,
        feastBonus: 0
    })

    const getSettings = async () => {

        try {
            const res = await fetch(`${API_URL}/settings`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            });
            const res_1 = await res.json();
            setSettings(res_1.settings[0]);
            formData.set('CNSSBase', res_1.settings[0].CNSSBase || 0);
            formData.set('CNSSRate', res_1.settings[0].CNSSRate || 0);
            formData.set('CIMRRate', res_1.settings[0].CIMRRate || 0);
            formData.set('CNSSAMORate', res_1.settings[0].CNSSAMORate || 0);
            formData.set('assGroupRate', res_1.settings[0].assGroupRate || 0);
            formData.set('medicalChargesRate', res_1.settings[0].medicalChargesRate || 0);
            formData.set('seniorityBonusBase', res_1.settings[0].seniorityBonusBase || 0);
            formData.set('seniorityBonusRate', res_1.settings[0].seniorityBonusRate || 0);
            formData.set('suppSalary125Base', res_1.settings[0].suppSalary125Base || 0);
            formData.set('suppSalary125Rate', res_1.settings[0].suppSalary125Rate || 0);
            formData.set('suppSalary150Base', res_1.settings[0].suppSalary150Base || 0);
            formData.set('suppSalary150Rate', res_1.settings[0].suppSalary150Rate || 0);
            formData.set('transporAndLodgementBonus', res_1.settings[0].transporAndLodgementBonus || 0);
            formData.set('feastBonus', res_1.settings[0].feastBonus || 0);

            dispatch({
                type: GET_SETTINGS,
                payload: res_1.settings[0]
            });

        } catch (err) {
            dispatch({type: SETTINGS_ERROR});
        }
    }

    useEffect(() => {
        getSettings()
    }, [])


    const handleChange = e => {
        formData.set(e.target.name, e.target.value)
        setSettings({...settings, [e.target.name]: e.target.value})
    }

    const submit = e => {
        e.preventDefault()
        
        const { user, token } = isAuthenticated();

        dispatch(updateSettings(formData, user, token, props.history))
    }

    return (
        <Fragment>
            {loading ? (<Spinner />) : (

                <Fragment>
                    <LayoutSettings>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <form onSubmit={submit}>
                            <h4 className="text-primary">Déductions</h4>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Base CNSS ({settings ? settings.currencySymbole : ''})</label>
                                        <input name="CNSSBase" value={settings ? settings.CNSSBase : 0} onChange={handleChange} type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>CNSS (%)</label>
                                        <input name="CNSSRate" value={settings ? settings.CNSSRate : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>CIMR (%)</label>
                                        <input name="CIMRRate" value={settings ? settings.CIMRRate : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>CNSS-AMO (%)</label>
                                        <input name="CNSSAMORate" value={settings ? settings.CNSSAMORate : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label title="Groupe et Association">Groupe. Ass (%)</label>
                                        <input name="assGroupRate" value={settings ? settings.assGroupRate : 0} onChange={handleChange} type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Frais médicaux (%)</label>
                                        <input name="medicalChargesRate" value={settings ? settings.medicalChargesRate : 0} onChange={handleChange} type="text" className="form-control"/>
                                    </div>
                                </div>
                            </div>

                            <h4 className="text-primary">Gains</h4>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Base de prime d'ancienneté ({settings ? settings.currencySymbole : ''})</label>
                                        <input name="seniorityBonusBase" value={settings ? settings.seniorityBonusBase : 0} onChange={handleChange} type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Taux de prime d'ancienneté (%)</label>
                                        <input name="seniorityBonusRate" value={settings ? settings.seniorityBonusRate : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Bonus de transport et d'hébergement ({settings ? settings.currencySymbole : ''})</label>
                                        <input name="transporAndLodgementBonus" value={settings ? settings.transporAndLodgementBonus : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Bonus de fête ({settings ? settings.currencySymbole : ''})</label>
                                        <input name="feastBonus" value={settings ? settings.feastBonus : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Base du salaire supp125%</label>
                                        <input name="suppSalary125Base" value={settings ? settings.suppSalary125Base : 0} onChange={handleChange} type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Taux du salaire supp125% ({settings ? settings.currencySymbole : ''})</label>
                                        <input name="suppSalary125Rate" value={settings ? settings.suppSalary125Rate : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Base du salaire supp150% </label>
                                        <input name="suppSalary150Base" value={settings ? settings.suppSalary150Base : 0} onChange={handleChange} type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Taux du salaire supp150%  ({settings ? settings.currencySymbole : ''})</label>
                                        <input name="suppSalary150Rate" value={settings ? settings.suppSalary150Rate : 0} onChange={handleChange} className="form-control" type="text"/>
                                    </div>
                                </div>
                            </div>

							<div className="row">
								<div className="col-sm-12 text-center m-t-20">
                                    <Link to="/dashboard" className="btn btn-secondary submit-btn mr-2">Annuler</Link>
                                    <button type="submit" className="btn btn-primary submit-btn">Enregistrer</button>
								</div>
							</div>
                        </form>
                    </div>
                </div>
            </LayoutSettings>
                </Fragment>
            )}
        </Fragment>
    )
}


export default SalarySettings
