import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "./helpers";

const AdminRoute = ({ component:Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() && isAuthenticated().user.role == "Administrateur" ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/dashboard"
                    }}
                />
            )
        }
    />
);

export default AdminRoute;
