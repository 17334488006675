
import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";

import { 
    ADD_INVOICE,
    INVOICE_ERROR,
    GET_INVOICES,
    GET_INVOICE,
    DELETE_INVOICE,
    UPDATE_INVOICE,
    CLEAR_INVOICE
} from './types';
import { addAppointmentInvoice, updateAppointmentStatus } from './appointmentActions';


export const getInvoices = () => dispatch => {

    dispatch({type: CLEAR_INVOICE});
    
    fetch(`${API_URL}/invoices`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_INVOICES,
            payload: res.invoices
        })
    })
    .catch(err => dispatch({type: INVOICE_ERROR}));

}

export const getInvoice = id => dispatch => {

    fetch(`${API_URL}/invoices/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_INVOICE,
            payload: res.invoice
        })
    })
    .catch(err => dispatch({type: INVOICE_ERROR}));

}

export const addInvoice = (invoice, admin_id, token, history) => dispatch => {

    fetch(`${API_URL}/invoices/create/${admin_id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(invoice)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: ADD_INVOICE,
                payload: res.invoice
            })

            dispatch(updateAppointmentStatus(admin_id, token, invoice.appointment, 'Closed'));
            dispatch(addAppointmentInvoice(admin_id, token, invoice.appointment, res.invoice._id));

            toastr.success(`invoice created !`, 'New invoice' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/invoices');

        }
    })
    .catch(err => dispatch({type: INVOICE_ERROR}));
}

export const removeInvoice = (invoice_id, admin_id, token) => dispatch => {

    fetch(`${API_URL}/invoices/${invoice_id}/${admin_id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: DELETE_INVOICE,
                payload: invoice_id
            })

            toastr.success(`Invoice deleted !`, 'Invoice deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

        }
    })
    .catch(err => dispatch({type: INVOICE_ERROR}));

}

export const updateInvoice = (invoice, user_id, token, invoice_id, history) => dispatch => {

    fetch(`${API_URL}/invoices/${invoice_id}/${user_id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(invoice)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_INVOICE,
                payload: res.invoice
            })

            toastr.success(`Invoice updated !`, 'Invoice updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/invoices');
        }
    })
    .catch(err => dispatch({type: INVOICE_ERROR}));
}


