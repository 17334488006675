import { 
    ADD_DEPARTMENT, 
    CLEAR_DEPARTMENT, 
    DELETE_DEPARTMENT, 
    GET_DEPARTMENT, 
    GET_DEPARTMENTS, 
    UPDATE_DEPARTMENT 
} from "../actions/types"

let myState = {
    departments: [],
    department: null,
    loading: true,
    error: false
}

const departmentReducer = (state = myState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {
        case GET_DEPARTMENTS:
            return {
                ...state,
                departments: payload,
                loading: false,
                error: false
            }
        case GET_DEPARTMENT:
            return {
                ...state,
                department: payload,
                loading: false,
                error: false
            }
        case DELETE_DEPARTMENT:
            return {
                ...state,
                departments: state.departments.filter(department => department._id !== payload),
                loading: false,
                error: false
            }
        case ADD_DEPARTMENT:
            return {
                ...state,
                departments: [...state.departments, payload],
                loading: false,
                error: false
            }
        case UPDATE_DEPARTMENT:
            return {
                ...state,
                departments: state.departments.map(department => department._id === payload._id ? (department = payload) : department),
                loading: false,
                error: false
            }
        case CLEAR_DEPARTMENT:
            return {
                ...state,
                department: null
            }
        default:{
            return state
        }
    }
}

export default departmentReducer;
