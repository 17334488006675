
import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";


export const getEmployeesSalaries = (params) => dispatch => {

    fetch(`${API_URL}/employeesSalaries`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_EMPLOYEES_SALARIES',
            payload: res.employeesSalaries
        })
    })
    .catch(err => console.error(err))

}

export const getEmployeeSalary = id => dispatch => {

    fetch(`${API_URL}/employeesSalaries/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_EMPLOYEE_SALARY',
            payload: res.employeeSalary
        })
    })
    .catch(err => console.error(err))

}

export const addEmployeeSalary = (employeeSalary, user, token, history) => dispatch => {

    fetch(`${API_URL}/employeesSalaries/create/${user._id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(employeeSalary)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'ADD_EMPLOYEE_SALARY',
                payload: res.employeeSalary
            })

            toastr.success(`employee Salary created !`, 'New employee salary' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/employees-salaries');

        }
    })
    .catch(err => console.error(err))
}

export const removeEmployeeSalary = (id, user, token, history) => dispatch => {

    fetch(`${API_URL}/employeesSalaries/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'DELETE_EMPLOYEE_SALARY',
                payload: id
            })

            toastr.success(`Employee Salary deleted !`, 'Employee Salary deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/employees-salaries');

        }
    })
    .catch(err => console.error(err))

}

export const updateEmployeeSalary = (employeeSalary, user, token, history) => dispatch => {

    fetch(`${API_URL}/employeesSalaries/${employeeSalary._id}/${user._id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(employeeSalary)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_EMPLOYEE_SALARY',
                payload: res.employeeSalary
            })

            toastr.success(`Employee Salary updated !`, 'Employee Salary updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/employeesSalaries');
        }
    })
    .catch(err => console.error(err))
}
