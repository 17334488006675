import {
    ADD_APPOINTMENT,
    APPOINTMENT_ERROR,
    GET_APPOINTMENTS,
    GET_APPOINTMENT,
    DELETE_APPOINTMENT,
    UPDATE_APPOINTMENT,
    CLEAR_APPOINTMENT
} from './../actions/types';

let myState = {
    appointments: [],
    appointment: null,
    loading: true,
    error: false
}

const appointmentReducer = (state = myState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {

        case GET_APPOINTMENTS:
            return {
                ...state,
                appointments: payload,
                loading: false,
                error: false
            }
        case GET_APPOINTMENT:
            return {
                ...state,
                appointment: payload,
                loading: false,
                error: false
            }
        case DELETE_APPOINTMENT:
            return {
                ...state,
                appointments: state.appointments.filter(appointment => appointment._id !== payload),
                loading: false,
                error: false
            }
        case ADD_APPOINTMENT:
            return {
                ...state,
                appointments: [...state.appointments, payload],
                loading: false,
                error: false
            }
        case UPDATE_APPOINTMENT:
            return {
                ...state,
                appointments: state.appointments.map(appointment => appointment._id === payload._id ? (appointment = payload) : appointment),
                loading: false,
                error: false
            }
        case CLEAR_APPOINTMENT:
            return {
                ...state,
                appointment: null
            }
        case APPOINTMENT_ERROR:
            return {
                ...state,
                appointment: null,
                loading: false,
                error: true
            }
        default:{
            return state
        }
    }
}

export default appointmentReducer;
