import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Layout from '../../core/Layout';
import Spinner from '../../core/Spinner';
import { getAppointments } from '../../actions/appointmentActions';
import Page500 from '../../core/Page500';


const CalendarAppointment = () => {
    const dispatch = useDispatch();
    const appointments = useSelector(state => state.appointment.appointments);
    const loading = useSelector(state => state.appointment.loading);
    const error = useSelector(state => state.appointment.error);

    let events = [];

    useEffect(() => {
        dispatch(getAppointments());
    }, [])
    
    events = appointments.map(appointment => {
        return { title: `${appointment.department.name} - Dr.${appointment.doctor.firstname}` , date: appointment.appointmentDate, color: appointment.department.color}
    })
    
    if (error == true) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading ? ( <Spinner/>) : (
                <Fragment>
                    <Layout>
                        <FullCalendar
                            plugins={[ dayGridPlugin]}
                            events={events}
                            />
                    </Layout>
                </Fragment>
            )}
        </Fragment>
    )
  }

  export default CalendarAppointment;