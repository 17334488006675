import React, { Component } from 'react';
import PropTypes from 'prop-types';


class LineItem extends Component {

  render = () => {

    const { index, name, description, quantity, price } = this.props

    return (
      <tr>
        <td>
            {index + 1}
        </td>
        <td>
            <input  className="form-control" name="name" type="text" value={name} onChange={this.props.changeHandler(index)} />
        </td>
        <td>
            <input  className="form-control" name="description" type="text" value={description} onChange={this.props.changeHandler(index)} />
        </td>
        <td>
            <input  className="form-control" name="price" type="number" step="1" min="0" max="99999990" value={price} onChange={this.props.changeHandler(index)} />
        </td>
        <td>
            <input  className="form-control" name="quantity" type="number" step="1" min="0" max="99999990" value={quantity} onChange={this.props.changeHandler(index)} />
        </td>
        <td>
            {quantity * price}
        </td>
        <td>
            {index && (index + 1) > 0 ? (
                <button type="button" className="btn btn-danger" onClick={this.props.deleteHandler(index)}>
                    <i className="fa fa-trash" size="1.25em" />
                </button>
            ) : null}
        </td>
      </tr>
    )
  }
}

export default LineItem

LineItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


