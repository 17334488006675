import React, {useState, useEffect} from 'react'
import Layout from '../../../core/Layout'

import { useSelector, useDispatch } from 'react-redux';

import { getEmployees, removeEmployee } from '../../../actions/employeeActions';
import { API_URL } from '../../../config';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

import { isAuthenticated } from '../../../auth/helpers';
import { Fragment } from 'react';

import Spinner from '../../../core/Spinner';
import Page500 from '../../../core/Page500';

const ListEmployees = () => {

    const employees = useSelector(state => state.employee.employees);
    const loading = useSelector(state => state.employee.loading);
    const error = useSelector(state => state.employee.error);
    let dispatch = useDispatch()
    
    const [show, setShow] = useState(false);
    const [deletedEmployeeId, setDeletedEmployeeId] = useState('') ;
    const [searchData, setSearchData] = useState('');
    let [limit, setLimit] = useState(8);


    useEffect(() => {
        dispatch(getEmployees({limit}))
    }, [])


    // search 
    const handleChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value })
    }

    const searchSubmit = (e) => {
        e.preventDefault();
    
        if(searchData && searchData.name != "" && searchData.cin != "" && searchData.role != ""){
            
            dispatch(getEmployees({search: searchData}));
        
        }else{
            dispatch(getEmployees({limit:8, skip:0}))
                setLimit(8);
        }    
    }

    // delete
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedEmployeeId(id)
        setShow(true);
    }
    
    const submitDeleteModal = () => {

        const { user, token } = isAuthenticated();

        dispatch(removeEmployee(deletedEmployeeId, user, token))
        setShow(false);
    }

    const deleteEmployeeModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Are you sure want to delete this Employee?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }

    const classNameRole = (role) => {
        
        switch (role) {
            
            case 'Administrateur':
                return 'status-red'
                break;
            
            case 'Doctor':
                return 'status-blue'
                break;
            
            case 'Nurse':
                return 'status-green'
                break;
            
            default:
                return 'status-grey'
                break;
        }
      }


    // load button
    const loadMore = () => {

        limit = limit + 5

        dispatch(getEmployees({limit, search: searchData}))
        setLimit(limit)
        
    }


    const buttonToLoadMore = () => {
        return (
            employees.length > 0 && employees.length >= limit && (
                <div className="text-center mt-2">
                    <button onClick={loadMore} className="btn btn-outline-success">Load More</button>
                </div>
            )   
        )
    } 

    if (error == true) {
        return <Page500 />;
    }

    
    return (
        <Fragment>
            {loading ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                        <div className="row">
                            <div className="col-sm-4 col-3">
                                <h4 className="page-title">Employees</h4>
                            </div>
                            <div className="col-sm-8 col-9 text-right m-b-20">
                                <Link to="/employees/create" className="btn btn-primary float-right btn-rounded"><i className="fa fa-plus"></i> Add Employee</Link>
                            </div>
                        </div>
                        
                        <form onSubmit={searchSubmit}>
                            <div className="row filter-row">
                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Employee Name</label>
                                        <input name="name" onChange={handleChange} type="text" className="form-control floating"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group form-focus">
                                        <label className="focus-label">Employee CIN</label>
                                        <input name="cin" onChange={handleChange} type="text" className="form-control floating"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group">
                                        <select style={{ height: 'calc(2.25rem + 14px)' }} name="role" onChange={handleChange} className="form-control">
                                            <option value="">Select Role</option>
                                            <option>Admin</option>
                                            <option>Accountant</option>
                                            <option>Doctor</option>
                                            <option>Laboratorist</option>
                                            <option>Nurse</option>
                                            <option>Pharmacist</option>
                                            <option>Receptionist</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3">
                                    <button type="submit" className="btn btn-success btn-block"> Search </button>
                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table id="example" className="table table-striped table-bordered dt-responsive nowrap" cellSpacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th style={{width:'20%'}}>Name</th>
                                                <th>Employee CIN</th>
                                                <th>Mobile</th>
                                                <th>Email</th>
                                                <th>Join Date</th>
                                                <th>Role</th>
                                                <th className="text-right">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            { employees && employees.map((employee,i) => (

                                                <tr key={i}>
                                                    <td>
                                                    <img width="28" height="28" src="./../assets/img/user.jpg" className="rounded-circle m-r-5"/>
                                                        <h2>{employee.firstname} {employee.lastname}</h2>
                                                    </td>
                                                    <td>{employee.cin}</td>
                                                    <td>{employee.phone}</td>
                                                    <td /* style={{width:'30%'}} */>{employee.email}</td>
                                                    <td>{moment(employee.joiningDate).format('DD/MM/YYYY') }</td>
                                                    <td>
                                                        <span className={`custom-badge ${classNameRole(employee.role.name)}`}>{employee.role.name}</span>
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="dropdown dropdown-action">
                                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <Link className="dropdown-item" to={`/employees/${employee._id}/update`}><i className="fa fa-pencil m-r-5"></i> Edit</Link>
                                                                <a style={{"cursor": "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,employee._id)}><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                                {buttonToLoadMore()}
                            </div>
                        </div>
                        {deleteEmployeeModal()}
                    </Layout>
                </Fragment>)
            }  
        </Fragment>
    )
}

export default ListEmployees
