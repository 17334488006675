import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../core/Layout';
import LineItems from './LineItems';
import uuidv4 from 'react-uuid';
import moment from 'moment';

import { addPrescription, updatePrescription } from '../../actions/prescriptionActions';
import { isAuthenticated } from '../../auth/helpers';
import { API_URL } from '../../config';
import { APPOINTMENT_ERROR, GET_APPOINTMENT, GET_PRESCRIPTION, PRESCRIPTION_ERROR } from '../../actions/types';
import Page500 from '../../core/Page500';
import Spinner from '../../core/Spinner';

const initialState = {
    appointment: '',
    email: '',
    otherInformation: '',
    lineItems: [
        {
            id: 'initial',      // react-beautiful-dnd unique key
            name: '',
        },
    ]
}

const FormPrescription = ({history, match}) => {

    const prescriptionId = match.params.id;
    const appointmentId = match.params.appointmentId;

    const dispatch = useDispatch();
    const taxs = useSelector(state => state.tax.taxs);
    const loading = useSelector(state => state.appointment.loading);
    const error = useSelector(state => state.appointment.error);

    const [prescription, setPrescription] = useState(initialState);
    const [appointment, setAppointment] = useState('');
        
    useEffect(() => {
        if(appointmentId) getAppointment(appointmentId);
        if(prescriptionId) getPrescription(prescriptionId);
        
        
    }, [appointmentId, prescriptionId])
    
    const getAppointment = id => {
        return fetch(`${API_URL}/appointments/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setPrescription({
                ...prescription,
                appointment: res.appointment._id,
                email: res.appointment.patient.email
            });

            setAppointment({
                patient: res.appointment.patient.firstname+" "+res.appointment.patient.lastname,
                department: res.appointment.department.name,
            })

            dispatch({
                type: GET_APPOINTMENT,
                payload: res.appointment
            })
        })
        .catch(err => dispatch({type: APPOINTMENT_ERROR}));
        
    }

    const getPrescription = id => {
        return fetch(`${API_URL}/prescriptions/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setPrescription(res.prescription);
            dispatch({
                type: GET_PRESCRIPTION,
                payload: res.prescription
            })
        })
        .catch(err => dispatch({type: PRESCRIPTION_ERROR}));
        
    }
    
    const handlePrescriptionChange = e => setPrescription({...prescription, [e.target.name]: e.target.value});

    const handleLineItemChange = (elementIndex) => (e) => {
        let lineItems = prescription.lineItems.map((item, i) => {
        if (elementIndex !== i) return item
        return {...item, [e.target.name]: e.target.value}
        })
        setPrescription({...prescription, lineItems})
    }

    const handleAddLineItem = e => {
        setPrescription({
            ...prescription,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: prescription.lineItems.concat(
            [{ id: uuidv4(), name: '', description: '', quantity: 0, price: 0.00 }]
        )
        })
    }

    const handleRemoveLineItem = elementIndex => e => {
        setPrescription({
        lineItems: prescription.lineItems.filter((item, i) => {
            return elementIndex !== i
        })
        })
    }


    const formatCurrency = amount => {
        return (new Intl.NumberFormat(prescription.locale, {
        //style: 'currency',
        currency: prescription.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
        }).format(amount))
    } 

  
    const calcLineItemsTotal = () => {
        return (prescription.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price)), 0)) || 0;
    }

    const calcTaxTotal = () => {
        return (calcLineItemsTotal() * (prescription.taxRate / 100)) || 0;
    }

    const calcDiscountTotal = () => {
        return (calcLineItemsTotal() * (prescription.discountRate / 100)) || 0;
    }

    const calcGrandTotal = () => {
        return (calcLineItemsTotal() + calcTaxTotal() - calcDiscountTotal()) || 0;
    }

    prescription.total = calcLineItemsTotal();
    prescription.grandTotal = calcGrandTotal();
    prescription.taxAmount = calcTaxTotal();
    prescription.discountAmount = calcDiscountTotal();

    if(prescription.status == 'Sent') prescription.paidBy = "";
    if(prescription.status !== 'Partially Paid') prescription.rest = 0;

    const submit = (e) => {
        e.preventDefault();
        const { user, token } = isAuthenticated();
        
        prescriptionId ? dispatch(updatePrescription(prescription, user._id, token, prescriptionId, history)) : dispatch(addPrescription(prescription, user._id, token, history));
    }

    if(error) return <Page500/>;

    return (
        <Fragment>
            {loading ? (<Spinner />) : (
			
			<Fragment>
                <Layout>
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="page-title">{prescriptionId ? "Update" : "Create"} Prescription</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={submit} >
                                <div className="row">
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Patient <span className="text-danger">*</span></label>
                                            <input readOnly className="form-control" type="text" value={appointment.patient} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Department <span className="text-danger">*</span></label>
                                            <input readOnly className="form-control" type="text" value={appointment.department} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input onChange={handlePrescriptionChange} name="email" className="form-control" type="email" value={prescription.email} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Prescription date <span className="text-danger">*</span></label>
                                            <input onChange={handlePrescriptionChange} name="prescriptionDate" className="form-control" type="date" value={prescription.prescriptionDate ? moment(prescription.prescriptionDate).format('YYYY-MM-DD') : ''} />
                                        </div>
                                    </div>
                                    
                                </div>
                                <LineItems
                                    items={prescription.lineItems}
                                    currencyFormatter={formatCurrency}
                                    addHandler={handleAddLineItem}
                                    changeHandler={handleLineItemChange}
                                    deleteHandler={handleRemoveLineItem}
                                />

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Other Information</label>
                                            <textarea onChange={handlePrescriptionChange} name="otherInformation" className="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center m-t-20">
                                    {prescriptionId ? (
                                        <Fragment>
                                            <button type="button" className="btn btn-grey submit-btn m-r-10">Return</button>
                                            <button type="submit" className="btn btn-warning submit-btn">Update</button>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <button type="button" className="btn btn-grey submit-btn m-r-10">Return</button>
                                            <button type="submit" className="btn btn-primary submit-btn">Save</button>
                                        </Fragment>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </Layout>
            </Fragment>)
		}  
        </Fragment>
    )
  }

export default FormPrescription;
