import {
    ADD_PRESCRIPTION,
    PRESCRIPTION_ERROR,
    GET_PRESCRIPTIONS,
    GET_PRESCRIPTION,
    DELETE_PRESCRIPTION,
    UPDATE_PRESCRIPTION,
    CLEAR_PRESCRIPTION
} from './../actions/types';

let myState = {
    prescriptions: [],
    prescription: null,
    loading: true,
    error: false
}

const prescriptionReducer = (state = myState, action) => {

    const { type, payload } = action;

    switch (type) {

        case GET_PRESCRIPTIONS:
            return {
                ...state,
                prescriptions: payload,
                loading: false,
                error: false
            }
        case GET_PRESCRIPTION:
            return {
                ...state,
                prescription: payload,
                loading: false,
                error: false
            }
        case DELETE_PRESCRIPTION:
            return {
                ...state,
                prescriptions: state.prescriptions.filter(prescription => prescription._id !== payload),
                loading: false,
                error: false
            }
        case ADD_PRESCRIPTION:
            return {
                ...state,
                prescriptions: [...state.prescriptions, payload],
                loading: false,
                error: false
            }
        case UPDATE_PRESCRIPTION:
            return {
                ...state,
                prescriptions: state.prescriptions.map(prescription => prescription._id === payload._id ? (prescription = payload) : prescription),
                loading: false,
                error: false
            }
        case PRESCRIPTION_ERROR: 
            return {
                ...state,
                loading: false,
                error: true
            }
        case CLEAR_PRESCRIPTION:
            return {
                ...state,
                prescription: null
            }
        default:{
            return state
        }
    }
}

export default prescriptionReducer;
