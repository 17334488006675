import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Pdf from "react-to-pdf";

import Layout from '../../../core/Layout';
import Page500 from '../../../core/Page500';

import Spinner from '../../../core/Spinner';
import { API_URL } from '../../../config';
import { GET_INVOICE, INVOICE_ERROR } from '../../../actions/types';


const ref = React.createRef();

const ViewInvoice = ({match}) => {

    const dispatch = useDispatch();
    const [invoice, setInvoice] = useState({});
    const [settings, setSettings] = useState({});
    const loading = useSelector(state => state.invoice.loading);
    const error = useSelector(state => state.invoice.error);
    const invoiceId = match.params.id;

    useEffect(() => {
        dispatch(getSettings());
        if(invoiceId) dispatch(getInvoice(invoiceId));
    }, [invoiceId]);


    const getSettings = () => dispatch => {

        fetch(`${API_URL}/settings`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {

            setSettings(res.settings[0]);
            
            dispatch({
                type: 'GET_SETTINGS',
                payload: res.settings[0]
            })
        })
        .catch(err => console.error(err))
    }

    const getInvoice = id => dispatch => {

        fetch(`${API_URL}/invoices/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setInvoice(res.invoice);
            dispatch({
                type: GET_INVOICE,
                payload: res.invoice
            })
        })
        .catch(err => dispatch({type: INVOICE_ERROR}));
    
    }


    const {
        _id,
        email,
        patientAddress,
        billingAddress,
        invoiceDate,
        taxRate,
        taxAmount,
        discountRate,
        discountAmount,
        dueDate,
        currency,
        grandTotal,
        total,
        status,
        otherInformation,
        lineItems
    } = invoice;

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const ref = React.createRef();

    if(error) return <Page500/>;

    return (
        <Fragment>
            {!invoice || loading ? (<Spinner />) : (
			
			<Fragment>
                <Layout>
                        <div className="row">
                            <div className="col-sm-5 col-4">
                                <h4 className="page-title">Invoice</h4>
                            </div>
                            <div className="col-sm-7 col-8 text-right m-b-30">
                                <div className="btn-group btn-group-sm">
                                    <Pdf targetRef={ref} filename="invoice.pdf" >
                                        {({ toPdf }) => <button className="btn btn-white" onClick={toPdf}>PDF</button>}
                                    </Pdf>
                                    
                                    <button onClick={handlePrint} className="btn btn-white"><i className="fa fa-print fa-lg"></i> Print</button>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div ref={componentRef} className="card">
                                    <div className="card-body" ref={ref} >
                                        <div className="row custom-invoice">
                                            <div className="col-6 col-sm-6 m-b-20">
                                                <img src="assets/img/logo-dark.png" className="inv-logo" alt=""/>
                                                <ul className="list-unstyled">
                                                    <li>{settings ? settings.companyName : ""}</li>
                                                    <li>{settings ? settings.address: ""},</li>
                                                    <li>{settings ? settings.state: ""}, {settings ? settings.city: ""}, {settings ? settings.postalCode: ""}</li>
                                                    <li>GST No:</li>
                                                </ul>
                                            </div>
                                            <div className="col-6 col-sm-6 m-b-20">
                                                <div className="invoice-details">
                                                    <h3 className="text-uppercase">Invoice #{invoice ? _id : ""}</h3>
                                                    <ul className="list-unstyled">
                                                        <li>Date: <span>{invoice ? moment(invoiceDate).format('MMMM DD, YYYY') : ""}</span></li>
                                                        <li>Due date: <span>{invoice ? moment(dueDate).format('MMMM DD, YYYY') : ""}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-6 m-b-20">
                                                <h5>Invoice to:</h5>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h5><strong>{invoice && invoice.appointment ? invoice.appointment.patient.firstname+' '+invoice.appointment.patient.lastname : ""}</strong></h5>
                                                    </li>
                                                    <li><span>{invoice ? patientAddress : ""}</span></li>
                                                    <li>5754 Airport Rd</li>
                                                    <li>{invoice && invoice.patient? invoice.patient.phone : ""}</li>
                                                    <li><a href="#">{invoice ? email : ""}</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 col-lg-6 m-b-20">
                                                <div className="invoices-view">
                                                    <span className="text-muted">Payment Details:</span>
                                                    <ul className="list-unstyled invoice-payment-details">
                                                        <li>
                                                            <h5>Total Due: <span className="text-right">{invoice ? grandTotal : ""} {settings ? settings.currencyCode : ""}</span></h5>
                                                        </li>
                                                        <li>Bank name: <span>Profit Bank Europe</span></li>
                                                        <li>Country: <span>United Kingdom</span></li>
                                                        <li>City: <span>London E1 8BF</span></li>
                                                        <li>Address: <span>3 Goodman Street</span></li>
                                                        <li>IBAN: <span>KFH37784028476740</span></li>
                                                        <li>SWIFT code: <span>BPT4E</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>ITEM</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>UNIT COST</th>
                                                        <th>QUANTITY</th>
                                                        <th>TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {invoice && invoice.lineItems && invoice.lineItems.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{index+1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.description ? item.description : "-"}</td>
                                                            <td>{item.price} MAD</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.price * item.quantity} MAD</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div>
                                            <div className="row invoice-payment">
                                                <div className="col-sm-7">
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="m-b-20">
                                                        <h6>Total due</h6>
                                                        <div className="table-responsive no-border">
                                                            <table className="table m-b-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Subtotal:</th>
                                                                        <td className="text-right">{invoice ? total : 0} MAD</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Tax: <span className="text-regular">({invoice ? taxRate : 0}%)</span></th>
                                                                        <td className="text-right">{invoice ? taxAmount : 0} MAD</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Discount: <span className="text-regular">({invoice ? discountRate : 0}%)</span></th>
                                                                        <td className="text-right">{invoice ? discountAmount : 0} MAD</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Total:</th>
                                                                        <td className="text-right text-primary">
                                                                            <h5>{invoice ? grandTotal : 0} MAD</h5>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="invoice-info">
                                                <h5>Other information</h5>
                                                <p className="text-muted">{invoice ? otherInformation : "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Layout>
            </Fragment>)
		    }  
        </Fragment>
    )
}

export default ViewInvoice
