import React from 'react'
import { Link } from 'react-router-dom';

const Page500 = () => {
    return (
      <div class="error-box">
          <h1>500</h1>
          <h3><i class="fa fa-warning"></i> Oops! Something went wrong</h3>
          <p>The page you requested was not found.</p>
          <Link to="/dashboard" class="btn btn-primary go-home">Go to Home</Link>
      </div>
    );
  };

export default Page500
