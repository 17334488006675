import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutSettings from '../../core/LayoutSettings';

import { isAuthenticated } from './../../auth/helpers';
import { GET_SETTINGS, SETTINGS_ERROR } from '../../actions/types';
import { API_URL } from '../../config';

import { updateSettings } from '../../actions/settingsActions';
import { Link } from 'react-router-dom';
import Spinner from '../../core/Spinner';

const ThemeSettings = (props) => {

    const [formData, setFormData] = useState(new FormData());

    let dispatch = useDispatch();
    const loading = useSelector(state => state.settings.loading);

    const [settings, setSettings] = useState({
        websiteName: '',
        lightLogo: '',
        favicon: '',
    })

    const getSettings = async () => {

        try {
            const res = await fetch(`${API_URL}/settings`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            });

            const res_1 = await res.json();

            setSettings(res_1.settings[0]);
            
            formData.set('websiteName', res_1.settings[0].websiteName);

            dispatch({
                type: GET_SETTINGS,
                payload: res_1.settings[0]
            });

        } catch (err) {
            dispatch({type: SETTINGS_ERROR});
        }
    }

    useEffect(() => {
        getSettings()
    }, [])


    const handleChange = e => {
        let key = ""
        let value = ""
        
        if(e.target.type == 'file'){
            key = e.target.name
            value = e.target.files[0]  
        }
        else{
            key = e.target.name
            value = e.target.value
        } 
        
        formData.set(key, value)

        setSettings({...settings, [key]: value})
    }

    const submit = e => {
        e.preventDefault()
        
        const { user, token } = isAuthenticated();

        dispatch(updateSettings(formData, user, token, props.history))
    }


    return (
        <Fragment>
            {loading ? (<Spinner />) : (

                <Fragment>
                    <LayoutSettings>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <form onSubmit={submit}>
                                    <h4 className="page-title">Paramètres des thèmes</h4>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Nom du site Web</label>
                                        <div className="col-lg-9">
                                            <input name="websiteName" className="form-control" value={settings ? settings.websiteName : ''} type="text" onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Logo léger</label>
                                        <div className="col-lg-7">
                                            <input className="form-control" type="file" name="lightLogo" onChange={handleChange}/>
                                            <span className="form-text text-muted">La taille d'image recommandée est de 40 px x 40 px</span>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="img-thumbnail float-right"><img src={`${API_URL}/settings/lightLogo`} alt="" width="40" height="40"/></div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Favicon</label>
                                        <div className="col-lg-7">
                                            <input className="form-control" type="file" name="favicon" onChange={handleChange}/>
                                            <span className="form-text text-muted">La taille d'image recommandée est 16 px x 16 px</span>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="settings-image img-thumbnail float-right">
                                                <img src={`${API_URL}/settings/favicon`} className="img-fluid" width="16" height="16" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-t-20 text-center">
                                        <Link to="/dashboard" className="btn btn-secondary submit-btn mr-2">Annuler</Link>
                                        <button type="submit" className="btn btn-primary submit-btn">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </LayoutSettings>
                </Fragment>
            )}
        </Fragment>
    )
}


export default ThemeSettings
