import {
    GET_SETTINGS,
    UPDATE_SETTINGS,
    SETTINGS_ERROR
} from './../actions/types';

let myState = {
    settings: [],
    loading: true,
    error: false
}

const settingReducer = (state = myState, action) => {

    switch (action.type) {

        case GET_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                loading: false,
                error: false
            }
        case UPDATE_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                loading: false,
                error: false
            }
        case SETTINGS_ERROR:
            return {
                ...state,
                settings: null,
                loading: false,
                error: true
            }
        default:{
            return state
        }
    }
}

export default settingReducer;
