
import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";
import { GET_SETTINGS, SETTINGS_ERROR, UPDATE_SETTINGS } from './types';


export const getSettings = () => dispatch => {

    return fetch(`${API_URL}/settings`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_SETTINGS,
            payload: res.settings[0]
        })
    })
    .catch(err => dispatch({type: SETTINGS_ERROR}))
}

export const updateSettings = (formData, user, token, history) => dispatch => {

    fetch(`${API_URL}/settings/${user._id}`, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: formData
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_SETTINGS,
                payload: res.settings
            })

            toastr.success(`Paramètres mis à jour !`, 'Paramètres' ,
            {"positionClass": "toast-bottom-left",})

            

            /* history.push('/dashboard'); */
        }
    })
    .catch(err => {
        toastr.warning(`Erreur de serveur. veuillez réessayer plus tard !`, 'Paramètres' ,
            {"positionClass": "toast-bottom-left",})

        dispatch({type: SETTINGS_ERROR})
    })
}
