import api from './api';

const setAuthToken = token => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}` ;
    localStorage.setItem('jwt_info', token);
  } else {
    delete api.defaults.headers.common['Authorization'];
    localStorage.removeItem('jwt_info');
  }
};

export default setAuthToken;
