import React, { Children, Fragment } from 'react'
import Header from './Header';
import Sidebar from './Sidebar';

function Layout({children}) {
    return (
        <Fragment>
            <Header/>
            <Sidebar/>
            <div className="page-wrapper">
                <div className="content">
                    {children}
                </div>  
            </div>
        </Fragment>
    )
}

export default Layout
