import {API_URL} from './../config';
import axios from 'axios';

import toastr from 'toastr';
import "toastr/build/toastr.css";

import { push } from 'react-router-redux';
import { 
    ADD_DOCTOR, 
    DOCTOR_ERROR, 
    GET_DOCTOR, 
    GET_DOCTORS,
    CLEAR_DOCTOR,
    DELETE_DOCTOR,
    UPDATE_DOCTOR
} from './types';


export const getDoctors = params => async dispatch => {

    /* if(params.search != undefined){
        const { search : { speciality, name } } = params;
    
        if((!speciality || speciality === '') && !name){
            dispatch({ type: CLEAR_DOCTOR });
        }
    } */

    dispatch({ type: CLEAR_DOCTOR });
    
    try {
        const config = {
            "Accept": "application/json",
            "Content-Type": "applicat ion/json",
        }
        
        const res = await axios.post(`${API_URL}/users`, params, config);
        
        dispatch({
           type: GET_DOCTORS,
           payload: res.data.users
        });
    }catch (err) {
        dispatch({type: DOCTOR_ERROR});
    }

}

export const getDoctor = id => async dispatch => {

    try {
        const res = await axios(`${API_URL}/users/${id}`);
        
        dispatch({
            type: GET_DOCTOR,
            payload: res.data.user
        });
        
    }catch (err) {
        dispatch({type: DOCTOR_ERROR});
    }
}

export const getDoctorPhoto = (id) => dispatch => {

    fetch(`${API_URL}/users/photo/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "ContentType": "application/json"
        }
    })
    .then(res => res.json())
    .then(res => {
        return res
    })
    .catch(err => console.error(err))
}

export const addDoctor = (formData, user, token, history) => dispatch => {

    fetch(`${API_URL}/users/create/${user._id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: formData
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            let doctor = res.user;

            dispatch({
                type: ADD_DOCTOR,
                payload: doctor
            })

            toastr.success(`Doctor ${doctor.firstname} created !`, 'New Doctor' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/doctors');

        }
    })
    .catch(error => {
        console.error(error)
        dispatch({
            type: DOCTOR_ERROR,
            payload: { msg: error.response.statusText, status: error.response.status }
        })
    })
}

export const removeDoctor = (id, admin, token) => dispatch => {

    fetch(`${API_URL}/users/${id}/${admin._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: DELETE_DOCTOR,
                payload: id
            })
            
            toastr.success(`Doctor deleted !`, 'Doctor deleted' ,
            {"positionClass": "toast-bottom-left",})
            
        }
    })
    .catch(err => console.error(err))

}

export const updateDoctor = (formData, user_id, token, doctor_id, history) => dispatch => {

    fetch(`${API_URL}/users/${doctor_id}/${user_id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: formData
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_DOCTOR,
                payload: res.user
            })

            toastr.success(`Doctor ${res.user.firstname} UPDATED !`, 'Doctor' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/doctors');
        }
    })
    .catch(err => console.error(err))
}