
import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";


export const getTaxs = () => dispatch => {

    fetch(`${API_URL}/taxs`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_TAXS',
            payload: res.taxs
        })
    })
    .catch(err => console.error(err))

}

export const getTax = id => dispatch => {

    fetch(`${API_URL}/taxs/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_TAX',
            payload: res.tax
        })
    })
    .catch(err => console.error(err))

}

export const addTax = (tax, user, token, history) => dispatch => {

    fetch(`${API_URL}/taxs/create/${user._id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(tax)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'ADD_TAX',
                payload: res.tax
            })

            toastr.success(`tax ${res.tax.name} created !`, 'New tax' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/taxs');

        }
    })
    .catch(err => console.error(err))
}

export const removeTax = (id, user, token, history) => dispatch => {

    fetch(`${API_URL}/taxs/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'DELETE_TAX',
                payload: id
            })

            toastr.success(`tax deleted !`, 'tax deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/taxs');

        }
    })
    .catch(err => console.error(err))

}

export const updateTax = (tax, user, token, history) => dispatch => {

    fetch(`${API_URL}/taxs/${tax._id}/${user._id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(tax)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_TAX',
                payload: res.tax
            })

            toastr.success(`tax ${res.tax.name} updated !`, 'tax updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/taxs');
        }
    })
    .catch(err => console.error(err))
}

export const updateTaxStatus = (user, token, tax, status) => dispatch => {

    fetch(`${API_URL}/taxs/${tax._id}/status/${user._id}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({status})
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_TAX',
                payload: res.tax
            })

            toastr.success(`tax ${res.tax.name} updated !`, 'tax updated' ,
            {"positionClass": "toast-bottom-left",})
        }
    })
    .catch(err => console.error(err))
}


