import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { isAuthenticated } from '../auth/helpers';
import { getRoleByNameConnectedUser } from '../actions/roleActions';

const isActive = (history, path) => {

    if(history.location.pathname.substring(0, path.length) === path){
      return "active";
        
    }else{
        return "";
    }

}

const SidebarSettings = ({history}) => {

    const { user } = isAuthenticated();
    const dispatch = useDispatch();
    const roleConnectedUser = useSelector(state => state.role.roleConnectedUser);


    useEffect(() => dispatch(getRoleByNameConnectedUser({search: { name: user.role }})), []);

    return (
        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div className="sidebar-menu">
                    <ul>
                        <li>
                            <Link to="/dashboard"><i className="fa fa-home back-icon"></i> Tableau de bord</Link>
                        </li>
                        <li className="menu-title">Settings</li>


                        {roleConnectedUser && roleConnectedUser.hasAccessModuleBasicSettings && (
                            <li className={isActive(history, '/settings/localization')}>
                                <Link to="/settings/localization">Paramètres de base</Link>
                            </li>
                        )}

                        {roleConnectedUser && roleConnectedUser.hasAccessModuleCompanySettings && (
                            <li className={isActive(history, '/settings/company')}>
                                <Link to="/settings/company">Paramètres de l'entreprise</Link>
                            </li>
                        )}

                        {roleConnectedUser && roleConnectedUser.hasAccessModuleThemeSettings && (
                            <li className={isActive(history, '/settings/theme')}>
                                <Link to="/settings/theme">Paramètres des thèmes</Link>
                            </li>
                        )}

                        {roleConnectedUser && roleConnectedUser.hasAccessModuleSalarySettings && (
                            <li className={isActive(history, '/settings/salary')}>
                                <Link to="/settings/salary">Paramètres de salaire</Link>
                            </li>
                        )}
                        
                        {roleConnectedUser && roleConnectedUser.hasAccessModuleRolesAndPermissions && (
                            <li className={isActive(history, '/settings/roles-permissions')}>
                                <Link to="/settings/roles-permissions">Rôles & autorisations</Link>
                            </li>
                        )}

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SidebarSettings)
