import React, { Fragment } from 'react';
import spinnerxs from './spinnerxs.gif';

const SpinnerXS = () => (
  <Fragment>
    <img
      src={spinnerxs}
      style={{ width: '100px', margin: '110px auto', display: 'block' }}
      alt="Loading..."
    />
  </Fragment>
);

export default SpinnerXS;
