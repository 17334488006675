
import {API_URL} from '../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";

import { ADD_DEPARTMENT, DELETE_DEPARTMENT, GET_DEPARTMENTS, UPDATE_DEPARTMENT } from './types';

export const getDepartments = () => dispatch => {

    fetch(`${API_URL}/departments`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "ContentType": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_DEPARTMENTS,
            payload: res.departments
        })
    })
    .catch(err => console.error(err))

}

export const addDepartment = (formData, admin_id, token, history) => dispatch => {
    
    fetch(`${API_URL}/departments/create/${admin_id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(res => {
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{

            dispatch({
                type: ADD_DEPARTMENT,
                payload: res.department
            })

            toastr.success(`Department ${res.department.name} created !`, 'New department' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/departments');
        }

        
        
    })
    .catch(err => toastr.error(err, 'Server error !', 
    {"positionClass": "toast-bottom-left",}))
}

export const updateDepartment = (formData, admin_id, token, department_id, history) => dispatch => {
    
    fetch(`${API_URL}/departments/${department_id}/${admin_id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(res => {
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{

            dispatch({
                type: UPDATE_DEPARTMENT,
                payload: res.department
            })

            toastr.success(`Department ${res.department.name} updated !`, 'Department updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/departments');
        }

    })
    .catch(err => toastr.error(err, 'Server error !', 
    {"positionClass": "toast-bottom-left",}))
}

export const removeDepartment = (admin_id, token, department_id) => dispatch => {

    fetch(`${API_URL}/departments/${department_id}/${admin_id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: DELETE_DEPARTMENT,
                payload: department_id
            })
            
            toastr.success(`Department deleted !`, 'Department deleted successfully' ,
            {"positionClass": "toast-bottom-left",})
            
        }
    })
    .catch(err => console.error(err))

}