import {
    ADD_INVOICE,
    INVOICE_ERROR,
    GET_INVOICES,
    GET_INVOICE,
    DELETE_INVOICE,
    UPDATE_INVOICE,
    CLEAR_INVOICE
} from './../actions/types';

let myState = {
    invoices: [],
    invoice: null,
    loading: true,
    error: false
}

const invoiceReducer = (state = myState, action) => {

    const { type, payload } = action;

    switch (type) {

        case GET_INVOICES:
            return {
                ...state,
                invoices: payload,
                loading: false,
                error: false
            }
        case GET_INVOICE:
            return {
                ...state,
                invoice: payload,
                loading: false,
                error: false
            }
        case DELETE_INVOICE:
            return {
                ...state,
                invoices: state.invoices.filter(invoice => invoice._id !== payload),
                loading: false,
                error: false
            }
        case ADD_INVOICE:
            return {
                ...state,
                invoices: [...state.invoices, payload],
                loading: false,
                error: false
            }
        case UPDATE_INVOICE:
            return {
                ...state,
                invoices: state.invoices.map(invoice => invoice._id === payload._id ? (invoice = payload) : invoice),
                loading: false,
                error: false
            }
        case INVOICE_ERROR: 
            return {
                ...state,
                loading: false,
                error: true
            }
        case CLEAR_INVOICE:
            return {
                ...state,
                invoice: null
            }
        default:{
            return state
        }
    }
}

export default invoiceReducer;
