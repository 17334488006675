import React, { useState, useEffect } from 'react'
import { API_URL } from '../../config'
import Layout from '../../core/Layout'
import moment from 'moment';

const PayslipExample = (props) => {

    const [settings, setSettings] = useState({});

    const getSettings = () => {

        return fetch(`${API_URL}/settings`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
            .then(res => res.json())
            .then(res => {
                setSettings(res.settings[0])
            })
            .catch(err => console.error(err))
    }


    useEffect(() => {
        getSettings()
    }, [])


    /* const {
        settings ? settings.currencySymbole : '',
        CNSSRate,
        CIMRRate,
        CNSSAMORate,
        assGroupRate,
        medicalChargesRate,
    } = settings */

    return (
        <div>
            <Layout>
                <div class="row">
                    <div class="col-sm-5 col-4">
                        <h4 class="page-title">Payslip</h4>
                    </div>
                    <div class="col-sm-7 col-8 text-right m-b-30">
                        <div class="btn-group btn-group-sm">
                            {/* <button class="btn btn-white">CSV</button> */}
                            <button class="btn btn-white">PDF</button>
                            <button class="btn btn-white"><i class="fa fa-print fa-lg"></i> Print</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-box">
                            <h4 class="payslip-title">Payslip for the month of AUGUST 2021</h4>
                            <div class="row">
                                <div class="col-sm-6 m-b-20">
                                    <img src="assets/img/logo-dark.png" class="inv-logo" alt="" />
                                    <ul class="list-unstyled m-b-0">
                                        <li>{settings ? settings.websiteName : ''}</li>
                                        <li>{settings ? settings.address : ''}</li>
                                    </ul>
                                </div>
                                <div class="col-sm-6 m-b-20">
                                    <div class="invoice-details">
                                        <h3 class="text-uppercase">Payslip #6006ED24</h3>
                                        <ul class="list-unstyled">
                                            <li>Salary Month: <span> August, 2021</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 m-b-20">
                                    <ul class="list-unstyled">
                                        <li>
                                            <h5 class="m-b-0"><strong>Ibtissam MAZHARI</strong></h5></li>
                                        <li><span>Accountant</span></li>
                                        <li>Employee CIN: AB1234</li>
                                        <li>Joining Date: 2 January 2021</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div>
                                        <h4 class="m-b-10 text-primary"><strong>Earnings</strong></h4>
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Feast Bonus</strong> <span class="float-right">250.00 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Seniority Bonus (15%)</strong> <span class="float-right">1090.73 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Transport & Lodgement</strong> <span class="float-right">800.00  {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Supp Salary (125%)</strong> <span class="float-right">200.88 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Supp Salary (150%)</strong> <span class="float-right">385.68 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Others Earnings</strong> <span class="float-right">0 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Total Earnings</strong> <span class="float-right"><strong>2727.29{settings ? settings.currencySymbole : ''}</strong></span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div>
                                        <h4 class="m-b-10 text-danger"><strong>Deductions</strong></h4>
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td><strong>CNSS ({settings ? settings.CNSSRate : ''}%)</strong> <span class="float-right">257.40 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>CIMR ({settings ? settings.CIMRRate : ''}%)</strong> <span class="float-right">763.64 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>CNSS-AMO ({settings ? settings.CNSSAMORate : ''}%)</strong> <span class="float-right">254.55 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Ass. Group ({settings ? settings.assGroupRate : ''}%)</strong> <span class="float-right">254.55 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Medical Charges ({settings ? settings.medicalChargesRate : ''}%)</strong> <span class="float-right">129.82 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Others Deducations</strong> <span class="float-right">0 {settings ? settings.currencySymbole : ''}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Total Deducations</strong> <span class="float-right"><strong>1402.55 {settings ? settings.currencySymbole : ''}</strong></span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <p><strong>Net Salary: 59698 {settings ? settings.currencySymbole : ''}</strong> (Fifty nine thousand six hundred and ninety eight only.)</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default PayslipExample
