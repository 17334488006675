import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";


export const getProvidentFunds = () => dispatch => {

    fetch(`${API_URL}/providentFunds`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_PROVIDENT_FUNDS',
            payload: res.providentFunds
        })
    })
    .catch(err => console.error(err))

}

export const getProvidentFund = id => dispatch => {

    fetch(`${API_URL}/providentFunds/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_PROVIDENT_FUND',
            payload: res.providentFund
        })
    })
    .catch(err => console.error(err))

}

export const addProvidentFund = (providentFund, user, token, history) => dispatch => {

    fetch(`${API_URL}/providentFunds/create/${user._id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(providentFund)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'ADD_PROVIDENT_FUND',
                payload: res.providentFund
            })

            toastr.success(`Provident Fund added !`, 'New Provident Fund' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/providentFunds');

        }
    })
    .catch(err => console.error(err))
}

export const removeProvidentFund = (id, user, token, history) => dispatch => {

    fetch(`${API_URL}/providentFunds/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'DELETE_PROVIDENT_FUND',
                payload: id
            })

            toastr.success(`Provident Fund deleted !`, 'Provident Fund deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/providentFunds');

        }
    })
    .catch(err => console.error(err))

}

export const updateProvidentFund = (providentFund, user, token, providentFund_id, history) => dispatch => {

    fetch(`${API_URL}/providentFunds/${providentFund_id}/${user._id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(providentFund)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_PROVIDENT_FUND',
                payload: res.providentFund
            })

            toastr.success(`Provident Fund updated !`, 'Provident Fund updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/providentFunds');
        }
    })
    .catch(err => console.error(err))
}


