import React, { useState, useEffect } from 'react'
import { getEmployeeSalary } from '../../actions/employeeSalaryActions'
import { API_URL } from '../../config'
import Layout from '../../core/Layout'
import moment from 'moment';


const Payslip = (props) => {

    const [employeeSalary, setEmployeeSalary] = useState({})
    const [settings, setSettings] = useState({});

    const getSettings = () => {

        return fetch(`${API_URL}/settings`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
            .then(res => res.json())
            .then(res => {
                setSettings(res.settings[0])
            })
            .catch(err => console.error(err))
    }

    const getEmployeeSalary = id => {

        return fetch(`${API_URL}/employeesSalaries/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
            .then(res => res.json())
            .then(res => {
                setEmployeeSalary(res.employeeSalary)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        const id = props.match.params.id
        getEmployeeSalary(id)
        getSettings()
    }, [])

    const {
        salaryMonth,
        _id,
        user,
        feastBonus,
        seniorityBonus,
        transporAndLodgementBonus,
        suppSalary125,
        suppSalary150,
        othersEarning,
        earnings,
        CNSS,
        CIMR,
        CNSSAMO,
        assGroup,
        medicalCharges,
        othersDedications,
        deducations,
        netSalary
    } = employeeSalary


    const {
        currencySymbole,
        CNSSRate,
        CIMRRate,
        CNSSAMORate,
        assGroupRate,
        medicalChargesRate,
    } = settings

    return (
        <div>
            {employeeSalary && (
                <Layout>
                    <div class="row">
                        <div class="col-sm-5 col-4">
                            <h4 class="page-title">Payslip</h4>
                        </div>
                        <div class="col-sm-7 col-8 text-right m-b-30">
                            <div class="btn-group btn-group-sm">
                                {/* <button class="btn btn-white">CSV</button> */}
                                <button class="btn btn-white">PDF</button>
                                <button class="btn btn-white"><i class="fa fa-print fa-lg"></i> Print</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-box">
                                <h4 class="payslip-title">Payslip for the month of {moment(salaryMonth).format('MMMM Y')}</h4>
                                <div class="row">
                                    <div class="col-sm-6 m-b-20">
                                        <img src="assets/img/logo-dark.png" class="inv-logo" alt="" />
                                        <ul class="list-unstyled m-b-0">
                                            <li>{settings.websiteName}</li>
                                            <li>{settings.address}</li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-6 m-b-20">
                                        <div class="invoice-details">
                                            <h3 class="text-uppercase">Payslip #{_id}</h3>
                                            <ul class="list-unstyled">
                                                <li>Salary Month: <span>{moment(salaryMonth).format('MMMM, Y')}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {employeeSalary.user && (
                                    <div class="row">
                                        <div class="col-lg-12 m-b-20">
                                            <ul class="list-unstyled">
                                                <li>
                                                    <h5 class="m-b-0"><strong>{employeeSalary.user.firstname} {employeeSalary.user.lastname}</strong></h5></li>
                                                <li><span>{employeeSalary.user.role}</span></li>
                                                <li>Employee CIN: {employeeSalary.user.cin}</li>
                                                <li>Joining Date: {moment(employeeSalary.user.joiningDate).format('d MMMM Y')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div>
                                            <h4 class="m-b-10 text-primary"><strong>Earnings</strong></h4>
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td><strong>Feast Bonus</strong> <span class="float-right">{feastBonus ? feastBonus.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Seniority Bonus (15%)</strong> <span class="float-right">{seniorityBonus ? seniorityBonus.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Transport & Lodgement</strong> <span class="float-right">{transporAndLodgementBonus ? transporAndLodgementBonus.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Supp Salary (125%)</strong> <span class="float-right">{suppSalary125 ? suppSalary125.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Supp Salary (150%)</strong> <span class="float-right">{suppSalary150 ? suppSalary150.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Others Earnings</strong> <span class="float-right">{othersEarning ? othersEarning.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Total Earnings</strong> <span class="float-right"><strong>{earnings ? earnings.toFixed(2) : 0} {currencySymbole}</strong></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <h4 class="m-b-10 text-danger"><strong>Deductions</strong></h4>
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td><strong>CNSS ({CNSSRate}%)</strong> <span class="float-right">{CNSS ? CNSS.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>CIMR ({CIMRRate}%)</strong> <span class="float-right">{CIMR ? CIMR.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>CNSS-AMO ({CNSSAMORate}%)</strong> <span class="float-right">{CNSSAMO ? CNSSAMO.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Ass. Group ({assGroupRate}%)</strong> <span class="float-right">{assGroup ? assGroup.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Medical Charges ({medicalChargesRate}%)</strong> <span class="float-right">{medicalCharges ? medicalCharges.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Others Deducations</strong> <span class="float-right">{othersDedications ? othersDedications.toFixed(2) : 0} {currencySymbole}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Total Deducations</strong> <span class="float-right"><strong>{deducations ? deducations.toFixed(2) : 0} {currencySymbole}</strong></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <p><strong>Net Salary: {netSalary ? netSalary.toFixed(2) : 0} {currencySymbole}</strong> (Fifty nine thousand six hundred and ninety eight only.)</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Layout>
            )}
        </div>
    )
}

export default Payslip
