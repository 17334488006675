import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';


import { isAuthenticated } from '../../../auth/helpers';
import { API_URL } from '../../../config';
import Layout from '../../../core/Layout';
import { Fragment } from 'react';
import Spinner from '../../../core/Spinner';
import { GET_ROLE, ROLE_ERROR } from '../../../actions/types';
import { addRole, updateRole } from '../../../actions/roleActions';
import Page500 from '../../../core/Page500';

const initialState = {
    name: '',
    status: ''
};

const FormRole = ({match, history}) => {

    const dispatch = useDispatch();
    const role_id = match.params.id;

    const loading = useSelector(state => state.role.loading);
    const error = useSelector(state => state.role.error);


    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if(role_id) getRole(role_id);
    }, [role_id])


    const getRole = async id => {

        try {
            const res = await axios(`${API_URL}/roles/${id}`);
            const role = res.data.role;
            
            setFormData(role);
            
            dispatch({
                type: GET_ROLE,
                payload: role
            });
            
        }catch (err) {
            dispatch({type: ROLE_ERROR});
        }
    }

    const handleChange = e => setFormData({...formData, [e.target.name]: e.target.value})

    const submit = e => {
        e.preventDefault();
        
        const { user, token } = isAuthenticated();

        if(role_id){
            dispatch(updateRole(formData, user, token, role_id, history));
        }else{
            dispatch(addRole(formData, user, token, history));
        }
    } 

    if (error == true) {
        return <Page500 />;
    }
    
    return (
        <Fragment>
            {role_id && loading ? (<Spinner />) : (
			
			<Fragment>
                <Layout>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h4 className="page-title">{role_id ? 'Modifier un rôle': 'Nouveau rôle'}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <form onSubmit={submit}>
                                <div className="form-group">
                                    <label>Nom du rôle <span className="text-danger">*</span></label>
                                    <input name="name" className="form-control" type="text" onChange={handleChange} value={formData ? formData.name : ''} readOnly={formData.name === 'Administrateur' || formData.name === 'Doctor'} />
                                </div>
                                <div className="form-group">
                                    <label className="display-block">Statut</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" onChange={handleChange} value="Active" checked={formData.status == "Active"} />
                                        <label className="form-check-label" htmlFor="role_active">
                                        Actif
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" onChange={handleChange} value="Inactive" checked={formData.status == "Inactive"} />
                                        <label className="form-check-label" htmlFor="role_inactive">
                                        Inactif
                                        </label>
                                    </div>
                                </div>
                                <div className="m-t-20 text-center">
                                    <Link className="mr-2" to="/settings/roles-permissions">
                                        <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Retourner</button>
                                    </Link>
                                    {role_id ? (
                                        <button type="submit" className="btn btn-warning submit-btn">Modifier le rôle</button>
                                    ): (
                                        <button type="submit" className="btn btn-primary submit-btn">Créer le rôle</button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </Layout>
            </Fragment>)
		}  
        </Fragment>
    )
}

export default FormRole;
