import React, {Fragment, useState, useEffect} from 'react';
import Layout from '../../core/Layout';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

import { getDoctors, removeDoctor } from './../../actions/doctorActions';
import { isAuthenticated } from './../../auth/helpers';
import { API_URL } from './../../config';

import Spinner from '../../core/Spinner';
import Page500 from '../../core/Page500';


const ListDoctors = ({ doctor: { doctors, loading, error } , getDoctors, removeDoctor }) => {
    
    const [show, setShow] = useState(false);
    const [deletedDoctorId, setDeletedDoctorId] = useState('');
    const [searchData, setSearchData] = useState('');
    let [limit, setLimit] = useState(12);


    useEffect(() => {
        getDoctors({limit, search: {...searchData, role:'Doctor'}})
    }, [getDoctors, limit])

    // search 
    const handleChange = e =>  setSearchData({...searchData, [e.target.id]: e.target.value })

    const searchSubmit = (e) => {
        e.preventDefault();
        
        if(searchData && searchData.name != "" && searchData.speciality != ""){
            getDoctors({search: {...searchData, role:'Doctor'}})
        
        }else{
            getDoctors({limit:12, search: {...searchData, role:'Doctor'}})
                setLimit(12)
        }
    }

    // delete
    const handleClose = () => setShow(false);
    
    const handleShow = (id) => {
        setDeletedDoctorId(id)
        setShow(true);
    }

    const submitDeleteModal = () => {

        const { user, token } = isAuthenticated();

        removeDoctor(deletedDoctorId, user, token)
        setShow(false);
    }

    const deleteDoctorModal = () => {
        return (
            
        <Modal aria-labelledby="contained-modal-title-vcenter"
        centered show={show} onHide={handleClose} variant="modal-dialog modal-dialog-centered">
            
                <Modal.Body variant="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width="50" height="46"/>
                    <h3>Voulez-vous vraiment supprimer ce docteur ?</h3>
                    <div className="m-t-20"> 
                        <Button variant="btn btn-white mx-1" onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="btn btn-danger" onClick={submitDeleteModal}>
                            Supprimer
                        </Button>
                    </div>
                </Modal.Body>
        </Modal>
        )
    }

    // load button
    const loadMore = () => {

        limit = limit + 8

        getDoctors({limit, search: {...searchData, role:'Doctor'}})
        setLimit(limit)
        
        
    }


    const buttonToLoadMore = () => {
        return (
            doctors && doctors.length > 0 && doctors.length >= limit && (
                <div className="text-center mt-2">
                    <button onClick={loadMore} className="btn btn-outline-success">Afficher plus ...</button>
                </div>
            )   
        )
    } 

    if (error == true) {
        return <Page500 />;
    }

    return (
        <Fragment>
            {loading ? (<Spinner />) : (
        
                <Fragment>
                    <Layout>
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title">Médecins</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link to="/doctors/create" className="btn btn-primary btn-rounded float-right"><i className="fa fa-plus"></i> Ajouter un médecin</Link>
                        </div>
                    </div>

                    <form onSubmit={searchSubmit}>
                        <div className="row filter-row">
                            <div className="col-sm-6 col-md-3">
                                <div className="form-group form-focus">
                                    <label className="focus-label">Nom du médecin</label>
                                    <input id="name" onChange={handleChange} type="text" className="form-control floating"/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="form-group">
                                    <select style={{ height: 'calc(2.25rem + 14px)' }} id="speciality" onChange={handleChange} className="form-control">
                                        <option value="">Sélectionnez une spécialité</option>
                                        <option>Cardiologue</option>
                                        <option>Dentiste</option>
                                        <option>Gynécologue</option>
                                        <option>Ophtalmologiste</option>
                                        <option>Psychiatre</option>
                                        <option>Radiologue</option>
                                        <option>Urologue</option>
                                        <option>Autre</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 offset-md-3">
                                <button type="submit" className="btn btn-success btn-block">Rechercher</button>
                            </div>
                        </div>
                    </form>

                    <div className="row doctor-grid">
                        {doctors && doctors.map((doctor,i) => (

                            <div key={i} className="col-md-4 col-sm-4 col-xs-6 col-lg-3">
                                <div className="profile-widget">
                                    <div className="doctor-img">
                                        <Link className="avatar" to={`/doctors/${doctor._id}/profile`}><img alt="" src={`${API_URL}/users/photo/${doctor._id}`}/></Link>
                                    </div>
                                    <div className="dropdown profile-action">
                                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <Link className="dropdown-item" to={`/doctors/${doctor._id}/update`}><i className="fa fa-pencil m-r-5"></i> Modifier</Link>
                                            <a style={{"cursor": "pointer"}} className="dropdown-item" onClick={handleShow.bind(this,doctor._id)}><i className="fa fa-trash-o m-r-5"></i> Supprimer</a>
                                        </div>
                                    </div>
                                    <h4 className="doctor-name text-ellipsis">
                                        <Link to={`/doctors/${doctor._id}/profile`}>{doctor.firstname} {doctor.lastname}</Link>
                                    </h4>
                                    <div className="doc-prof">{doctor.speciality}</div>
                                    <div className="user-country">
                                        <i className="fa fa-map-marker"></i> {doctor.country}, {doctor.city}
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>
                    {buttonToLoadMore()}
                    {deleteDoctorModal()}
                
                    </Layout>
                </Fragment>)
            }  
        </Fragment>
    )
};

ListDoctors.propTypes = {
    getDoctors: PropTypes.func.isRequired,
    removeDoctor: PropTypes.func.isRequired,
    doctor: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    doctor: state.doctor
})

export default connect(mapStateToProps, { getDoctors, removeDoctor })(ListDoctors)
