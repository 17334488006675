import { combineReducers } from 'redux';

import authReducer from './authReducer'
import doctorReducer from './doctorReducer'
import patientReducer from './patientReducer'
import appointmentReducer from './appointmentReducer';
import departmentReducer from './departmentReducer';
import paymentReducer from './paymentReducer';
import employeeReducer from './employeeReducer';
import holidayReducer from './holidayReducer';
import invoiceReducer from './invoiceReducer';
import taxReducer from './taxReducer';
import expenseReducer from './expenseReducer'
import providentFundReducer from './providentFundReducer'
import settingsReducer from './settingsReducer'
import employeeSalaryReducer from './employeeSalaryReducer';
import leaveReducer from './leaveReducer';
import roleReducer from './roleReducer';
import prescriptionReducer from './prescriptionReducer';

const rootReducers = combineReducers({
    auth: authReducer,
    doctor: doctorReducer,
    patient: patientReducer,
    appointment: appointmentReducer,
    department: departmentReducer,
    payment: paymentReducer,
    employee: employeeReducer,
    holiday: holidayReducer,
    invoice: invoiceReducer,
    tax: taxReducer,
    expense: expenseReducer,
    providentFund: providentFundReducer,
    settings: settingsReducer,
    employeeSalary: employeeSalaryReducer,
    leave: leaveReducer,
    role: roleReducer,
    prescription: prescriptionReducer
})

export default rootReducers;