import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../core/Layout';
import LineItems from './LineItems';
import uuidv4 from 'react-uuid';
import moment from 'moment';

import { getTaxs } from '../../../actions/taxActions';
import { addInvoice, updateInvoice } from '../../../actions/invoiceActions';
import { isAuthenticated } from '../../../auth/helpers';
import { API_URL } from '../../../config';
import { APPOINTMENT_ERROR, GET_APPOINTMENT, GET_INVOICE, INVOICE_ERROR } from '../../../actions/types';
import Page500 from '../../../core/Page500';
import Spinner from '../../../core/Spinner';

const initialState = {
    appointment: '',
    email: '',
    patientAddress: '',
    billingAddress: '',
    invoiceDate: '',
    taxRate: 0,
    taxAmount: 0,
    discountRate: 0,
    discountAmount: 0,
    dueDate: '',
    locale: 'en-US',
    currency: 'MAD',
    grandTotal:0,
    total:0,
    status: '',
    paidBy: '',
    rest: 0,
    otherInformation: '',
    lineItems: [
        {
            id: 'initial',      // react-beautiful-dnd unique key
            name: '',
            description: '',
            quantity: 0,
            price: 0.00,
        },
    ]
}

const FormInvoice = ({history, match}) => {

    const invoiceId = match.params.id;
    const appointmentId = match.params.appointmentId;

    const dispatch = useDispatch();
    const taxs = useSelector(state => state.tax.taxs);
    const loading = useSelector(state => state.appointment.loading);
    const error = useSelector(state => state.appointment.error);

    const [invoice, setInvoice] = useState(initialState);
    const [appointment, setAppointment] = useState('');
        
    useEffect(() => {
        if(appointmentId) getAppointment(appointmentId);
        if(invoiceId) getInvoice(invoiceId);
        
        dispatch(getTaxs());
        
    }, [appointmentId, invoiceId])
    
    const getAppointment = id => {
        return fetch(`${API_URL}/appointments/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setInvoice({
                ...invoice,
                appointment: res.appointment._id,
                email: res.appointment.patient.email
            });

            setAppointment({
                patient: res.appointment.patient.firstname+" "+res.appointment.patient.lastname,
                department: res.appointment.department.name,
            })

            dispatch({
                type: GET_APPOINTMENT,
                payload: res.appointment
            })
        })
        .catch(err => dispatch({type: APPOINTMENT_ERROR}));
        
    }

    const getInvoice = id => {
        return fetch(`${API_URL}/invoices/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setInvoice(res.invoice);
            dispatch({
                type: GET_INVOICE,
                payload: res.invoice
            })
        })
        .catch(err => dispatch({type: INVOICE_ERROR}));
        
    }
    
    const handleInvoiceChange = e => setInvoice({...invoice, [e.target.name]: e.target.value});

    const handleLineItemChange = (elementIndex) => (e) => {
        let lineItems = invoice.lineItems.map((item, i) => {
        if (elementIndex !== i) return item
        return {...item, [e.target.name]: e.target.value}
        })
        setInvoice({...invoice, lineItems})
    }

    const handleAddLineItem = e => {
        setInvoice({
            ...invoice,
        // use optimistic uuid for drag drop; in a production app this could be a database id
        lineItems: invoice.lineItems.concat(
            [{ id: uuidv4(), name: '', description: '', quantity: 0, price: 0.00 }]
        )
        })
    }

    const handleRemoveLineItem = elementIndex => e => {
        setInvoice({
        lineItems: invoice.lineItems.filter((item, i) => {
            return elementIndex !== i
        })
        })
    }


    const formatCurrency = amount => {
        return (new Intl.NumberFormat(invoice.locale, {
        //style: 'currency',
        currency: invoice.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
        }).format(amount))
    } 

  
    const calcLineItemsTotal = () => {
        return (invoice.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price)), 0)) || 0;
    }

    const calcTaxTotal = () => {
        return (calcLineItemsTotal() * (invoice.taxRate / 100)) || 0;
    }

    const calcDiscountTotal = () => {
        return (calcLineItemsTotal() * (invoice.discountRate / 100)) || 0;
    }

    const calcGrandTotal = () => {
        return (calcLineItemsTotal() + calcTaxTotal() - calcDiscountTotal()) || 0;
    }

    invoice.total = calcLineItemsTotal();
    invoice.grandTotal = calcGrandTotal();
    invoice.taxAmount = calcTaxTotal();
    invoice.discountAmount = calcDiscountTotal();

    if(invoice.status == 'Sent') invoice.paidBy = "";
    if(invoice.status !== 'Partially Paid') invoice.rest = 0;

    const submit = (e) => {
        e.preventDefault();
        const { user, token } = isAuthenticated();

        if(invoice.status === 'Paid') alert('the invoice is not editable if it was paid')
        
        invoiceId ? dispatch(updateInvoice(invoice, user._id, token, invoiceId, history)) : dispatch(addInvoice(invoice, user._id, token, history));
    }

    if(error) return <Page500/>;

    return (
        <Fragment>
            {loading ? (<Spinner />) : (
			
			<Fragment>
                <Layout>
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="page-title">{invoiceId ? "Update" : "Create"} Invoice</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={submit} >
                                <div className="row">
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Patient <span className="text-danger">*</span></label>
                                            <input readOnly className="form-control" type="text" value={appointment.patient} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Department <span className="text-danger">*</span></label>
                                            <input readOnly className="form-control" type="text" value={appointment.department} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input onChange={handleInvoiceChange} name="email" className="form-control" type="email" value={invoice.email} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Tax</label>
                                            <select onChange={handleInvoiceChange} name="taxRate" className="select form-control" value={invoice.taxRate}>
                                                <option>Select Tax</option>
                                                {taxs && taxs.map((tax, index) => (
                                                    <option key={index} value={tax.percentage}>{tax.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Patient Address</label>
                                            <textarea onChange={handleInvoiceChange} name="patientAddress" className="form-control" rows="3" value={invoice.patientAddress}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Billing Address</label>
                                            <textarea onChange={handleInvoiceChange} name="billingAddress" className="form-control" rows="3" value={invoice.billingAddress} ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Invoice date <span className="text-danger">*</span></label>
                                            <input onChange={handleInvoiceChange} name="invoiceDate" className="form-control" type="date" value={invoice.invoiceDate ? moment(invoice.invoiceDate).format('YYYY-MM-DD') : ''} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Due Date <span className="text-danger">*</span></label>
                                            <input onChange={handleInvoiceChange} name="dueDate" className="form-control" value={invoice.dueDate ? moment(invoice.dueDate).format('YYYY-MM-DD') : ''} type="date"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Currency</label>
                                            <select onChange={handleInvoiceChange} name="currency" value={invoice.currency} className="select form-control">
                                                <option value="">Select Currency</option>
                                                <option value="MAD">MAD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="USD">USD</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select onChange={handleInvoiceChange} name="status" value={invoice.status} className="select form-control">
                                                <option value="">Select Status</option>
                                                <option value="Sent">Sent</option>
                                                <option value="Partially Paid">Partially Paid</option>
                                                <option value="Paid">Paid</option>
                                            </select>
                                        </div>
                                    </div>
                                    {invoice.status && invoice.status === "Partially Paid" && (
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                    <label>Rest ({invoice.currency})</label>
                                                <input className="form-control" name="rest" type="number" value={invoice.rest} onChange={handleInvoiceChange}/>
                                            </div>
                                        </div>
                                    )}
                                    {invoice.status && (invoice.status === "Partially Paid" || invoice.status === "Paid") && (
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Paid By</label>
                                                <select onChange={handleInvoiceChange} name="paidBy" value={invoice.paidBy} className="select form-control">
                                                    <option value="">Select Payment Method</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="Cheque">Cheque</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    
                                </div>
                                <LineItems
                                    items={invoice.lineItems}
                                    currencyFormatter={formatCurrency}
                                    addHandler={handleAddLineItem}
                                    changeHandler={handleLineItemChange}
                                    deleteHandler={handleRemoveLineItem}
                                />
                                <div className="table-responsive mt-4">
                                    <table className="table table-hover table-white">
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td colSpan="3" className="text-right">Total</td>
                                                <td colSpan="5" style={{textAlign: "right", paddingRight: "30px", width: "230px"}}>{formatCurrency(calcLineItemsTotal())} {invoice.currency}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan="5" className="text-right">
                                                    Tax rate %
                                                </td>
                                                <td style={{textAlign: "right", paddingRight: "30px" ,width: "230px"}}>
                                                    <input className="form-control text-right" name="taxRate" type="number" readOnly value={invoice.taxRate} onChange={handleInvoiceChange}/>
                                                </td>
                                                <td className="text-right">Tax ({invoice.currency})</td>
                                                <td style={{textAlign: "right", paddingRight: "30px" ,width: "230px"}}>
                                                    <input className="form-control" value={formatCurrency(calcTaxTotal())} readOnly type="text"/>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan="5" className="text-right">
                                                    Discount rate %
                                                </td>
                                                <td style={{textAlign: "right", paddingRight: "30px" ,width: "230px"}}>
                                                    <input className="form-control text-right" name="discountRate" type="number" step="0.01" value={invoice.discountRate} onChange={handleInvoiceChange}/>
                                                </td>
                                                <td className="text-right">Discount ({invoice.currency})</td>
                                                <td style={{textAlign: "right", paddingRight: "30px" ,width: "230px"}}>
                                                    <input className="form-control" value={formatCurrency(calcDiscountTotal())} readOnly type="text"/>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td colSpan="3" style={{textAlign: "right", fontWeight: "bold"}}>
                                                    Grand Total
                                                </td>
                                                <td colSpan="5" style={{textAlign: "right", paddingRight: "30px", fontWeight: "bold", fontSize: "16px"}}>
                                                    {formatCurrency(calcGrandTotal())} {invoice.currency}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Other Information</label>
                                            <textarea onChange={handleInvoiceChange} name="otherInformation" className="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center m-t-20">
                                    {invoiceId ? (
                                        <Fragment>
                                            <button type="submit" className="btn btn-grey submit-btn m-r-10">Update</button>
                                            <button type="button" className="btn btn-warning submit-btn">Update & Send</button>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <button type="submit" className="btn btn-grey submit-btn m-r-10">Save</button>
                                            <button type="button" className="btn btn-primary submit-btn">Save & Send</button>
                                        </Fragment>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </Layout>
            </Fragment>)
		}  
        </Fragment>
    )
  }

export default FormInvoice;
