import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutSettings from '../../core/LayoutSettings';

import { isAuthenticated } from './../../auth/helpers';
import { GET_SETTINGS, SETTINGS_ERROR } from '../../actions/types';

import { API_URL } from '../../config';
import { updateSettings } from '../../actions/settingsActions';
import Spinner from '../../core/Spinner';
import { Link } from 'react-router-dom';

const CompanySettings = (props) => {

    const [formData, setFormData] = useState(new FormData());

    let dispatch = useDispatch();
    const loading = useSelector(state => state.settings.loading);

    const [settings, setSettings] = useState({
        companyName: '',
        contactPerson: '',
        address: '',
        country: 'Morocco',
        city: '',
        state: '',
        postalCode: '',
        email: 'email@example.com',
        phoneNumber: '',
        fax: '',
        websiteUrl: 'www.example.com'
    })

    const getSettings = async () => {

        try {
            const res = await fetch(`${API_URL}/settings`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            });
            const res_1 = await res.json();
            setSettings(res_1.settings[0]);
            formData.set('companyName', res_1.settings[0].companyName || '');
            formData.set('contactPerson', res_1.settings[0].contactPerson || '');
            formData.set('address', res_1.settings[0].address || '');
            formData.set('country', res_1.settings[0].country || 'Morocco');
            formData.set('city', res_1.settings[0].city || '');
            formData.set('state', res_1.settings[0].state || '');
            formData.set('postalCode', res_1.settings[0].postalCode || '12345');
            formData.set('email', res_1.settings[0].email || 'email@example.com');
            formData.set('phoneNumber', res_1.settings[0].phoneNumber || '+212611223344');
            formData.set('fax', res_1.settings[0].fax || '+212611223344');
            formData.set('websiteUrl', res_1.settings[0].websiteUrl || 'www.example.com');

            dispatch({
                type: GET_SETTINGS,
                payload: res_1.settings[0]
            });

        } catch (err) {
            dispatch({type: SETTINGS_ERROR});
        }
    }

    useEffect(() => {
        getSettings()
    }, [])


    const handleChange = e => {
        formData.set(e.target.name, e.target.value)
        setSettings({...settings, [e.target.name]: e.target.value})
    }

    const submit = e => {
        e.preventDefault()
        
        const { user, token } = isAuthenticated();

        dispatch(updateSettings(formData, user, token, props.history))
    }


    return (
        <Fragment>
            {loading ? (<Spinner />) : (

                <Fragment>
                    <LayoutSettings>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <form onSubmit={submit}>
                                <h3 className="page-title">Paramètres de l'entreprise</h3>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Nom de la compagnie <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" name="companyName" onChange={handleChange} value={settings ? settings.companyName: ''}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Personne de contact</label>
                                            <input className="form-control " name="contactPerson" onChange={handleChange} value={settings ? settings.contactPerson: ''} type="text"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Adresse</label>
                                            <input className="form-control " name="address" onChange={handleChange} value={settings ? settings.address: ''} type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Pays</label>
                                            <select className="form-control select" name="country" onChange={handleChange} value={settings ? settings.country: ''}>
                                                <option value="">Sélectionnez votre pays</option>
                                                <option value="Morocco">Maroc</option>
                                                <option value="USA">Etats-Unis</option>
                                                <option value="United Kingdom">Royaume-Uni</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Vile</label>
                                            <input className="form-control" name="city" onChange={handleChange} value={settings ? settings.city: ''} type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Province</label>
                                            <input className="form-control select" name="state" onChange={handleChange} value={settings ? settings.state: ''}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Code postal</label>
                                            <input className="form-control" type="text" name="postalCode" onChange={handleChange} value={settings ? settings.postalCode: ''} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Adresse email</label>
                                            <input className="form-control" name="email" onChange={handleChange} value={settings ? settings.email: ''} type="email"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Numéro de téléphone</label>
                                            <input className="form-control" name="phoneNumber" onChange={handleChange} value={settings ? settings.phoneNumber: ''} type="text"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Numéro de portable</label>
                                            <input className="form-control" name="phoneNumber" onChange={handleChange} value={settings ? settings.phoneNumber: ''} type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Fax</label>
                                            <input className="form-control" name="fax" onChange={handleChange} value={settings ? settings.fax: ''} type="text"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>URL de site web</label>
                                            <input className="form-control" name="websiteUrl" onChange={handleChange} value={settings ? settings.websiteUrl: ''} type="text"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text-center m-t-20">
                                        <Link to="/dashboard" className="btn btn-secondary submit-btn mr-2">Annuler</Link>
                                        <button type="submit" className="btn btn-primary submit-btn">Enregistrer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </LayoutSettings>
                </Fragment>
            )}
        </Fragment>
    )
}

export default CompanySettings
