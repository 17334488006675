import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";
import { 
    ADD_ROLE,
    DELETE_ROLE,
    GET_ROLE,
    GET_ROLES,
    GET_ROLE_CONNECTED_USER,
    UPDATE_ROLE
} from './types';


export const getRoles = () => dispatch => {

    fetch(`${API_URL}/roles`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_ROLES,
            payload: res.roles
        })
    })
    .catch(err => console.error(err))

}

export const getRole = id => dispatch => {

    fetch(`${API_URL}/roles/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_ROLE,
            payload: res.role
        })
    })
    .catch(err => console.error(err))
}

export const getRoleByName = name => dispatch => {

    fetch(`${API_URL}/roles/roleByName`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(name)
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_ROLE,
            payload: res.role
        })
    })
    .catch(err => console.error(err))
}

export const getRoleByNameConnectedUser = name => dispatch => {

    fetch(`${API_URL}/roles/roleByName`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(name)
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: GET_ROLE_CONNECTED_USER,
            payload: res.role
        })
    })
    .catch(err => console.error(err))
}

export const addRole = (formData, user, token, history) => dispatch => {

    fetch(`${API_URL}/roles/create/${user._id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: ADD_ROLE,
                payload: res.role
            })

            toastr.success(`role ${res.role.name} created !`, 'New role' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/settings/roles-permissions');

        }
    })
    .catch(err => console.error(err))
}

export const removeRole = (id, user, token, history) => dispatch => {

    fetch(`${API_URL}/roles/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: DELETE_ROLE,
                payload: id
            })

            toastr.success(`role deleted !`, 'role deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/roles');

        }
    })
    .catch(err => console.error(err))
}

export const updateRole = (formData, user, token, role_id, history) => dispatch => {

    fetch(`${API_URL}/roles/${role_id}/${user._id}`, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_ROLE,
                payload: res.role
            })

            toastr.success(`role ${res.role.name} updated !`, 'role updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push(`/settings/roles-permissions/${role_id}`);
        }
    })
    .catch(err => console.error(err))
}

export const updateRoleModuleAccess = (formData, user, token, role_id) => dispatch => {

    fetch(`${API_URL}/roles/${role_id}/${user._id}`, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: UPDATE_ROLE,
                payload: res.role
            })

            toastr.success(`Modules access updated for role ${res.role.name} !`, 'role updated' ,
            {"positionClass": "toast-bottom-left",})
        }
    })
    .catch(err => console.error(err))
}


