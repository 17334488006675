
import {API_URL} from './../config'

import toastr from 'toastr';
import "toastr/build/toastr.css";
import { HOLIDAY_ERROR } from './types';


export const getHolidays = () => dispatch => {

    fetch(`${API_URL}/holidays`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_HOLIDAYS',
            payload: res.holidays
        })
    })
    .catch(err => dispatch({type: HOLIDAY_ERROR}));

}

export const getHoliday = id => dispatch => {

    fetch(`${API_URL}/holidays/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    })
    .then(res => res.json())
    .then(res => {
        dispatch({
            type: 'GET_HOLIDAY',
            payload: res.holiday
        })
    })
    .catch(err => dispatch({type: HOLIDAY_ERROR}));

}

export const addHoliday = (holiday, user_id, token, history) => dispatch => {

    fetch(`${API_URL}/holidays/create/${user_id}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(holiday)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'ADD_HOLIDAY',
                payload: res.holiday
            })

            toastr.success(`holiday ${res.holiday.title} created !`, 'New holiday' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/holidays');

        }
    })
    .catch(err => dispatch({type: HOLIDAY_ERROR}));
}

export const removeHoliday = (id, user, token, history) => dispatch => {

    fetch(`${API_URL}/holidays/${id}/${user._id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Error, try again !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'DELETE_HOLIDAY',
                payload: id
            })

            toastr.success(`holiday deleted !`, 'holiday deleted successfully' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/holidays');

        }
    })
    .catch(err => dispatch({type: HOLIDAY_ERROR}));

}

export const updateHoliday = (holiday, user_id, token, holiday_id, history) => dispatch => {

    fetch(`${API_URL}/holidays/${holiday_id}/${user_id}`, {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(holiday)
    })
    .then(res => res.json())
    .then(res => {
        
        if(res.error){
            toastr.warning(res.error, 'Please check form !', 
            {"positionClass": "toast-bottom-left",})
        }else{
            
            dispatch({
                type: 'UPDATE_HOLIDAY',
                payload: res.holiday
            })

            toastr.success(`holiday ${res.holiday.title} updated !`, 'holiday updated' ,
            {"positionClass": "toast-bottom-left",})

            history.push('/holidays');
        }
    })
    .catch(err => dispatch({type: HOLIDAY_ERROR}));
}


