import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { updateTax } from '../../../actions/taxActions'

import { isAuthenticated } from '../../../auth/helpers';
import { API_URL } from '../../../config'


import Layout from '../../../core/Layout'

const UpdateTax = (props) => {

    const dispatch = useDispatch()
    const id = props.match.params.id

    const [updTax, setUpdTax] = useState({
        name: '',
        percentage: 0,
        status: 'Pending'
    })

    useEffect(() => {
        getTax(id)
    }, [])

    const getTax = id => {

        fetch(`${API_URL}/taxs/${id}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(res => {
            setUpdTax(res.tax)
        })
        .catch(err => console.error(err))
    
    }

    const handleChange = e => setUpdTax({...updTax, [e.target.name]: e.target.value})
    
    const submit = e => {
        e.preventDefault();

        const { user, token } = isAuthenticated();
        
        dispatch(updateTax(updTax, user, token, props.history))

    }

    const { name, percentage, status } = updTax;
    
    return (
        <div>
            <Layout>
            <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4 className="page-title">Update Tax</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <form onSubmit={submit}>
                            <div className="form-group">
                                <label>Tax Name <span className="text-danger">*</span></label>
                                <input name="name" onChange={handleChange} className="form-control" type="text" value={name} />
                            </div>
                            <div className="form-group">
                                <label>Tax Percentage (%) <span className="text-danger">*</span></label>
                                <input name="percentage" onChange={handleChange} className="form-control" type="number" value={percentage} />
                            </div>
                            <div className="form-group">
                                <label>Status <span className="text-danger">*</span></label>
                                <select name="status" onChange={handleChange} className="select form-control" value={status}>
                                    <option>Pending</option>
                                    <option>Approved</option>
                                </select>
                            </div>
                            <div className="m-t-20 text-center">
                                <Link className="mr-2" to="/taxs">
                                    <button type="button" className="btn btn-secondary submit-btn"><i className="fa fa-arrow-left"></i> Go Back</button>
                                </Link>
                                <button className="btn btn-warning submit-btn">Update Tax</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default UpdateTax
